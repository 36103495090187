import { getDateRange } from '$/app/utils';
import { FacilityTime } from '$shared/utils/date-time/facility-time';
import { MedicationReceivingFilters } from '../medication-receiving.vmodel';

export const defaultFilters = (
  ft: FacilityTime
): MedicationReceivingFilters => ({
  statuses: ['pending'],
  dateRange: getDateRange(null, ft, 'past30Days')
});

export interface State {
  filters: MedicationReceivingFilters;
}

export const initialState: State = {
  filters: null
};
