import { IPaginationInfo } from '$/models';
import { IActivityResident } from '$shared/services/activity-resident.schema';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const activityResidentsAdapter =
  createEntityAdapter<IActivityResident>();

export interface State extends EntityState<IActivityResident> {
  pagination: IPaginationInfo;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = activityResidentsAdapter.getInitialState({
  pagination: {},
  loading: false,
  loaded: false,
  error: null
});
