import { IProposedChange } from '$shared/services/proposed-changes.schema';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const proposedChangesAdapter = createEntityAdapter<IProposedChange>();

export interface State extends EntityState<IProposedChange> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = proposedChangesAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
