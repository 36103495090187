import { IPharmacyApiMessage } from '$shared/services/pharmacy-api-messages.schema';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const pharmacyApiMessagesAdapter =
  createEntityAdapter<IPharmacyApiMessage>();

export interface State extends EntityState<IPharmacyApiMessage> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = pharmacyApiMessagesAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
