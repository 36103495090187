import { FacilityContactsPageActions } from '$/app/pages/facilities/facility-detail/facility-contacts/facility-contacts.actions';
import { PharmacyInfoPageActions } from '$/app/pages/facilities/facility-detail/pharamcy-info/pharmacy-info.page.actions';
import { InventoryItemFormActions } from '$/app/pages/medications/shared/components/inventory-item-form/inventory-item-form.modal.actions';
import { ResidentModalActions } from '$/app/pages/residents/resident-modal/resident.modal.actions';
import { EffectHelpersService, PharmaciesApiService } from '$/app/services';
import * as PharmacyFormActions from '$/app/shared/pages/forms/pharmacy-form/pharmacy-form.actions';
import { PharmacyPhonesGeneralActions } from '$/app/store/pharmacy-phones';
import { selectParams } from '$/app/store/router/router.selectors';
import { ApiData, getAllPages, scanPaginatedResponses } from '$/app/utils';
import { pharmacySchema } from '$shared';
import { normalizeToArrayAll } from '$shared/normalization/normalize-to-array-all';
import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { normalize } from 'normalizr';
import { EMPTY } from 'rxjs';
import {
  catchError,
  exhaustMap,
  mergeMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import {
  PharmaciesApiActions,
  PharmaciesGeneralActions,
  PharmaciesListActions
} from './actions';

@Injectable()
export class PharmaciesEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly router = inject(Router);
  private readonly pharmaciesService = inject(PharmaciesApiService);
  private readonly route = inject(ActivatedRoute);
  private readonly effectHelpers = inject(EffectHelpersService);

  loadPharmacies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        PharmaciesListActions.loadPharmacies,
        ResidentModalActions.loadPharmacies,
        InventoryItemFormActions.loadPharmacies
      ),
      this.effectHelpers.apiRequest({
        description: 'Get ',
        onRequest: (action) =>
          this.pharmaciesService
            .getAll(action.params)
            .pipe(
              getAllPages(this.pharmaciesService, action?.params?.query),
              scanPaginatedResponses()
            ),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'pharmacies',
            response,
            PharmaciesApiActions.loadPharmaciesSuccess
          );

          return responseData.getActions();
        },
        onError: (error) => PharmaciesApiActions.loadPharmaciesFail({ error })
      })
    );
  });

  getPharmacies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PharmaciesListActions.getPharmacies),
      this.effectHelpers.apiRequest({
        description: 'Get ',
        onRequest: (action) =>
          this.pharmaciesService
            .getAll(action.params)
            .pipe(
              getAllPages(this.pharmaciesService, action?.params?.query),
              scanPaginatedResponses()
            ),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'pharmacies',
            response,
            PharmaciesApiActions.getPharmaciesSuccess
          );

          return responseData.getActions();
        },
        onError: (error) => PharmaciesApiActions.getPharmaciesFail({ error })
      })
    );
  });

  fetchPharmacyDetailsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PharmaciesApiActions.fetchPharmacySuccess),
      mergeMap((action) => {
        const normalizedPharmacyGraph = normalize(
          action.pharmacy,
          pharmacySchema
        );

        const { pharmacies, pharmacyPhones } = normalizeToArrayAll(
          normalizedPharmacyGraph.entities,
          { pharmacies: ['phones'] }
        );

        const actions = [];

        if (!isEmpty(pharmacies)) {
          actions.push(PharmaciesGeneralActions.addPharmacies({ pharmacies }));
        }

        if (!isEmpty(pharmacyPhones)) {
          actions.push(
            PharmacyPhonesGeneralActions.addPharmacyPhones({ pharmacyPhones })
          );
        }

        return actions;
      })
    )
  );

  createPharmacy$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PharmacyFormActions.createPharmacy),
        exhaustMap((action) =>
          this.pharmaciesService.create(action.pharmacy).pipe(
            tap(
              this.effectHelpers.onModalFormSubmitSuccess(
                'Pharmacy created successfully!'
              )
            ),
            catchError((error) => {
              this.store.dispatch(
                PharmaciesApiActions.createPharmacyFail({ error })
              );

              return EMPTY;
            })
          )
        )
      ),
    { dispatch: false }
  );

  createPharmacyFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PharmaciesApiActions.createPharmacyFail),
        tap(this.effectHelpers.onFormSubmitFail())
      ),
    { dispatch: false }
  );

  updatePharmacy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        PharmacyFormActions.updatePharmacy,
        FacilityContactsPageActions.mergePharmacies
      ),
      this.effectHelpers.apiRequest({
        description: 'Update Pharmacy',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.pharmaciesService.patch(
            action.id,
            action.changes,
            action.params
          );
        },
        onSuccess: (pharmacy) =>
          PharmaciesApiActions.updatePharmacySuccess({
            pharmacy
          }),
        onError: (error) => PharmaciesApiActions.updatePharmacyFail({ error })
      })
    );
  });

  deletePharmacy$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          FacilityContactsPageActions.deletePharmacy,
          PharmacyInfoPageActions.deletePharmacy
        ),
        exhaustMap((action) =>
          this.pharmaciesService.delete(action.id, action.params).pipe(
            tap(
              this.effectHelpers.onModalFormSubmitSuccess(
                'Pharmacy deleted successfully!'
              )
            ),
            withLatestFrom(this.store.select(selectParams)),
            tap(([, params]) => {
              this.router.navigate(
                ['facilities', params.facilityId, 'contacts'],
                { relativeTo: this.route }
              );
            }),
            catchError((error) => {
              this.store.dispatch(
                PharmaciesApiActions.deletePharmacyFail({ error })
              );

              return EMPTY;
            })
          )
        )
      ),
    { dispatch: false }
  );

  deletePharmacyFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PharmaciesApiActions.deletePharmacyFail),
        tap(this.effectHelpers.onFormSubmitFail())
      ),
    { dispatch: false }
  );
}
