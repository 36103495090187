import { dateRangeToQuery } from '$/app/utils';
import { createActionGroup } from '@ngrx/store';
import { ActivityLogFilters } from '../activity-log.vmodel';

export const ActivityLogsFilterModalActions = createActionGroup({
  source: 'Activity Logs Filter Modal',
  events: {
    'Filter Changed': (
      filters: ActivityLogFilters
    ): { filters: ActivityLogFilters; queryParams: Record<string, any> } => {
      return {
        filters,
        queryParams: {
          activities: filters.activities,
          ...dateRangeToQuery(filters.dateRange)
        }
      };
    }
  }
});
