import { AmountSegment } from '../types';
import { getMeasurementViaAlternateId } from './get-measurement-via-alternate-id';

export interface AmountDisplayOptions {
  /**
   * Display options only apply when the measurement is included in the amount
   * string. If no measurement information is included, then it's effectively
   * like showMeasurement is set to 'none'.
   *
   * none: don't show measurement for any segment
   * all: show measurement for all segments
   * last: show measurement for last segment only
   */
  showMeasurement?: 'none' | 'all' | 'last';
  measurement?: string;
  includeSpacing?: boolean;
}

/**
 * Creates a string representation of the amount segments.
 *
 * ***
 * e.g.
 * [
 *    {amount: '10', measurement: 'mg'},
 *    '/',
 *    {amount: '5', measurement: 'ml'}
 * ] => '10 mg / 5 ml'
 */
export function stringifyAmount(
  segments: AmountSegment[],
  options: AmountDisplayOptions = {}
): string {
  options.showMeasurement ||= 'all';
  options.includeSpacing ??= false;

  // TODO: when measurement is passed it should override the measurement in the segments
  // concentration measurements makes this a little more involved.
  options.measurement = getMeasurementViaAlternateId(options.measurement)?.id;

  const amount = segments.reduce((amountStr, segment, index): string => {
    const isLastSegment = index === segments.length - 1;

    if (typeof segment === 'string') {
      amountStr += options.includeSpacing ? ` ${segment} ` : segment;
    } else {
      const hideMeasurementForSegment =
        options.showMeasurement === 'none' ||
        (options.showMeasurement === 'last' && !isLastSegment);

      amountStr += hideMeasurementForSegment
        ? segment.amount
        : [segment.amount, segment?.measurement]
            .filter(Boolean)
            .join(options.includeSpacing ? ' ' : '');
    }

    return amountStr;
  }, '');

  return amount;
}
