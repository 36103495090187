import { Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'medName', standalone: true })
export class AlcMedicationNamePipe implements PipeTransform {
  private readonly sanitizer = inject(DomSanitizer);

  transform(value: {
    brandName?: string;
    genericName?: string;
    displayName?: string;
  }): SafeHtml {
    if (!value) {
      return null;
    }

    let name: string = '';

    if (value.displayName) {
      return this.sanitizer.bypassSecurityTrustHtml(value.displayName);
    }

    if (value.brandName) {
      name += value.brandName;
    }
    if (value.genericName) {
      name += ` <i>(${value.genericName})</i>`;
    }

    return this.sanitizer.bypassSecurityTrustHtml(name);
  }
}
