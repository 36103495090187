<h4 class="mb-[2px] mt-0 !text-sm !font-medium text-dark">
  <span [matTooltip]="tooltip()" matTooltipShowDelay="500">
    <span class="mr-2">{{ label() }}</span>
    @if (tooltip()) {
      <ion-icon class="align-middle" src="assets/icon/material/help.svg" />
    }
  </span>
</h4>
<ng-content select="[info-value]">
  <p class="m-0 !text-base text-medium-dark">
    <ng-content />
  </p>
</ng-content>
