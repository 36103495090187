import { AbstractApiService } from '$/app/services';
import { FeathersService } from '$/app/services/feathers.service';
import { IActivity } from '$shared/services/activity.schema';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivitiesWsActions } from './activities.actions';

@Injectable({ providedIn: 'root' })
export class ActivitiesApiService extends AbstractApiService<IActivity> {
  constructor(feathers: FeathersService, store: Store) {
    super('activities', feathers, store, {
      entityName: 'activity',
      created: ActivitiesWsActions.activitiesCreated,
      patched: ActivitiesWsActions.activitiesPatched,
      removed: ActivitiesWsActions.activitiesRemoved
    });
  }
}
