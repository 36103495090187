import { getDateRange } from '$/app/utils';
import { FacilityTime } from '$shared/utils/date-time/facility-time';
import { ActivityLogFilters } from '../activity-log.vmodel';

export const defaultFilters = (
  ft: FacilityTime,
  defaultActivities: string[] = []
): ActivityLogFilters => ({
  types: ['activity', 'outing'],
  activities: defaultActivities,
  dateRange: getDateRange(null, ft, 'past30Days')
});

export interface State {
  filters: ActivityLogFilters;
}

export const initialState: State = {
  filters: null
};
