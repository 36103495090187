import { Paginated } from '$/models';
import { IActivityLog } from '$shared/services/activity-log.schema';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ActivityLogsApiActions = createActionGroup({
  source: 'Activity Logs Api',
  events: {
    'Load Activity Logs Success': props<{
      activityLogs: Paginated<IActivityLog>;
      metadata: { pageQueryType: string };
    }>(),
    'Get Activity Logs Success': props<{
      activityLogs: Paginated<IActivityLog>;
      metadata: { pageQueryType: string };
    }>(),
    'Create Activity Log Success': props<{
      activityLog: IActivityLog;
    }>(),
    'Update Activity Log Success': props<{
      activityLog: IActivityLog;
    }>(),
    'Delete Activity Log Success': props<{ id: string }>(),

    'Load Activity Logs Fail': props<{ error: Error }>(),
    'Get Activity Logs Fail': props<{ error: Error }>(),
    'Create Activity Log Fail': props<{ error: Error }>(),
    'Update Activity Log Fail': props<{ error: Error }>(),
    'Delete Activity Log Fail': props<{ error: Error }>()
  }
});

export const ActivityLogsWsActions = createActionGroup({
  source: 'Activity Logs Ws',
  events: {
    'Activity Logs Created': props<{
      activityLog: IActivityLog;
    }>(),
    'Activity Logs Patched': props<{
      activityLog: IActivityLog;
    }>(),
    'Activity Logs Removed': props<{ id: string }>()
  }
});

export const ActivityLogsGeneralActions = createActionGroup({
  source: 'Activity Logs General',
  events: {
    'Clear Activity Logs': emptyProps(),
    'Add Activity Logs': props<{
      activityLogs: IActivityLog[];
    }>()
  }
});
