import { IPharmacyApiMessage } from '$shared/services/pharmacy-api-messages.schema';
import { Paginated } from '$shared/types';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const PharmacyApiMessagesApiActions = createActionGroup({
  source: 'Pharmacy Api Messages API',
  events: {
    'Load Pharmacy Api Messages Success': props<{
      pharmacyApiMessages: Paginated<IPharmacyApiMessage>;
    }>(),

    'Load Pharmacy Api Messages Fail': props<{ error: Error }>(),

    'Update Pharmacy Api Message Success': props<{
      pharmacyApiMessage:
        | IPharmacyApiMessage
        | IPharmacyApiMessage[]
        | Paginated<IPharmacyApiMessage>;
    }>(),
    'Update Pharmacy Api Message Fail': props<{ error: Error }>()
  }
});

export const PharmacyApiMessagesWsActions = createActionGroup({
  source: 'Pharmacy Api Messages WS',
  events: {
    'Pharmacy Api Messages Created': props<{
      pharmacyApiMessage: IPharmacyApiMessage;
    }>(),
    'Pharmacy Api Messages Patched': props<{
      pharmacyApiMessage: IPharmacyApiMessage;
    }>(),
    'Pharmacy Api Messages Removed': props<{ id: string }>()
  }
});

export const PharmacyApiMessagesGeneralActions = createActionGroup({
  source: 'Pharmacy Api Messages General',
  events: {
    'Clear Pharmacy Api Messages': emptyProps(),
    'Add Pharmacy Api Messages': props<{
      pharmacyApiMessages: IPharmacyApiMessage[];
    }>()
  }
});
