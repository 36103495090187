import { NormalizedSchema } from 'normalizr';
import { Dictionary } from '../types';
import { NormalizrEntities } from './normalizr-schemas';

export const getFieldsToRemove = (
  normalizedGraph: NormalizedSchema<any, any>
): Dictionary<string[]> => {
  const fieldsToRemove = Object.keys(normalizedGraph.entities).reduce(
    (fieldsToRemove, entity) => {
      if (NormalizrEntities[entity]?.relations) {
        fieldsToRemove[entity] = Object.keys(
          NormalizrEntities[entity].relations!
        );
      }

      return fieldsToRemove;
    },
    {} as Dictionary<string[]>
  );

  return fieldsToRemove;
};
