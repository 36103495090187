import { Update } from '$/models';
import { IPharmacyApiMessage } from '$shared/services/pharmacy-api-messages.schema';
import { IProposedChange } from '$shared/services/proposed-changes.schema';
import { createActionGroup, props } from '@ngrx/store';

export const MedicationReceivingTableActions = createActionGroup({
  source: 'Medication Receiving Table',
  events: {
    'Accept Pharmacy Api Messages': props<Update<IPharmacyApiMessage>>(),
    'Update Proposed Change': props<Update<IProposedChange>>(),
    'Change Pharmacy Api Message Relation':
      props<Update<IPharmacyApiMessage>>(),
    'Reject Pharmacy Api Messages': props<Update<IPharmacyApiMessage>>()
  }
});
