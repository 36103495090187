import { find, includes } from 'lodash';
import { Logger } from '../logger';
import { Measurement, measurements } from '../lookups/measurements.lookup';

/**
 * Match a measurement
 */
export function getMeasurementViaAlternateId(
  alternateId: string | undefined
): Measurement | undefined {
  if (!alternateId) {
    return;
  }

  alternateId = alternateId.toLowerCase();

  const measurement = find(measurements, (m) =>
    includes(m.alternatives, alternateId)
  );

  Logger.when(!measurement).warn(
    `Measurement ID could not be matched for ${alternateId}`
  );

  return measurement;
}
