import { find } from 'lodash';
import { Logger } from '../logger';
import { LogTypeId } from '../lookups/log-details.lookup';
import { lookupValue } from '../lookups/lookup-value';
import { LogDependencyRule } from './log-dependency-rule';
import { LogDependencyRuleAction } from './log-dependency-rule-action.model';

const logDependencyFieldMap: Record<LogTypeId, any> = {
  activity: {
    activityId: 'Activity'
  },
  behavior: {
    behaviorId: 'Behavior',
    occurrences: 'Occurrences'
  },
  bp: {
    diastolic: 'Diastolic',
    systolic: 'Systolic',
    pulse: 'Pulse'
  },
  bs: {
    level: 'Level'
  },
  bm: {
    bmSizeId: 'Size',
    bmTextureId: 'Texture'
  },
  food: {
    mealTypeId: 'Meal Type',
    amount: 'Amount',
    target: 'Target'
  },
  mood: {
    mood: 'Mood'
  },
  sleep: {
    hoursAsleep: 'Asleep',
    hoursAwake: 'Awake',
    timesUp: 'Times Up'
  },
  temp: {
    temperature: 'Temperature',
    temperatureUnit: 'Temperature Unit'
  },
  water: {
    amount: 'Amount',
    target: 'Target'
  },
  weight: {
    weight: 'Weight',
    weightUnitId: 'Weight Unit'
  },
  // TODO: What should each of these look like?
  bodyCheck: undefined,
  oxygen: undefined,
  position: undefined,
  seizure: undefined,
  shower: undefined,
  note: undefined,
  urine: undefined
};

export interface ILogDependency {
  type: LogTypeId;
  rules: LogDependencyRule[];
}

export class LogDependency {
  type: LogTypeId;
  rules: LogDependencyRule[];

  constructor(logDependency: ILogDependency) {
    this.type = logDependency.type;
    this.rules = logDependency.rules?.map(
      (rule) => new LogDependencyRule(rule)
    );
  }

  /**
   * Returns the first valid log dependency rule.
   */
  private getValidRule(logRecord: any): LogDependencyRule | undefined {
    const validRule = find(this.rules, (rule) => rule.validate(logRecord));

    if (!validRule) {
      Logger.warn(
        'Could not match a log dependency rule. This will happen if the rules in the medication form are configured incorrectly. e.g. A rule exists to hold, but not to give.',
        { rules: this.rules, logRecord }
      );
    }

    return validRule;
  }

  /**
   * Gets the calculated dose from the first valid log dependency rule.
   */
  getValidAction(logRecord: any): LogDependencyRuleAction | undefined {
    return this.getValidRule(logRecord)?.action;
  }

  generateOmitNotes(logRecord: any): string {
    const rule = this.getValidRule(logRecord);

    if (!rule) {
      return '';
    }

    const conditions = rule.conditions;
    const logDetail = lookupValue('logDetails', this.type);

    if (!conditions?.length) {
      return '';
    }

    const messages = conditions.map((condition) => {
      const field = logDependencyFieldMap[this.type][condition.field];
      const operator = condition.operator;
      const value = condition.value;

      return `${field} ${operator} ${value}`;
    });

    return `${logDetail} Log: ${messages.join(', ').trim()}`;
  }
}
