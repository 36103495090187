import { AlcAction, IDocument, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const DocumentActionMenuActions = createActionGroup({
  source: 'Document Action Menu',
  events: {
    'Upload File': AlcAction<{
      id: string;
      changes: Pick<IDocument, 'status' | 'statusChangedAt' | 'expiresAt'>;
      file: File;
    }>(),

    'Remove File': (documentId: string, fileId: string): Update<IDocument> => ({
      id: documentId,
      changes: { fileId: null },
      params: {
        query: { $actions: [{ removeFile: { fileId } }] },
        loading: { message: 'Removing file...' },
        toast: { message: 'File removed successfully' }
      }
    }),

    'Delete Document': AlcAction<{ id: string }>(),

    'Renew Document': props<Update<IDocument>>()
  }
});
