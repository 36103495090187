import { AbstractApiService } from '$/app/services';
import { FeathersService } from '$/app/services/feathers.service';
import { IActivityResident } from '$shared/services/activity-resident.schema';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivityResidentsWsActions } from './activity-residents.actions';

@Injectable({ providedIn: 'root' })
export class ActivityResidentsApiService extends AbstractApiService<IActivityResident> {
  constructor(feathers: FeathersService, store: Store) {
    super('activity-residents', feathers, store, {
      entityName: 'activityResident',
      created: ActivityResidentsWsActions.activityResidentCreated,
      patched: ActivityResidentsWsActions.activityResidentPatched,
      removed: ActivityResidentsWsActions.activityResidentRemoved
    });
  }
}
