import { Logger } from '$shared/logger';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function notEmpty(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const value = control.value as any[];

    if (!value) {
      return null;
    }

    Logger.assert(
      Array.isArray(value),
      'The form control value needs to be an array'
    );

    return value.length ? null : { notEmpty: true };
  };
}
