import { Dictionary, MaybeArray, ServiceActionGroup } from '$shared/types';
import { NavigationExtras } from '@angular/router';
import { Query } from '@feathersjs/feathers';
import {
  LoadingOptions,
  RefresherEventDetail
} from '@ionic/angular/standalone';
import { Action } from '@ngrx/store';

export interface RedirectOptions {
  pathSegments: string[];
  extras?: NavigationExtras;
  wait?: number;
}

export type TQuery = Query & {
  $actions?: ServiceActionGroup[];
};

export interface Params {
  query?: TQuery;
  userAction?: string;
  toast?: string | { message: string };
  refresher?: RefresherEventDetail;
  loading?: string | LoadingOptions;
  redirect?: string | RedirectOptions;
  errorMessages?: {
    skip?: boolean;
    title?: string;
    message?: string;
  };
  onSuccess?: (result?: any) => any;
  onError?: (error: any) => any;
  onComplete?: () => any;
}

export function AlcAction<T = Dictionary<any>>(staticParams?: Params) {
  return (props?: T & { params?: Params }) => ({
    ...props,
    params: {
      query: {},
      ...staticParams,
      ...props?.params
    }
  });
}

export interface Update<T> {
  id: string | null;
  changes: Partial<T>;
  params?: Params;
}

export interface Delete {
  id: string;
  params?: Params;
}

export interface Create<T> {
  data: MaybeArray<T>;
  params?: Params;
}

export interface Load {
  params?: Params;
}

export interface ActionWithProps extends Action {
  [key: string]: any;
}
