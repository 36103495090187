import { Update } from '$/models';
import { IFacilitySetting } from '$shared/facility-settings/types';
import { createActionGroup, props } from '@ngrx/store';

export const GeofenceSettingsComponentActions = createActionGroup({
  source: 'Geofence Settings Component',
  events: {
    'Update Geofence Setting': props<Update<IFacilitySetting>>()
  }
});
