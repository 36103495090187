import { Params, Update } from '$/models';
import { IMedicalProfessionalWithPhones } from '$shared/services/medical-professional.schema';
import { IProposedChange } from '$shared/services/proposed-changes.schema';
import { createActionGroup, props } from '@ngrx/store';

export const MedicalProfessionalFormActions = createActionGroup({
  source: 'Medical Professional Form',
  events: {
    'Create Medical Professional': props<{
      medicalProfessional: IMedicalProfessionalWithPhones & {
        residentId?: string;
      };
      params?: Params;
    }>(),

    'Update Medical Professional':
      props<Update<IMedicalProfessionalWithPhones>>(),
    'Reject Proposed Change': props<Update<IProposedChange>>()
  }
});
