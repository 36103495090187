import { InventoryItemFormActions } from '$/app/pages/medications/shared/components/inventory-item-form/inventory-item-form.modal.actions';
import { FacilityTimeService, OverlayService } from '$/app/services';
import { AlcMedicalProfessionalFormModal } from '$/app/shared/pages/forms/medical-professional-form/medical-professional-form.modal';
import { AlcPharmacyFormModal } from '$/app/shared/pages/forms/pharmacy-form/pharmacy-form.modal';
import { SharedModule } from '$/app/shared/shared.module';
import { AlcomyValidators } from '$/app/shared/validators';
import { FacilitySettingsSelectors } from '$/app/store';
import * as MedicationInventoryItemsSelectors from '$/app/store/medication-inventory-items/medication-inventory-items.selectors';
import { PharmaciesSelectors } from '$/app/store/pharmacies';
import { validateForm } from '$/app/utils';
import { MedicationOrderItem } from '$/models';
import { DateTimeFormats } from '$shared/constants/datetime-formats';
import { GeneralSettingsEnum } from '$shared/facility-settings/constants';
import { Logger } from '$shared/logger';
import { lookups, lookupsById } from '$shared/lookups';
import { AmountTypeId } from '$shared/lookups/amount-types.lookup';
import { Measurement } from '$shared/lookups/measurements.lookup';
import { MedicationForm } from '$shared/lookups/medication-forms.lookup';
import { IMedicationInventoryItem } from '$shared/medication-inventory-items';
import { IMedication } from '$shared/medications';
import { IResident } from '$shared/residents/residents.interface';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  computed,
  inject
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { filter } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MedicationAndOrderItem } from '../../../dashboard/medication-orders/medication-order-details/medication-order-details.vmodel';
import * as InventoryItemFormSelectors from './inventory-item-form.modal.selectors';

@Component({
  selector: 'alc-inventory-item-form',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './inventory-item-form.modal.html',
  styles: `
    ::ng-deep mat-form-field [mattextsuffix] {
      margin-left: 8px !important;
    }
  `
})
export class AlcInventoryItemFormModal implements OnInit, OnDestroy {
  private readonly overlay = inject(OverlayService);
  private readonly store = inject(Store);
  private readonly fb = inject(UntypedFormBuilder);
  private readonly ft = inject(FacilityTimeService);

  protected readonly lookups = lookups;

  protected form: UntypedFormGroup;
  private defaultAmountType: AmountTypeId = 'qty';
  protected measurement: Measurement;
  protected amountMeasurement: Measurement;

  @Input() resident: IResident;
  @Input() medication: IMedication | MedicationAndOrderItem;
  @Input() medicationOrderItem?: MedicationOrderItem;
  @Input() medicationInventoryItem?: IMedicationInventoryItem;

  now = this.ft.createDateTime();

  private previousInventoryItem$: Observable<IMedicationInventoryItem>;

  private readonly allPrescribers = this.store.selectSignal(
    InventoryItemFormSelectors.selectMedicalProfessionalsDropdown
  );

  protected readonly prescribers = computed(() => {
    return filter(this.allPrescribers(), { residentId: this.resident?.id });
  });

  protected readonly pharmacies = this.store.selectSignal(
    PharmaciesSelectors.selectAll
  );

  protected readonly inventoryCountSetting = this.store.selectSignal(
    FacilitySettingsSelectors.selectCurrentFacilitySettingValueByCode(
      GeneralSettingsEnum.MEDICATION_INVENTORY_COUNT
    )
  );

  protected medicationForm: MedicationForm;

  private destroyed$ = new Subject<void>();

  ngOnInit() {
    this.store.dispatch(InventoryItemFormActions.loadPharmacies());

    this.store.dispatch(
      InventoryItemFormActions.getResidentMedicalProfessionals({
        residentId: this.resident.id
      })
    );

    this.store.dispatch(
      InventoryItemFormActions.getLastMedicationInventory({
        query: {
          medicationBatchId: this.medication.batchId,
          $limit: 1,
          $sort: {
            itemNumber: -1
          }
        }
      })
    );

    this.previousInventoryItem$ = this.store.select(
      MedicationInventoryItemsSelectors.selectLastMedicationInventoryItem(
        this.medication.batchId
      )
    );

    if (this.medication) {
      this.measurement =
        lookupsById.measurements[this.medication.measurementId];
      this.medicationForm = lookupsById.medicationForms[this.medication.formId];
      this.defaultAmountType = this.medicationForm.amountTypeId;

      const mlMeasurements = ['ml', 'mgml', 'gml', 'unitml'];
      this.amountMeasurement = mlMeasurements.includes(this.measurement.id)
        ? lookupsById.measurements['ml']
        : lookupsById.measurements[this.measurement.id];
    }

    this.createForm();

    if (this.medication.measurementId === 'unit') {
      this.form.get('strength').patchValue('1');
      this.form.get('strength').disable();
    }

    if (!this.medicationInventoryItem) {
      this.previousInventoryItem$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((invItem) => {
          if (this.form && invItem) {
            if (this.medication.measurementId === 'unit') {
              this.form.get('strength').patchValue('1');
              this.form.get('strength').disable();
            }

            this.form.patchValue({
              isOTC: invItem.isOTC,
              pharmacyId:
                this.medicationOrderItem?.pharmacyId || invItem?.pharmacyId,
              rxNumber:
                invItem?.rxNumber && invItem?.refills > 0
                  ? invItem?.rxNumber
                  : null,
              medicalProfessionalId: invItem?.medicalProfessionalId,
              amountTypeId: invItem?.amountTypeId,
              amountMeasurementId:
                invItem?.amountMeasurementId || this.amountMeasurement.id,
              amount: invItem?.amount,
              refills: invItem?.refills > 0 ? invItem?.refills - 1 : null
            });
          }
        });
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  protected changeExpirationDate() {
    if (!this.form.value.expireDate && this.form.value.fillDate) {
      this.form.controls.expireDate.patchValue(
        this.form.value.fillDate.plus({ years: 1 })
      );
    }
  }

  protected async close() {
    await this.overlay.dismissModal();
  }

  protected async createMedicalProfessional() {
    await this.overlay.showModal({
      component: AlcMedicalProfessionalFormModal,
      componentProps: {
        residentId: this.resident.id,
        theme: 'residents'
      }
    });
  }

  protected async createPharmacy() {
    await this.overlay.showModal({
      component: AlcPharmacyFormModal,
      componentProps: {
        theme: 'residents'
      }
    });
  }

  protected async submit() {
    try {
      const value = validateForm(this.form);

      if (value.isOTC) {
        delete value.pharmacyId;
        delete value.rxNumber;
      }

      if (this.medicationInventoryItem?.id !== undefined) {
        this.store.dispatch(
          InventoryItemFormActions.updateMedicationInventoryItem({
            id: this.medicationInventoryItem.id,
            changes: value
          })
        );
      } else {
        this.store.dispatch(
          InventoryItemFormActions.createMedicationInventoryItem({
            medicationInventoryItem: value
          })
        );
      }
    } catch (error) {
      Logger.error('Error submitting new inventory form', { error });

      await this.overlay.showToast(
        'failure',
        'Validation Error: Fields marked in red are not valid. Please correct them before submitting.'
      );
    }
  }

  private createForm() {
    this.form = this.fb.group({
      isOTC: [this.medicationInventoryItem?.isOTC ?? false],
      isCurrent: [false],
      isEmpty: [false],
      pharmacyId: [
        {
          value:
            this.medicationOrderItem?.pharmacyId ??
            this.medicationInventoryItem?.pharmacyId,
          disabled: this.medicationOrderItem?.pharmacyId
        }
      ],
      rxNumber: [this.medicationInventoryItem?.rxNumber],
      medicalProfessionalId: [
        this.medicationInventoryItem?.medicalProfessionalId,
        Validators.required
      ],
      amountTypeId: [
        this.medicationInventoryItem?.amountTypeId ?? this.defaultAmountType,
        Validators.required
      ],
      amount: [
        this.medicationInventoryItem?.amount
          ? this.medicationInventoryItem.amount
          : null,
        Validators.required
      ],
      amountMeasurementId: [
        this.medicationInventoryItem?.amountMeasurementId ??
          this.amountMeasurement.id,
        RxwebValidators.required({
          conditionalExpression: (x) => {
            return x.amountTypeId === 'total';
          }
        })
      ],
      strength: [
        this.medicationInventoryItem?.strength ?? this.medication?.strength,
        Validators.required
      ],
      refills: [
        this.medicationInventoryItem?.refills,
        [Validators.required, Validators.min(0)]
      ],
      fillDate: [this.medicationInventoryItem?.fillDate],
      expireDate: [
        this.medicationInventoryItem?.expireDate,
        Validators.required
      ],
      startDate: [this.medicationInventoryItem?.startDate],
      endDate: [
        this.medicationInventoryItem?.endDate,
        AlcomyValidators.minDateTime(() => this.form?.get('startDate').value)
      ],
      medicationId: [this.medication.id],
      medicationBatchId: [this.medication.batchId]
    });

    if (isMedicationAndOrderItem(this.medication)) {
      this.form.addControl(
        'medicationOrderItemId',
        new UntypedFormControl(this.medication.medicationOrderItem.id)
      );
    }

    this.toggleOnOTC(this.form.get('isOTC').value);

    this.form
      .get('isOTC')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.toggleOnOTC(value);
      });
  }

  private toggleOnOTC(value: boolean) {
    const pharmacyId = this.form.get('pharmacyId');
    const rxNumber = this.form.get('rxNumber');
    const fillDate = this.form.get('fillDate');

    if (value === false) {
      pharmacyId.setValidators(Validators.required);
      rxNumber.setValidators(Validators.required);
      fillDate.setValidators(Validators.required);
    } else {
      pharmacyId.clearValidators();
      rxNumber.clearValidators();
      fillDate.clearValidators();
    }

    pharmacyId.updateValueAndValidity();
    rxNumber.updateValueAndValidity();
    fillDate.updateValueAndValidity();
  }

  formatShortDate(isoDate?: string | null): string {
    if (!isoDate) {
      return null;
    }

    return this.ft.createDateTime(isoDate).toFormat(DateTimeFormats.DATE_SHORT);
  }
}

function isMedicationAndOrderItem(
  medication: IMedication | MedicationAndOrderItem
): medication is MedicationAndOrderItem {
  return !!(medication as MedicationAndOrderItem).medicationOrderItem;
}
