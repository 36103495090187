import { Params } from '$/models';
import { MedicationTaskSubmission } from '$shared/medication-tasks';
import { props } from '@ngrx/store';

import { createActionGroup } from '@ngrx/store';

export const MedicationMarsActionModalActions = createActionGroup({
  source: 'Medication Mars Action Modal',
  events: {
    'Change Current State': (
      id: string,
      changes: MedicationTaskSubmission,
      params: Params = { query: {} }
    ) => ({ id, changes, params }),

    'Delete Activity Log': props<{ id: string; params?: Params }>(),
    'Delete Behavior Log': props<{ id: string; params?: Params }>(),
    'Delete Blood Pressure Log': props<{ id: string; params?: Params }>(),
    'Delete Blood Sugar Log': props<{ id: string; params?: Params }>(),
    'Delete Body Check Log': props<{ id: string; params?: Params }>(),
    'Delete Bowel Movement Log': props<{ id: string; params?: Params }>(),
    'Delete Food Log': props<{ id: string; params?: Params }>(),
    'Delete Mood Log': props<{ id: string; params?: Params }>(),
    'Delete Oxygen Log': props<{ id: string; params?: Params }>(),
    'Delete Position Log': props<{ id: string; params?: Params }>(),
    'Delete Seizure Log': props<{ id: string; params?: Params }>(),
    'Delete Sleep Log': props<{ id: string; params?: Params }>(),
    'Delete Shower Log': props<{ id: string; params?: Params }>(),
    'Delete Temperature Log': props<{ id: string; params?: Params }>(),
    'Delete Urine Log': props<{ id: string; params?: Params }>(),
    'Delete Water Log': props<{ id: string; params?: Params }>(),
    'Delete Weight Log': props<{ id: string; params?: Params }>()
  }
});
