import { AlcCommonModule } from '$/app/shared/alc-common.module';
import { Component, input, output } from '@angular/core';

@Component({
  selector: 'alc-info-card',
  standalone: true,
  imports: [AlcCommonModule],
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss']
})
export class AlcInfoCardComponent {
  title = input<string>('');
  showButton = input(true);
  icon = input('more_vert');
  buttonTestId = input<string>();
  cardClasses = input<string>('');

  buttonClicked = output<MouseEvent>();

  protected onButtonClick(ev: MouseEvent) {
    this.buttonClicked.emit(ev);
  }

  protected setIconPath() {
    return `/assets/icon/material/${this.icon()}.svg`;
  }
}
