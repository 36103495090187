import { Params } from '$/models';
import { createActionGroup } from '@ngrx/store';

export const MissedBowelMovementsPageActions = createActionGroup({
  source: 'Missed Bowel Movements Page',
  events: {
    'Load Missed Bowel Movements': (params: Params) => ({ params }),
    'Get Residents': (params: any) => ({ params })
  }
});
