import { IProposedChange } from '$shared/services/proposed-changes.schema';
import { Paginated } from '$shared/types';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ProposedChangesApiActions = createActionGroup({
  source: 'Proposed Changes API',
  events: {
    'Load Proposed Changes Success': props<{
      proposedChanges: IProposedChange[];
    }>(),
    'Load Proposed Changes Fail': props<{ error: Error }>(),
    'Update Proposed Change Success': props<{
      proposedChange:
        | IProposedChange
        | IProposedChange[]
        | Paginated<IProposedChange>;
    }>(),
    'Update Proposed Change Fail': props<{ error: Error }>()
  }
});

export const ProposedChangesWsActions = createActionGroup({
  source: 'Proposed Changes WS',
  events: {
    'Proposed Changes Created': props<{
      proposedChange: IProposedChange;
    }>(),
    'Proposed Changes Patched': props<{
      proposedChange: IProposedChange;
    }>(),
    'Proposed Changes Removed': props<{ id: string }>()
  }
});

export const ProposedChangesGeneralActions = createActionGroup({
  source: 'Proposed Changes General',
  events: {
    'Clear Proposed Changes': emptyProps(),
    'Add Proposed Changes': props<{
      proposedChanges: IProposedChange[];
    }>()
  }
});
