import { AbstractApiService } from '$/app/services';
import { FeathersService } from '$/app/services/feathers.service';
import { IActivityLog } from '$shared/services/activity-log.schema';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivityLogsWsActions } from './activity-logs.actions';

@Injectable({ providedIn: 'root' })
export class ActivityLogsApiService extends AbstractApiService<IActivityLog> {
  constructor(feathers: FeathersService, store: Store) {
    super('activity-logs', feathers, store, {
      entityName: 'activityLog',
      created: ActivityLogsWsActions.activityLogsCreated,
      patched: ActivityLogsWsActions.activityLogsPatched,
      removed: ActivityLogsWsActions.activityLogsRemoved
    });
  }
}
