import { FeathersService } from '$/app/services/feathers.service';
import { MedicationsApiActions } from '$/app/store/medications/actions';
import { Paginated, Params } from '$/models';
import { IMedication, IMedicationGraph } from '$shared/medications';
import { MaybeArray } from '$shared/types/utility-types';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MedicationsApiService {
  private medicationsService: Service<IMedication>;

  constructor(feathers: FeathersService, store: Store) {
    this.medicationsService = feathers.client.service('medications');

    this.medicationsService.on('created', (medication) => {
      store.dispatch(
        MedicationsApiActions.medicationsCreated({
          medication
        })
      );
    });

    this.medicationsService.on('patched', (medication) => {
      store.dispatch(
        MedicationsApiActions.medicationsPatched({
          medication
        })
      );
    });

    this.medicationsService.on('removed', (medication) => {
      store.dispatch(
        MedicationsApiActions.medicationsRemoved({
          id: medication.id
        })
      );
    });
  }

  getAll(
    params: Params = { query: {} }
  ): Observable<Paginated<IMedicationGraph>> {
    const promise = this.medicationsService.find(params) as Promise<
      Paginated<IMedicationGraph>
    >;
    return from(promise);
  }

  get(id: string, params: Params = { query: {} }): Observable<IMedication> {
    const promise = this.medicationsService.get(id, params);
    return from(promise);
  }

  create(
    medication: MaybeArray<Partial<IMedication>>,
    params: Params = { query: {} }
  ) {
    const promise = this.medicationsService.create(medication, params);
    return from(promise);
  }

  patch(
    id: string,
    medication: Partial<IMedicationGraph>,
    params: Params = { query: {} }
  ): Observable<IMedication> {
    const promise = this.medicationsService.patch(id, medication, params);
    return from(promise);
  }

  delete(id: string, params: Params = { query: {} }): Observable<IMedication> {
    const promise = this.medicationsService.remove(id, params);
    return from(promise);
  }
}
