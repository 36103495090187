import { ResidentContactComponentActions } from '$/app/pages/residents/resident-detail/resident-contact-item/resident-contact-item.actions';
import { ResidentContactFormActions } from '$/app/shared/pages/forms/resident-contact-form/resident-contact-form.actions';
import {
  State,
  initialState,
  residentContactsAdapter
} from '$/app/store/resident-contacts/resident-contacts.state';
import { getPaginatedData } from '$/app/utils';
import { Action, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  ResidentContactsApiActions,
  ResidentContactsGeneralActions
} from './actions';

const reducer = createReducer(
  initialState,

  on(
    ResidentContactFormActions.createResidentContact,
    ResidentContactFormActions.updateResidentContact,
    ResidentContactComponentActions.deleteResidentContact,
    (state) => {
      return {
        ...state,
        loading: true
      };
    }
  ),

  on(
    ResidentContactsApiActions.loadResidentContactsSuccess,
    (state, action) => {
      return residentContactsAdapter.setAll(
        getPaginatedData(action.residentContacts),
        {
          ...state,
          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(ResidentContactsApiActions.getResidentContactsSuccess, (state, action) => {
    return residentContactsAdapter.upsertMany(
      getPaginatedData(action.residentContacts),
      {
        ...state,
        loading: false,
        loaded: true,
        error: null
      }
    );
  }),

  on(
    ResidentContactsApiActions.fetchResidentContactSuccess,
    (state, action) => {
      return residentContactsAdapter.upsertOne(action.residentContact, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    ResidentContactsApiActions.createResidentContactSuccess,
    (state, action) => {
      const residentContacts = castArray(action.residentContact);

      return residentContactsAdapter.addMany(residentContacts, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    ResidentContactsApiActions.updateResidentContactSuccess,
    (state, action) => {
      return residentContactsAdapter.upsertOne(action.residentContact, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    ResidentContactsApiActions.deleteResidentContactSuccess,
    (state, action) => {
      return residentContactsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    ResidentContactsApiActions.loadResidentContactsFail,
    ResidentContactsApiActions.getResidentContactsFail,
    ResidentContactsApiActions.fetchResidentContactFail,
    ResidentContactsApiActions.createResidentContactFail,
    ResidentContactsApiActions.updateResidentContactFail,
    ResidentContactsApiActions.deleteResidentContactFail,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
  ),

  on(
    ResidentContactsApiActions.residentContactsCreated,
    ResidentContactsApiActions.residentContactsPatched,
    (state, action) => {
      return residentContactsAdapter.upsertOne(action.residentContact, state);
    }
  ),

  on(ResidentContactsApiActions.residentContactsRemoved, (state, action) => {
    return residentContactsAdapter.removeOne(action.id, state);
  }),

  on(ResidentContactsGeneralActions.addResidentContacts, (state, action) => {
    return residentContactsAdapter.upsertMany(action.residentContacts, {
      ...state,
      loading: false,
      error: null
    });
  }),

  on(ResidentContactsGeneralActions.clearResidentContacts, (state) => {
    return residentContactsAdapter.removeAll({
      ...state,
      loading: false,
      loaded: false,
      error: null
    });
  })
);

export function residentContactsReducer(
  state = initialState,
  action: Action
): State {
  return reducer(state, action);
}
