import {
  differenceBy,
  differenceWith,
  filter,
  intersection,
  intersectionWith,
  isArray,
  isEmpty,
  isEqual,
  isObjectLike,
  isUndefined,
  keyBy,
  keys,
  reduce,
  union
} from 'lodash';
import { Logger } from './logger';

export type EntityRecord<T extends keyof any, K> = Record<T, K> & {
  id: string;
};

export type RecordUpdate<T extends Record<string, any>> = {
  id: string;
  changes: Partial<T>;
};

export type EntityUpdate<T extends EntityRecord<string, any>> = {
  id: string;
  changes: Partial<T>;
};

export type ChangeReport<T> = {
  added: T[];
  updated: EntityUpdate<EntityRecord<string, any>>[];
  removed: T[];
  existing: T[];
};

/**
 * Returns the changes between simple objects. When checking against an array of
 * objects, objects are compared based on their position in the array.
 * fieldStrategy determines what keys to compare between the two objects.
 * 'union' compares all keys between the two objects.
 * 'intersection' compares only the keys that are present in both objects.
 */
export function getRecordChanges<
  T extends Record<string, any> = Record<string, any>
>(
  original: T,
  updated: T | Partial<T>,
  fieldStrategy: 'union' | 'intersection' = 'union'
): Partial<T> {
  Logger.assert(
    isObjectLike(original) &&
      !isArray(original) &&
      isObjectLike(updated) &&
      !isArray(updated),
    'Both arguments must be defined and be an object',
    { original, updated }
  );

  const keyNames =
    fieldStrategy === 'union'
      ? union(keys(original), keys(updated))
      : intersection(keys(original), keys(updated));

  const changes = keyNames.reduce((acc, key) => {
    if (!isEqual(original[key], updated[key])) {
      acc[key] = updated[key];
    }
    return acc;
  }, {} as any);

  return changes;
}

/**
 * Use this to get the id and changed properties between two entity objects
 * (i.e. objects with an id property). If the includeOriginal flag is set to
 * true all the properties from the original object will be returned along side
 * the changes property.
 */
export function getEntityChanges<
  T extends EntityRecord<string, any> = EntityRecord<string, any>,
  U extends boolean = false,
  TResult = U extends true ? T & { changes: Partial<T> } : EntityUpdate<T>
>(original: T, updated: Partial<T>, includeOriginal: U = false as U): TResult {
  Logger.assert(
    isEntityRecord(original) && isEntityRecord(updated),
    'Both arguments must be defined and have an id property',
    { original, updated }
  );

  return includeOriginal
    ? ({
        ...original,
        changes: getRecordChanges(original, updated)
      } as any)
    : ({
        id: updated.id,
        changes: getRecordChanges(original, updated)
      } as any);
}

// returns an array of items that are in originalRecords but not in newRecords
export function getRemoved<T>(
  originalRecords: T[],
  newRecords: T[],
  key: string = 'id'
): T[] {
  if (!originalRecords?.length) {
    return [];
  }

  if (isEntityRecord(originalRecords[0])) {
    return differenceBy(originalRecords, newRecords, key);
  }

  return differenceWith(originalRecords, newRecords, isEqual);
}

// returns an array of items that are in newRecords but not in originalRecords
export function getAdded<T>(
  originalRecords: T[],
  newRecords: T[],
  key: string = 'id'
): T[] {
  if (!newRecords?.length) {
    return [];
  }

  if (isEntityRecord(newRecords[0])) {
    return differenceBy(newRecords, originalRecords, key);
  }

  // fallback to simple isEqual comparison when not an entity
  return differenceWith(newRecords, originalRecords, isEqual);
}

export function getEntityUpdates<
  T extends EntityRecord<string, any> = EntityRecord<string, any>
>(originalRecords: T[], updatedRecords: Partial<T>[]): EntityUpdate<T>[] {
  if (!originalRecords?.length || !updatedRecords?.length) {
    return [];
  }

  const originalMap = keyBy(originalRecords, 'id');

  if (isEmpty(originalMap)) {
    return [];
  }

  // Find updated records that have different data than the original
  return reduce(
    updatedRecords,
    (acc: EntityUpdate<T>[], updatedItem): EntityUpdate<T>[] => {
      if (!isEntityRecord(updatedItem)) {
        return acc;
      }

      const originalItem = updatedItem.id && originalMap[updatedItem.id];

      if (!originalItem) {
        return acc;
      }

      const changes = getEntityChanges(originalItem, updatedItem);

      if (!isEmpty(changes.changes)) {
        acc.push(changes);
      }

      return acc;
    },
    []
  );
}

export function getExisting<T>(originalRecords: T[], newRecords: T[]): T[] {
  if (isEntityRecord(newRecords[0])) {
    const originalMap = keyBy(originalRecords, 'id');

    if (!originalRecords.length || isEmpty(originalMap)) {
      return [];
    }

    // Find updated records that have different data than the original
    return filter(newRecords, (item) =>
      isEntityRecord(item) ? isEqual(item, originalMap[item.id]) : false
    );
  }

  return intersectionWith(originalRecords, newRecords, isEqual);
}

/**
 * When the values passed are not entities (objects with id property), the value itself is compared
 * using isEqual to determine what is added, removed, or existing.
 *
 * When the values passed are entities (objects with ids), the entity's id is
 * utilized in comparisons. This allows the function to accurately determine
 * which items have been updated
 */
export function getChangeReport<T>(
  originalItems: T[],
  newItems: T[]
): ChangeReport<T> {
  Logger.assert(
    Array.isArray(originalItems),
    'originalRecords must be an array',
    { originalRecords: originalItems }
  );

  Logger.assert(Array.isArray(newItems), 'newRecords must be an array', {
    newRecords: newItems
  });

  const report: ChangeReport<T> = {
    added: getAdded(originalItems, newItems),
    updated: [],
    removed: getRemoved(originalItems, newItems),
    existing: getExisting(originalItems, newItems)
  };

  if (
    originalItems?.length &&
    newItems?.length &&
    isEntityRecordArray(originalItems) &&
    isEntityRecordArray(newItems)
  ) {
    report.updated = getEntityUpdates(originalItems, newItems);
  }

  return report;
}

export function isEntityRecord(
  value: any,
  key: string = 'id'
): value is EntityRecord<string, any> {
  return isObjectLike(value) && !isArray(value) && !isUndefined(value[key]);
}

export function isEntityRecordArray(
  records: any[],
  key: string = 'id'
): records is EntityRecord<string, any>[] {
  return Array.isArray(records) && records[0]?.[key];
}
