import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AbstractApiService } from '$/app/services/api/abstract-api-service.service';
import { FeathersService } from '$/app/services/feathers.service';
import { IPharmacyApiMessage } from '$shared/services/pharmacy-api-messages.schema';
import { PharmacyApiMessagesWsActions } from './pharmacy-api-messages.actions';

@Injectable({
  providedIn: 'root'
})
export class PharmacyApiMessagesApiService extends AbstractApiService<IPharmacyApiMessage> {
  constructor(feathers: FeathersService, store: Store) {
    super('pharmacy-api-messages', feathers, store, {
      entityName: 'pharmacyApiMessage',
      created: PharmacyApiMessagesWsActions.pharmacyApiMessagesCreated,
      patched: PharmacyApiMessagesWsActions.pharmacyApiMessagesPatched,
      removed: PharmacyApiMessagesWsActions.pharmacyApiMessagesRemoved
    });
  }
}
