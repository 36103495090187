import { AlcLogFormComponent } from '$/app/shared/pages/forms/base-log-form/base-log-form.component';
import { InputProps } from '$/app/utils/input-props';
import { LogTypeId } from '$shared/lookups/log-details.lookup';
import { backgroundTask } from '$shared/utils/background-task';
import { getOrReport } from '$shared/utils/get-or-report';
import { logModalLoadersByLogTypeId } from './#log-modal-loaders';
import { OverlayService } from './index';
import {
  AlcLogModalOptions,
  AlcLogModalResultProvider,
  LogModalResultByTypeId
} from './types';

export function showLogModal<
  TLogTypeId extends LogTypeId,
  TOptions extends AlcLogModalOptions<TLogTypeId>,
  TComponent = Awaited<
    ReturnType<(typeof logModalLoadersByLogTypeId)[TLogTypeId]>
  >
>(
  this: OverlayService,
  logTypeId: TLogTypeId,
  props: InputProps<TComponent> &
    InputProps<AlcLogFormComponent<LogModalResultByTypeId[TLogTypeId]>>,
  options?: Omit<TOptions, 'componentProps'>
): TOptions extends { componentProps: { log: any } }
  ? AlcLogModalResultProvider<TLogTypeId, 'update'>
  : AlcLogModalResultProvider<TLogTypeId, 'create'> {
  const getLogModalResult = async () => {
    const componentLoader = getOrReport(
      logModalLoadersByLogTypeId,
      logTypeId,
      'Unknown log type'
    );

    const result = await this.getModalResult({
      size: 'md',
      cssClass: 'stacked',
      ...options,
      component: (await componentLoader()) as any,
      componentProps: props
    });

    return result;
  };

  const promise = getLogModalResult();

  backgroundTask('Showing log modal', () => promise);

  return {
    getResult: () => promise as any
  };
}
