import { IMedication } from '$shared/medications';
import { IFacilityUser } from '$shared/services/facility-user.schema';

export class PrnRecord {
  id?: string;
  medication: IMedication;
  reasonGiven: string;
  givenAt: string | Date;
  givenBy: IFacilityUser;
  followUpDateTime: string;
  response?: string;
  responseNotes?: string;
}
