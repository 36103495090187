import { createComponentLoader } from '$/lib/create-component-loader';

export const logModalLoadersByLogTypeId = {
  activity: createComponentLoader(
    'AlcActivityLogModal',
    () =>
      import(
        '../../../shared/pages/forms/log-forms/activity-log-modal/activity-log.modal'
      )
  ),
  behavior: createComponentLoader(
    'AlcBehaviorLogModal',
    () =>
      import(
        '../../../shared/pages/forms/log-forms/behavior-log-modal/behavior-log.modal'
      )
  ),
  bp: createComponentLoader(
    'AlcBloodPressureLogFormPage',
    () =>
      import(
        '../../../shared/pages/forms/log-forms/blood-pressure-log-form/blood-pressure-log-form.page'
      )
  ),
  bs: createComponentLoader(
    'AlcBloodSugarLogFormPage',
    () =>
      import(
        '../../../shared/pages/forms/log-forms/blood-sugar-log-form/blood-sugar-log-form.page'
      )
  ),
  bm: createComponentLoader(
    'AlcBmLogFormPage',
    () =>
      import(
        '../../../shared/pages/forms/log-forms/bm-log-form/bm-log-form.page'
      )
  ),
  bodyCheck: createComponentLoader(
    'AlcBodyCheckLogFormPage',
    () =>
      import(
        '../../../shared/pages/forms/log-forms/body-check-log-form/body-check-log-form.page'
      )
  ),
  food: createComponentLoader(
    'AlcFoodLogFormPage',
    () =>
      import('../../../shared/pages/forms/log-forms/food/food-log-form.page')
  ),
  mood: createComponentLoader(
    'AlcMoodLogFormPage',
    () =>
      import(
        '../../../shared/pages/forms/log-forms/mood-log-form/mood-log-form.page'
      )
  ),
  oxygen: createComponentLoader(
    'AlcOxygenLogFormPage',
    () =>
      import(
        '../../../shared/pages/forms/log-forms/oxygen-log-form/oxygen-log-form.page'
      )
  ),
  position: createComponentLoader(
    'AlcPositionLogFormPage',
    () =>
      import(
        '../../../shared/pages/forms/log-forms/position-log-form/position-log-form.page'
      )
  ),
  seizure: createComponentLoader(
    'AlcSeizureLogFormPage',
    () =>
      import(
        '../../../shared/pages/forms/log-forms/seizure-log-form/seizure-log-form.page'
      )
  ),
  shower: createComponentLoader(
    'AlcShowerLogFormPage',
    () =>
      import(
        '../../../shared/pages/forms/log-forms/shower-log-form/shower-log-form.page'
      )
  ),
  sleep: createComponentLoader(
    'AlcSleepLogFormPage',
    () =>
      import(
        '../../../shared/pages/forms/log-forms/sleep-log-form/sleep-log-forms.page'
      )
  ),
  temp: createComponentLoader(
    'AlcTemperatureLogFormPage',
    () =>
      import(
        '../../../shared/pages/forms/log-forms/temp-log-form/temp-log-form.page'
      )
  ),
  urine: createComponentLoader(
    'AlcUrineLogFormPage',
    () =>
      import(
        '../../../shared/pages/forms/log-forms/urine-log-form/urine-log-form.page'
      )
  ),
  water: createComponentLoader(
    'AlcWaterLogFormPage',
    () =>
      import(
        '../../../shared/pages/forms/log-forms/water-log-form/water-log-form.page'
      )
  ),
  weight: createComponentLoader(
    'AlcWeightLogFormPage',
    () =>
      import(
        '../../../shared/pages/forms/log-forms/weight-log-form/weight-log-form.page'
      )
  ),
  note: createComponentLoader(
    'AlcShiftNoteFormPage',
    () =>
      import(
        '../../../shared/pages/forms/log-forms/note-log-form/shift-note-form.page'
      )
  )
} as const;
