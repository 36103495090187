import { map } from 'lodash';

export enum NotificationSettingsEnum {
  MISSED_MEDICATION_ALERT = 'notification.medication.missedMedicationAlert',
  MISSED_PRN_RESPONSE_ALERT = 'notification.medication.missedPrnResponseAlert',
  OMITTED_MEDICATION_ALERT = 'notification.medication.omittedMedicationAlert',
  MISSED_CARE_TASK_ALERT = 'notification.care.missedCareTaskAlert',
  OMITTED_CARE_TASK_ALERT = 'notification.care.omittedCareTaskAlert',
  NO_BOWEL_MOVEMENT_ALERT = 'notification.notebook.noBowelMovementAlert',
  REFUSED_FOOD_ALERT = 'notification.notebook.refusedFoodAlert',
  REFUSED_WATER_ALERT = 'notification.notebook.refusedWaterAlert',
  MEDICATION_TASK_REMINDER = 'notification.medication.reminder',
  PRN_RESPONSE_REMINDER = 'notification.medication.prnReminder',
  CARE_TASK_REMINDER = 'notification.care.careTaskReminder'
}

export type NotificationSettingCodes =
  (typeof NotificationSettingsEnum)[keyof typeof NotificationSettingsEnum];

export enum GeneralSettingsEnum {
  FEATURE_FLAGS = 'general.featureFlags',
  LOCATION_GEOFENCE = 'general.location.geofence',
  LOCATION_TIMEZONE = 'general.location.timezone',
  MEDICATION_DAYS_NEW = 'general.medication.daysNew',
  MEDICATION_SHOW_BULK_BUTTONS = 'general.medication.showBulkButtons',
  MEDICATION_INVENTORY_COUNT = 'general.medication.inventoryCount',
  MEDICATION_PREP_TIME_RESTRICTIONS = 'general.medication.prepTimeRestrictions',
  MEDICATION_GIVEN_TIME_RESTRICTIONS = 'general.medication.givenTimeRestrictions',
  COMMUNICATION_INCIDENT_REPORT_SUBMISSION_EMAIL = 'general.communication.incidentReportSubmissionEmail',
  LOG_DAYS_SINCE_LAST_BOWEL_MOVEMENT = 'general.log.daysSinceLastBowelMovement',
  WEEKS_UNTIL_TO_DISPLAY_UPCOMING_DOCUMENTS = 'general.document.weeksUntilToDisplayUpcomingDocuments'
}

export type GeneralSettingCodes =
  (typeof GeneralSettingsEnum)[keyof typeof GeneralSettingsEnum];

export type SettingCodes = NotificationSettingCodes | GeneralSettingCodes;

export const TIMEZONES = [
  {
    value: 'America/Los_Angeles',
    name: 'Pacific',
    abbr: 'PT'
  },
  {
    value: 'America/Denver',
    name: 'Mountain',
    abbr: 'MT'
  },
  {
    value: 'America/Phoenix',
    name: 'Arizona/Phoenix',
    abbr: 'MST'
  },
  {
    value: 'America/Chicago',
    name: 'Central',
    abbr: 'CT'
  },
  {
    value: 'America/New_York',
    name: 'Eastern',
    abbr: 'ET'
  }
] as const;

export const TimezoneValues = map(TIMEZONES, 'value');
export type TimezoneValue = (typeof TimezoneValues)[number] | 'UTC';

export const DEFAULT_LOW_MEDICATION_DAYS = '7';
export const DEFAULT_LOW_PRN = '7';
