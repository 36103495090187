import { FeathersService } from '$/app/services/feathers.service';
import { MedicalProfessionalPhonesApiActions } from '$/app/store/medical-professional-phones/actions';
import { toUpdated } from '$/app/utils';
import { Paginated, Params, Update } from '$/models';
import { IMedicalProfessionalPhone } from '$shared/services/medical-professional-phone.schema';
import { Injectable } from '@angular/core';
import { Service } from '@feathersjs/feathers';
import { Store } from '@ngrx/store';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MedicalProfessionalPhonesApiService {
  private medicalProfessionalPhonesService: Service<IMedicalProfessionalPhone>;

  constructor(feathers: FeathersService, store: Store) {
    this.medicalProfessionalPhonesService = feathers.client.service(
      'medical-professional-phones'
    );

    this.medicalProfessionalPhonesService.on(
      'created',
      (medicalProfessionalPhone) => {
        store.dispatch(
          MedicalProfessionalPhonesApiActions.medicalProfessionalPhonesCreated({
            medicalProfessionalPhone
          })
        );
      }
    );

    this.medicalProfessionalPhonesService.on(
      'patched',
      (medicalProfessionalPhone) => {
        store.dispatch(
          MedicalProfessionalPhonesApiActions.medicalProfessionalPhonesPatched({
            medicalProfessionalPhone
          })
        );
      }
    );

    this.medicalProfessionalPhonesService.on(
      'removed',
      (medicalProfessionalPhone) => {
        store.dispatch(
          MedicalProfessionalPhonesApiActions.medicalProfessionalPhonesRemoved({
            id: medicalProfessionalPhone.id
          })
        );
      }
    );
  }

  getAll(
    params: Params = { query: {} }
  ): Observable<Paginated<IMedicalProfessionalPhone>> {
    const promise = this.medicalProfessionalPhonesService.find(
      params
    ) as Promise<Paginated<IMedicalProfessionalPhone>>;
    return from(promise);
  }

  get(
    id: string,
    params: Params = { query: {} }
  ): Observable<IMedicalProfessionalPhone> {
    const promise = this.medicalProfessionalPhonesService.get(id, params);
    return from(promise);
  }

  create(
    medicalProfessionalPhone:
      | IMedicalProfessionalPhone
      | IMedicalProfessionalPhone[]
  ) {
    const promise = this.medicalProfessionalPhonesService.create(
      medicalProfessionalPhone
    );
    return from(promise);
  }

  patch(
    id: string,
    medicalProfessionalPhone: Partial<IMedicalProfessionalPhone>
  ): Observable<Update<IMedicalProfessionalPhone>> {
    const promise = this.medicalProfessionalPhonesService.patch(
      id,
      medicalProfessionalPhone
    );

    return from(promise).pipe(map(toUpdated));
  }

  delete(
    id: string,
    params: Params = { query: {} }
  ): Observable<IMedicalProfessionalPhone> {
    const promise = this.medicalProfessionalPhonesService.remove(id, params);
    return from(promise);
  }
}
