import { map } from 'lodash';
import { Dictionary } from '../types';

/**
 * Receives an entity dictionary and converts it to an array
 * while also removing any fields listed in the fields to remove
 * argument
 */
export const normalizeToArray = (
  data: Dictionary<Record<string, any>>,
  fieldsToRemove: string[]
): Record<string, any>[] => {
  return map(data, (value) => {
    if (fieldsToRemove) {
      fieldsToRemove.forEach((field) => {
        delete value[field];
      });
    }

    return value;
  });
};
