import { Params, Update } from '$/models';
import { IActivityLog } from '$shared/services/activity-log.schema';
import { IActivityResident } from '$shared/services/activity-resident.schema';
import { MaybeArray } from '$shared/types';
import { createActionGroup, props } from '@ngrx/store';

export const ActivityLogModalActions = createActionGroup({
  source: 'Activity Log Modal',
  events: {
    'Create Activity Log': props<{
      activityLog: MaybeArray<Partial<IActivityLog>>;
      params?: Params;
    }>(),
    'Update Activity Log': props<Update<IActivityLog>>(),
    'Get Activity Logs': (params: Params) => ({ params }),
    'Get Residents': (params: Params) => ({ params }),
    'Create Activity Resident': props<{
      activityResidents: MaybeArray<Partial<IActivityResident>>;
      params?: Params;
    }>()
  }
});
