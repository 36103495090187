import { Paginated } from '$/models';
import { IActivity } from '$shared/services/activity.schema';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ActivitiesApiActions = createActionGroup({
  source: 'Activities Api',
  events: {
    'Load Activities Success': props<{
      activities: Paginated<IActivity>;
      metaData: { pageQueryType: string };
    }>(),
    'Get Activity Success': props<{
      activities: Paginated<IActivity>;
      metaData: { pageQueryType: string };
    }>(),
    'Create Activity Success': props<{
      activity: IActivity;
    }>(),
    'Update Activity Success': props<{
      activity: IActivity;
    }>(),
    'Delete Activity Success': props<{ id: string }>(),

    'Load Activity Fail': props<{ error: Error }>(),
    'Get Activity Fail': props<{ error: Error }>(),
    'Create Activity Fail': props<{ error: Error }>(),
    'Update Activity Fail': props<{ error: Error }>(),
    'Delete Activity Fail': props<{ error: Error }>()
  }
});

export const ActivitiesWsActions = createActionGroup({
  source: 'Activities WS',
  events: {
    'Activities Created': props<{
      activity: IActivity;
    }>(),
    'Activities Patched': props<{
      activity: IActivity;
    }>(),
    'Activities Removed': props<{ id: string }>()
  }
});

export const ActivitiesGeneralActions = createActionGroup({
  source: 'Activities General',
  events: {
    'Clear Activities': emptyProps(),
    'Add Activities': props<{
      activities: IActivity[];
    }>()
  }
});
