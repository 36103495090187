import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AbstractApiService } from '$/app/services/api/abstract-api-service.service';
import { FeathersService } from '$/app/services/feathers.service';
import { IProposedChange } from '$shared/services/proposed-changes.schema';
import { ProposedChangesWsActions } from './proposed-changes.actions';

@Injectable({
  providedIn: 'root'
})
export class ProposedChangesApiService extends AbstractApiService<IProposedChange> {
  constructor(feathers: FeathersService, store: Store) {
    super('proposed-changes', feathers, store, {
      entityName: 'proposedChange',
      created: ProposedChangesWsActions.proposedChangesCreated,
      patched: ProposedChangesWsActions.proposedChangesPatched,
      removed: ProposedChangesWsActions.proposedChangesRemoved
    });
  }
}
