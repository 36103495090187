import { Params } from '$/models';
import { props } from '@ngrx/store';

import { createActionGroup } from '@ngrx/store';

export const ActivitiesListPageActions = createActionGroup({
  source: 'Activities List Page',
  events: {
    'Load Activities': (params: Params = { query: {} }) => ({ params }),
    'Delete Activity': props<{ id: string; params?: Params }>()
  }
});
