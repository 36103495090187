import { AcknowledgementsPageActions } from '$/app/pages/communication/acknowledgements/acknowledgements.actions';
import { AnnouncementDetailPageActions } from '$/app/pages/communication/announcement-detail/announcement-detail.actions';
import * as AnnouncementsListPageActions from '$/app/pages/communication/announcements-list/announcements-list.actions';
import { EffectHelpersService } from '$/app/services';
import { AcknowledgementsApiService } from '$/app/services/api/acknowledgements.service';
import { UnreadAnnouncementPageActions } from '$/app/shared/pages/modals/unread-announcements/unread-announcement.actions';
import { NormalizedAction, createActionsFromNormalizedData } from '$/app/utils';
import { Acknowledgement } from '$/models';
import { acknowledgementSchema } from '$shared';
import { normalizeAndRemove } from '$shared/normalization/normalize-and-remove';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AnnouncementsGeneralActions } from '../announcements/announcements.actions';
import { FacilityUsersGeneralActions } from '../facility-users/actions';
import { AcknowledgementsApiActions } from './acknowledgements.actions';

@Injectable()
export class AcknowledgementsEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly acknowledgementsService = inject(AcknowledgementsApiService);
  private readonly effectHelpers = inject(EffectHelpersService);

  loadAcknowledgements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AcknowledgementsPageActions.loadAcknowledgements),
      switchMap((action) => {
        return this.acknowledgementsService.getAll(action.params).pipe(
          mergeMap((acknowledgements: Acknowledgement[]) => {
            const normalizedData = normalizeAndRemove(
              acknowledgements,
              [acknowledgementSchema],
              {
                acknowledgements: ['facilityUser', 'facilityUserGroup']
              }
            );

            return createActionsFromNormalizedData(normalizedData, {
              acknowledgements: new NormalizedAction(
                AcknowledgementsApiActions.loadAcknowledgementsSuccess,
                {
                  dispatchOnEmpty: true
                }
              ),
              announcements: AnnouncementsGeneralActions.addAnnouncements,
              facilityUsers: FacilityUsersGeneralActions.addFacilityUsers
            });
          }),
          catchError((error) => {
            return of(
              AcknowledgementsApiActions.loadAcknowledgementsFail({ error })
            );
          })
        );
      })
    );
  });

  updateAcknowledgement$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          AnnouncementDetailPageActions.acknowledgeAnnouncement,
          AnnouncementsListPageActions.acknowledgeAnnouncement,
          UnreadAnnouncementPageActions.acknowledgeAnnouncement
        ),
        switchMap((action) => {
          return this.acknowledgementsService
            .patch(action.id, action.changes, action.params)
            .pipe(
              catchError((error) => {
                this.store.dispatch(
                  AcknowledgementsApiActions.updateAcknowledgementFail({
                    error
                  })
                );
                return EMPTY;
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  updateAcknowledgementFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AcknowledgementsApiActions.updateAcknowledgementFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );
}
