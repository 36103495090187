import { Update } from '$/models';
import { IMedicalProfessionalPhone } from '$shared/services/medical-professional-phone.schema';
import { createAction, props } from '@ngrx/store';

//
// 💥💤 BACKEND API ACTIONS
//

// [API] Create Success/Fail
export const createMedicalProfessionalPhoneSuccess = createAction(
  '[Medical Professional Phones API] Create Medical Professional Phone Success',
  props<{
    medicalProfessionalPhone:
      | IMedicalProfessionalPhone
      | IMedicalProfessionalPhone[];
  }>()
);
export const createMedicalProfessionalPhoneFail = createAction(
  '[Medical Professional Phones API] Create Medical Professional Phone Fail',
  props<{ error: Error }>()
);

// [API] Update Success/Fail
export const updateMedicalProfessionalPhoneSuccess = createAction(
  '[Medical Professional Phones API] Update Medical Professional Phone Success',
  props<{ medicalProfessionalPhone: Update<IMedicalProfessionalPhone> }>()
);
export const updateMedicalProfessionalPhoneFail = createAction(
  '[Medical Professional Phones API] Update Medical Professional Phone Fail',
  props<{ error: Error }>()
);

// [API] Delete Success/Fail
export const deleteMedicalProfessionalPhoneSuccess = createAction(
  '[Medical Professional Phones API] Delete Medical Professional Phone Success',
  props<{ id: string }>()
);
export const deleteMedicalProfessionalPhoneFail = createAction(
  '[Medical Professional Phones API] Delete Medical Professional Phone Fail',
  props<{ error: Error }>()
);

// [WS] Medical Professional Phones Created
export const medicalProfessionalPhonesCreated = createAction(
  '[Medical Professional Phones WS] Medical Professional Phones Created',
  props<{ medicalProfessionalPhone: IMedicalProfessionalPhone }>()
);
// [WS] Medical Professional Phones Patched
export const medicalProfessionalPhonesPatched = createAction(
  '[Medical Professional Phones WS] Medical Professional Phones Patched',
  props<{ medicalProfessionalPhone: IMedicalProfessionalPhone }>()
);
// [WS] Medical Professional Phones Removed
export const medicalProfessionalPhonesRemoved = createAction(
  '[Medical Professional Phones WS] Medical Professional Phones Removed',
  props<{ id: string }>()
);
