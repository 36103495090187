import { EffectHelpersService } from '$/app/services';
import { ApiData } from '$/app/utils';
import {
  getAllPages,
  reducePaginatedResponses
} from '$/app/utils/pagination/rxjs-operators';
import { IActivity } from '$shared/services/activity.schema';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';

import { ActivitiesApiActions } from './activities.actions';
import { ActivitiesApiService } from './activities.service';
import { ActivitiesListPageActions } from '$/app/pages/facilities/facility-detail/activities-list/activities-list.actions';
import { ActivitiesFormModalActions } from '$/app/shared/pages/forms/activities-form/activities-form.actions';

@Injectable()
export class ActivitiesEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly activitiesService = inject(ActivitiesApiService);

  loadActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivitiesListPageActions.loadActivities),
      this.effectHelpers.apiRequest({
        description: 'loading activities',
        onRequest: (action) =>
          this.activitiesService
            .getAll(action.params)
            .pipe(
              getAllPages(this.activitiesService, action.params.query),
              reducePaginatedResponses()
            ),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'activities',
            response,
            ActivitiesApiActions.loadActivitiesSuccess
          );
          return responseData.getActions();
        }
      })
    )
  );

  createActivities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivitiesFormModalActions.createActivity),
      exhaustMap((action) => {
        return this.activitiesService.create(action.activity).pipe(
          tap((data: IActivity) => {
            this.effectHelpers.dismissModal({
              data
            });
          }),
          map((activity: IActivity) =>
            ActivitiesApiActions.createActivitySuccess({
              activity
            })
          ),
          catchError((error) =>
            of(ActivitiesApiActions.createActivityFail({ error }))
          )
        );
      })
    );
  });

  updateActivityLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivitiesFormModalActions.updateActivity),
      switchMap((action) => {
        return this.activitiesService.patch(action.id, action.changes).pipe(
          tap((data: IActivity) => {
            this.effectHelpers.dismissModal({
              data
            });
          }),
          map((activity: IActivity) => {
            return ActivitiesApiActions.updateActivitySuccess({
              activity
            });
          }),
          catchError((error) => {
            return of(
              ActivitiesApiActions.updateActivityFail({
                error
              })
            );
          })
        );
      })
    );
  });

  deleteActivities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivitiesListPageActions.deleteActivity),
      this.effectHelpers.apiRequest({
        description: 'delete Activity',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.activitiesService.delete(action.id, action.params);
        },
        onSuccess: (activity) =>
          ActivitiesApiActions.deleteActivitySuccess({
            id: activity.id
          }),
        onError: (error) => ActivitiesApiActions.deleteActivityFail({ error })
      })
    );
    // });
  });
}
