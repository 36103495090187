import { IActivityResident } from '$shared/services/activity-resident.schema';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ActivityResidentsApiActions = createActionGroup({
  source: 'Activity Residents Api',
  events: {
    'Load Activity Residents Success': props<{
      activityResidents: IActivityResident[];
    }>(),
    'Get Activity Residents Success': props<{
      activityResidents: IActivityResident[];
    }>(),
    'Create Activity Resident Success': props<{
      activityResident: IActivityResident;
    }>(),
    'Update Activity Resident Success': props<{
      activityResident: IActivityResident;
    }>(),
    'Delete Activity Resident Success': props<{ id: string }>(),

    'Load Activity Residents Fail': props<{ error: Error }>(),
    'Get Activity Residents Fail': props<{ error: Error }>(),
    'Create Activity Resident Fail': props<{ error: Error }>(),
    'Update Activity Resident Fail': props<{ error: Error }>(),
    'Delete Activity Resident Fail': props<{ error: Error }>()
  }
});

export const ActivityResidentsWsActions = createActionGroup({
  source: 'Activity Residents Ws',
  events: {
    'Activity Resident Created': props<{
      activityResident: IActivityResident;
    }>(),
    'Activity Resident Patched': props<{
      activityResident: IActivityResident;
    }>(),
    'Activity Resident Removed': props<{ id: string }>()
  }
});

export const ActivityResidentsGeneralActions = createActionGroup({
  source: 'Activity Residents General',
  events: {
    'Clear Activity Residents': emptyProps(),
    'Add Activity Residents': props<{
      activityResidents: IActivityResident[];
    }>()
  }
});
