import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ProposedChangesEffects } from './proposed-changes.effects';
import { proposedChangesFeature } from './proposed-changes.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(proposedChangesFeature),
    EffectsModule.forFeature([ProposedChangesEffects])
  ],
  providers: [ProposedChangesEffects]
})
export class ProposedChangesStoreModule {}
