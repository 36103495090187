import { ActivityLogsPageActions } from '$/app/pages/notebook/shared/logs/activity-logs/activity-log.actions';
import { EffectHelpersService } from '$/app/services';
import { ActivityLogModalActions } from '$/app/shared/pages/forms/log-forms/activity-log-modal/activity-log.modal.actions';
import { ApiData } from '$/app/utils';
import {
  getAllPages,
  reducePaginatedResponses
} from '$/app/utils/pagination/rxjs-operators';
import { IActivityLog } from '$shared/services/activity-log.schema';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ActivityLogsApiActions } from './activity-logs.actions';
import { ActivityLogsApiService } from './activity-logs.service';

@Injectable()
export class ActivityLogsEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly activityLogsService = inject(ActivityLogsApiService);

  getActivityLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ActivityLogsPageActions.getActivityLogs,
        ActivityLogModalActions.getActivityLogs
      ),
      this.effectHelpers.apiRequest({
        description: 'getting activity logs',
        onRequest: (action) =>
          // TODO: This could be simplified by having getAllPages
          // be a utility function, rather than an operator.
          this.activityLogsService
            .getAll(action.params)
            .pipe(
              getAllPages(this.activityLogsService, action.params.query),
              reducePaginatedResponses()
            ),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'activityLogs',
            response,
            ActivityLogsApiActions.getActivityLogsSuccess
          );

          return responseData.getActions();
        },
        onError: (error) =>
          ActivityLogsApiActions.getActivityLogsFail({ error })
      })
    )
  );

  createActivityLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivityLogModalActions.createActivityLog),
      this.effectHelpers.apiRequest({
        description: 'Create activity log',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.activityLogsService.create(
            action.activityLog,
            action.params
          );
        },
        onSuccess: (activityLog) =>
          ActivityLogsApiActions.createActivityLogSuccess({
            activityLog
          }),
        onError: (error) =>
          ActivityLogsApiActions.createActivityLogFail({ error })
      })
    );
  });

  updateActivityLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivityLogModalActions.updateActivityLog),
      switchMap((action) => {
        return this.activityLogsService
          .patch(action.id, action.changes, action.params)
          .pipe(
            tap((data: IActivityLog) => {
              this.effectHelpers.dismissModal({
                data
              });
            }),
            map((activityLog: IActivityLog) => {
              return ActivityLogsApiActions.updateActivityLogSuccess({
                activityLog
              });
            }),
            catchError((error) => {
              return of(
                ActivityLogsApiActions.updateActivityLogFail({
                  error
                })
              );
            })
          );
      })
    );
  });

  deleteActivityLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivityLogsPageActions.deleteActivityLog),
      this.effectHelpers.apiRequest({
        description: 'Delete activity log',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.activityLogsService.delete(action.id, action.params);
        },
        onSuccess: (activityLog: IActivityLog) =>
          ActivityLogsApiActions.deleteActivityLogSuccess({
            id: activityLog.id
          }),
        onError: (error) =>
          ActivityLogsApiActions.deleteActivityLogFail({ error })
      })
    );
  });
}
