import { TLookup } from '$shared/types/lookup.type';
import { chainFlow } from '$shared/utils';
import { sort } from 'fast-sort';
import {
  entries,
  filter,
  isEmpty,
  isEqual,
  isNil,
  isObjectLike,
  map,
  sortBy,
  uniq
} from 'lodash';
import { stringify } from 'safe-stable-stringify';
import { TFilterListItem, TPageFilter } from './page-filter.types';

export type FilterSources<T extends Record<string, any> = Record<string, any>> =
  {
    fromStore?: T;
    fromDefaults?: T;
    fromQuery?: T;
  };

export function getPageFilter<
  T extends Record<string, any> = Record<string, any>
>(filterSources: FilterSources<T>): TPageFilter<T> {
  const {
    fromDefaults = {} as T,
    fromQuery = {} as T,
    fromStore = {} as T
  } = filterSources;

  const keys = Object.keys({
    ...fromDefaults,
    ...fromQuery,
    ...fromStore
  });

  const filters = keys.reduce((acc, key) => {
    let defaultFilterValue = fromDefaults?.[key];
    let queryFilterValue = fromQuery?.[key];
    let storeFilterValue = fromStore?.[key];

    switch (true) {
      case Array.isArray(defaultFilterValue) ||
        Array.isArray(storeFilterValue) ||
        Array.isArray(queryFilterValue):
        defaultFilterValue = uniq(defaultFilterValue).filter(Boolean);
        queryFilterValue = uniq(queryFilterValue).filter(Boolean);
        storeFilterValue = uniq(storeFilterValue).filter(Boolean);

        acc[key] = [
          isEmpty(queryFilterValue) ? undefined : queryFilterValue,
          isEmpty(storeFilterValue) ? undefined : storeFilterValue,
          isEmpty(defaultFilterValue) ? undefined : defaultFilterValue
        ].filter(Boolean)[0];

        break;
      case typeof defaultFilterValue === 'object' &&
        !Array.isArray(defaultFilterValue):
        acc[key] = {
          ...defaultFilterValue,
          ...storeFilterValue,
          ...queryFilterValue
        };

        break;
      default:
        acc[key] = queryFilterValue ?? storeFilterValue ?? defaultFilterValue;
        break;
    }

    return acc;
  }, {}) as T;

  // const filters = {
  //   ...fromDefaults,
  //   ...fromStore,
  //   ...fromQuery
  // };

  const defaults = !isEmpty(fromDefaults) ? fromDefaults : undefined;

  return {
    modifiedCount: getModifiedFilterCount({ filters, defaults }),
    filters,
    defaults
  };
}

export function getModifiedFilterCount<
  T extends Record<string, any> = Record<string, any>
>({ filters, defaults }: { filters: T; defaults?: T }): number {
  if (!defaults) {
    return 0;
  }

  const count = entries(filters).reduce((acc: number, [key, value]) => {
    switch (true) {
      // checklist filters can have their items out of order from what is in
      // defaults so we need to sort them before comparing
      case Array.isArray(value) &&
        ((!isNil(value[0]) && !isObjectLike(value[0])) ||
          (!isNil(defaults[key][0]) && !isObjectLike(defaults[key][0]))):
        !isEqual(sortBy(value), sortBy(defaults[key])) && acc++;
        break;
      // filters that are objects could have their keys in a different order so
      // we need to stringify them safely before comparing
      case typeof value === 'object' && !Array.isArray(value):
        !isEqual(stringify(value), stringify(defaults[key])) && acc++;

        break;
      // all other filter types will most likely be primitives so we can compare
      // them directly
      case typeof value !== 'object':
        !isEqual(value, defaults[key]) && acc++;
        break;
    }

    return acc;
  }, 0);

  return count;
}

export function convertLookupToFilterList<T extends TLookup>(
  lookupList: T[]
): TFilterListItem[] {
  return chainFlow(
    filter(lookupList, (lookup: T) =>
      lookup.filterOptions ? lookup.filterOptions?.isVisible : true
    ),
    (lookups) =>
      sort(lookups).asc([
        (lookup) => lookup.filterOptions?.position,
        (lookup) => lookup.filterOptions?.label ?? lookup.name
      ]),
    (sortedLookups) =>
      map(
        sortedLookups,
        (lookup): TFilterListItem => ({
          id: lookup.id,
          label: lookup.filterOptions?.label ?? lookup.name,
          description: lookup.description
        })
      )
  );
}
