import { createFeature, createReducer, on } from '@ngrx/store';
import {
  ActivityResidentsApiActions,
  ActivityResidentsGeneralActions,
  ActivityResidentsWsActions
} from './activity-residents.actions';
import {
  activityResidentsAdapter,
  initialState
} from './activity-residents.state';
import { getRecords } from '$shared/utils';

export const activityResidentsFeature = createFeature({
  name: 'activityResidents',
  reducer: createReducer(
    initialState,
    // on((state) => {
    //   return { ...state, loading: true, error: null };
    // }),

    on(
      ActivityResidentsApiActions.getActivityResidentsSuccess,
      (state, action) => {
        const data = getRecords(action.activityResidents);

        return activityResidentsAdapter.upsertMany(data, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(ActivityResidentsApiActions.createActivityResidentSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(
      ActivityResidentsWsActions.activityResidentCreated,
      ActivityResidentsWsActions.activityResidentPatched,
      (state, action) => {
        return activityResidentsAdapter.upsertOne(
          action.activityResident,
          state
        );
      }
    ),
    on(ActivityResidentsWsActions.activityResidentRemoved, (state, action) =>
      activityResidentsAdapter.removeOne(action.id, state)
    ),
    on(
      ActivityResidentsGeneralActions.addActivityResidents,
      (state, action) => {
        return activityResidentsAdapter.upsertMany(action.activityResidents, {
          ...state,
          loading: false,
          error: null
        });
      }
    ),
    on(ActivityResidentsGeneralActions.clearActivityResidents, () => {
      return activityResidentsAdapter.removeAll({
        ...initialState
      });
    }),

    on(
      ActivityResidentsApiActions.createActivityResidentFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    )
  )
});
