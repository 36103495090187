import { IMedicalProfessional } from '$shared/services/medical-professional.schema';
import { MaybeArray } from '$shared/types/utility-types';
import { createAction, props } from '@ngrx/store';

export const loadMedicalProfessionalsSuccess = createAction(
  '[Medical Professionals API] Load Medical Professionals Success',
  props<{ medicalProfessionals: IMedicalProfessional[] }>()
);
export const loadMedicalProfessionalsFail = createAction(
  '[Medical Professionals API] Load Medical Professionals Fail',
  props<{ error: Error }>()
);

export const getMedicalProfessionalsSuccess = createAction(
  'Medical Professionals API] Get Medical Professionals Success',
  props<{ medicalProfessionals: IMedicalProfessional[] }>()
);
export const getMedicalProfessionalsFail = createAction(
  'Medical Professionals API] Get Medical Professionals Fail',
  props<{ error: Error }>()
);

export const fetchMedicalProfessionalSuccess = createAction(
  '[Medical Professionals API] Fetch Medical Professional Success',
  props<{ medicalProfessional: IMedicalProfessional }>()
);
export const fetchMedicalProfessionalFail = createAction(
  '[Medical Professionals API] Fetch Medical Professional Fail',
  props<{ error: Error }>()
);

export const createMedicalProfessionalSuccess = createAction(
  '[Medical Professionals API] Add Medical Professional Success',
  props<{ medicalProfessional: MaybeArray<IMedicalProfessional> }>()
);
export const createMedicalProfessionalFail = createAction(
  '[Medical Professionals API] Add Medical Professional Fail',
  props<{ error: Error }>()
);

export const updateMedicalProfessionalSuccess = createAction(
  '[Medical Professionals API] Update Medical Professional Success',
  props<{ medicalProfessional: IMedicalProfessional }>()
);
export const updateMedicalProfessionalFail = createAction(
  '[Medical Professionals API] Update IMedicalProfessional Fail',
  props<{ error: Error }>()
);

export const deleteMedicalProfessionalSuccess = createAction(
  '[Medical Professionals API] Delete Medical Professional Success',
  props<{ id: string }>()
);
export const deleteMedicalProfessionalFail = createAction(
  '[Medical Professionals API] Delete IMedicalProfessional Fail',
  props<{ error: Error }>()
);

// [WS] Medical Professionals Created
export const medicalProfessionalsCreated = createAction(
  '[Medical Professionals API WS] Medical Professionals Created',
  props<{ medicalProfessional: IMedicalProfessional }>()
);
// [WS] Medical Professionals Patched
export const medicalProfessionalsPatched = createAction(
  '[Medical Professionals API WS] Medical Professionals Patched',
  props<{ medicalProfessional: IMedicalProfessional }>()
);
// [WS] Medical Professionals Removed
export const medicalProfessionalsRemoved = createAction(
  '[Medical Professionals API WS] Medical Professionals Removed',
  props<{ id: string }>()
);
