import { ErrorAlertModal } from '$/app/services/ui/overlays/error-alert/error-alert.modal';
import { Logger } from '$shared/logger';
import { OverlayService } from './index';

let errorMessageShowing = false;

export function showErrorMessage(
  this: OverlayService,
  error: any,
  options: { title?: string; message?: string } = {}
) {
  if (errorMessageShowing) {
    return;
  }

  Logger.error('Error:', error);

  this._showPopover({
    component: ErrorAlertModal,
    componentProps: {
      title: options.title,
      message: options.message,
      error
    },

    cssClass: 'alert-popover'
  })
    .then((popover) => popover.onDidDismiss())
    .catch((error) => {
      Logger.error('Error showing error alert', error);
    })
    .finally(() => (errorMessageShowing = false));
}
