import { dateRangeToQuery } from '$/app/utils';
import { createActionGroup } from '@ngrx/store';
import { MedicationReceivingFilters } from '../medication-receiving.vmodel';

export const MedicationReceivingFilterComponentActions = createActionGroup({
  source: 'Medication Receiving Filter Component',
  events: {
    'Filter Changed': (
      filters: MedicationReceivingFilters
    ): {
      filters: MedicationReceivingFilters;
      queryParams: Record<string, any>;
    } => {
      return {
        filters,
        queryParams: {
          statuses: filters.statuses,
          ...dateRangeToQuery(filters.dateRange)
        }
      };
    }
  }
});
