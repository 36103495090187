import { GeofenceSettingsComponentActions } from '$/app/pages/facilities/facility-detail/facility-settings/general-settings/components/geofence-settings/geofence-settings.actions';
import { GeneralSettingsPageActions } from '$/app/pages/facilities/facility-detail/facility-settings/general-settings/general-settings.actions';
import { NotificationSettingDetailPageActions } from '$/app/pages/facilities/facility-detail/facility-settings/notification-setting-detail/notification-setting-detail.actions';
import { NotificationSettingsPageActions } from '$/app/pages/facilities/facility-detail/facility-settings/notification-settings/notification-settings.actions';
import {
  EffectHelpersService,
  FacilitySettingsApiService
} from '$/app/services';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { FacilitySettingsApiActions } from './actions';

@Injectable()
export class FacilitySettingsEffects {
  private readonly actions$ = inject(Actions);
  private readonly facilitySettingsService = inject(FacilitySettingsApiService);
  private readonly effectHelpers = inject(EffectHelpersService);

  updateFacilitySetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        GeneralSettingsPageActions.updateFacilitySetting,
        GeofenceSettingsComponentActions.updateGeofenceSetting,
        NotificationSettingsPageActions.updateFacilitySetting,
        NotificationSettingDetailPageActions.updateFacilitySetting
      ),
      this.effectHelpers.apiRequest({
        description: 'updating facility setting',
        onRequest: (action) =>
          this.facilitySettingsService.patch(
            action.id,
            action.changes,
            action.params
          ),
        onError: (error) =>
          FacilitySettingsApiActions.updateFacilitySettingFail({ error })
      })
    )
  );

  updateFacilitySettingFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FacilitySettingsApiActions.updateFacilitySettingFail),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );
}
