import { sortByDesc } from '$/app/utils/sorting/sort-by-desc';
import { IPaginationInfo } from '$/models';
import { IActivityLog } from '$shared/services/activity-log.schema';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export interface ActivityLogPageQueries {
  all: IPaginationInfo;
}

export const activityLogsAdapter = createEntityAdapter<IActivityLog>({
  sortComparer: sortByDesc('recordAt')
});

export interface State extends EntityState<IActivityLog> {
  pagination: ActivityLogPageQueries;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = activityLogsAdapter.getInitialState({
  pagination: {
    all: {
      total: 0,
      skip: 0
    }
  },
  loading: false,
  loaded: false,
  error: null
});
