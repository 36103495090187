import { IMedication } from '$shared/medications';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './medication-orders-queue.state';

const selectMedicationOrdersQueueState = createFeatureSelector<State>(
  'medicationOrdersQueue'
);

export const MedicationOrderQueueSelectors = {
  selectMedicationOrdersQueue: createSelector(
    selectMedicationOrdersQueueState,
    (state: State): IMedication[] => {
      return state.ordersQueue;
    }
  ),

  selectMedicationOrdersQueueCount: createSelector(
    selectMedicationOrdersQueueState,
    (state: State): number => state.ordersQueue.length
  )
};
