import { getRecordChanges } from '$shared';
import { Component, computed, input } from '@angular/core';
import { AlcCommonModule } from '../../alc-common.module';
import { AlcInfoBlockComponent } from '../info-block/info-block.component';

type Diff = {
  field: string;
  fieldLabel: string;
  tooltip: string | undefined;
  original: any;
  new: any;
};

export type DiffFieldConfig = {
  id: string;
  label?: string;
  tooltip?: string;
  formatter?: (value: any) => string;
};

/**
 * This component shows the differences between two objects. This is useful for
 * showing a summary of changes that are taking place in a form.
 */
@Component({
  selector: 'alc-diff',
  standalone: true,
  imports: [AlcCommonModule, AlcInfoBlockComponent],
  template: `
    <div class="flex flex-col gap-6">
      @for (diff of diffs(); track diff.field) {
        <alc-info-block
          class="basis-full"
          [label]="diff.fieldLabel"
          [tooltip]="diff.tooltip"
        >
          <div class="flex items-center gap-6">
            <div>{{ diff.original }}</div>

            <div class="flex w-8 flex-none justify-center">
              <ion-icon
                class="shrink-0"
                src="assets/icon/material/arrow_forward.svg"
              />
            </div>

            <div>{{ diff.new }}</div>
          </div>
        </alc-info-block>
      }
    </div>
  `
})
export class AlcDiffComponent {
  original = input<any>();
  new = input<any>();
  fields = input<DiffFieldConfig[]>();

  diffs = computed((): Diff[] => {
    const diff = [];

    let changes;

    if (this.original() && this.new()) {
      changes = getRecordChanges(this.original(), this.new());
    } else {
      changes = this.new();
    }

    for (const fieldConfig of this.fields()) {
      const { id: field, label: fieldLabel, tooltip } = fieldConfig;

      const formatter = fieldConfig.formatter ?? ((value) => value);

      if (changes[field] === undefined) {
        continue;
      }

      diff.push({
        field,
        fieldLabel,
        tooltip,
        original: formatter(this.original()?.[field]) || 'N/A',
        new: formatter(changes[field])
      });
    }

    return diff;
  });
}
