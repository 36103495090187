/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Type } from '@sinclair/typebox';
import { castArray, isRegExp, isString, omit } from 'lodash';
import { AlcomyStringOptions } from '../#types';
import { Logger } from '../../logger';
import { REGEX } from '../../regex';
import { OPAQUE_TOKEN_REGEX } from '../../security/parse-opaque-token';
import { MaybeArray } from '../../types';

export function PatternString(
  pattern: RegExp | string,
  options?: AlcomyStringOptions
) {
  if (isString(pattern)) {
    pattern = new RegExp(pattern);
  }

  if (pattern.flags !== '') {
    throw new Error('RegExp flags are not supported');
  }

  return Type.String({
    ...options,
    pattern: isRegExp(pattern) ? pattern.source : pattern
  });
}

export function EmptyString(options?: AlcomyStringOptions) {
  return PatternString(/^$/, options);
}

export function Url(
  options?: AlcomyStringOptions & {
    flavor?: MaybeArray<'web' | 'data'>;
  }
) {
  const flavors = castArray(options?.flavor ?? ['web', 'data']);

  Logger.assert(flavors.length, 'No url flavor specified', { options });

  const web = flavors.includes('web');
  const data = flavors.includes('data');
  options = omit(options, 'flavor');

  if (web && data) {
    return Type.Union([
      PatternString(REGEX.WEB_URL, options),
      PatternString(REGEX.DATA_URL, options)
    ]);
  }

  return web
    ? PatternString(REGEX.WEB_URL, options)
    : PatternString(REGEX.DATA_URL, options);
}

export function Base64String(options?: AlcomyStringOptions) {
  return PatternString(REGEX.BASE64_STRING, options);
}

export function DateTimeString(options?: AlcomyStringOptions) {
  return PatternString(REGEX.ISO_TIMESTAMP, options);
}

export function Id(options?: AlcomyStringOptions) {
  return PatternString(REGEX.UUID4, options);
}

export function IntegerString(options?: AlcomyStringOptions) {
  return PatternString(/^[0-9]+$/, options);
}

export function NumberString(options?: AlcomyStringOptions) {
  return PatternString(/^[0-9]+(\.[0-9]+)?$/, options);
}

export function PhoneNumber(options?: AlcomyStringOptions) {
  return PatternString(REGEX.PHONE_NUMBER, options);
}

export function OpaqueToken(options?: AlcomyStringOptions) {
  return PatternString(OPAQUE_TOKEN_REGEX, options);
}
