import { GivePrnFormActions } from '$/app/pages/medications/dashboard/give-prn-form/give-prn-form.actions';
import { PrnResponseFormActions } from '$/app/pages/medications/dashboard/prn-response-form/prn-response-form.actions';
import { EditFacilityUserAddressFormActions } from '$/app/pages/staff/edit-facility-user-address-form/edit-facility-user-address-form.actions';
import { EditFacilityUserFormActions } from '$/app/pages/staff/edit-facility-user-basic-form/edit-facility-user-basic-form.actions';
import { FacilityUserProfilePageActions } from '$/app/pages/staff/facility-user-details/facility-user-profile/facility-user-profile.actions';
import { FacilityUserListPageActions } from '$/app/pages/staff/facility-user-list/facility-user-list.actions';
import { NewFacilityUserModalActions } from '$/app/pages/staff/new-facility-user-modal/new-facility-user.modal.actions';
import { FacilityUsersApiService } from '$/app/services/api/facility-users.service';
import { EffectHelpersService } from '$/app/services/utils/effect-helpers.service';
import { FacilityUserSelectComponentActions } from '$/app/shared/form-controls/facility-user-select/facility-user-select.actions';
import { ApiData } from '$/app/utils';
import { IFacilityUser } from '$shared/services/facility-user.schema';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  Observable,
  catchError,
  map,
  mergeMap,
  of,
  switchMap,
  tap
} from 'rxjs';
import { FacilityUsersApiActions } from './actions';

@Injectable()
export class FacilityUsersEffects {
  private readonly actions$ = inject(Actions);
  private readonly facilityUsersService = inject(FacilityUsersApiService);
  private readonly effectHelpers = inject(EffectHelpersService);

  loadFacilityUsers$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FacilityUserListPageActions.loadFacilityUsers,
        GivePrnFormActions.loadFacilityUsers,
        PrnResponseFormActions.loadFacilityUsers,
        FacilityUserSelectComponentActions.loadFacilityUsers
      ),
      switchMap((action) => {
        return this.facilityUsersService.getAll(action.params).pipe(
          mergeMap((facilityUsers: IFacilityUser[]) => {
            const responseData = new ApiData(
              'facilityUsers',
              facilityUsers,
              FacilityUsersApiActions.loadFacilityUsersSuccess
            );

            return responseData.getActions();
          }),
          catchError((err) => {
            return of(
              FacilityUsersApiActions.loadFacilityUsersFail({ error: err })
            );
          })
        );
      })
    );
  });

  fetchFacilityUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FacilityUserProfilePageActions.fetchFacilityUser),
      switchMap((action) => {
        return this.facilityUsersService.get(action.id, action.params).pipe(
          mergeMap((facilityUser) => {
            const responseData = new ApiData('facilityUsers', facilityUser);
            responseData.setPrimaryAction(
              FacilityUsersApiActions.fetchFacilityUserSuccess,
              { payloadKey: 'facilityUser' }
            );

            return responseData.getActions();
          }),
          catchError((error) => {
            return of(FacilityUsersApiActions.fetchFacilityUserFail({ error }));
          })
        );
      })
    );
  });

  createFacilityUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewFacilityUserModalActions.createFacilityUser),
      this.effectHelpers.apiRequest({
        description: 'Creating facility user',
        useMapOperator: 'exhaustMap',
        onRequest: (action) =>
          this.facilityUsersService.create(action.facilityUser, {
            ...action.params,
            query: {
              ...action.params?.query,
              $actions: [
                {
                  updateRoles: { roleIds: action.roleIds },
                  createPhones: { phones: action.phones },
                  sendInviteEmail: true
                }
              ]
            }
          }),
        onSuccess: (facilityUser) =>
          FacilityUsersApiActions.createFacilityUserSuccess({ facilityUser }),
        onError: (error) =>
          FacilityUsersApiActions.createFacilityUserFail({ error })
      })
    );
  });

  updateFacilityUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        EditFacilityUserFormActions.updateFacilityUser,
        EditFacilityUserAddressFormActions.updateFacilityUser,
        FacilityUserProfilePageActions.updateFacilityUser
      ),
      switchMap((action) => {
        return this.facilityUsersService
          .patch(action.id, action.changes, action.params)
          .pipe(
            map((facilityUser) => {
              return FacilityUsersApiActions.updateFacilityUserSuccess({
                facilityUser
              });
            }),
            tap(
              this.effectHelpers.onModalFormSubmitSuccess(
                'Facility user updated successfully.'
              )
            ),
            catchError((error) => {
              return of(
                FacilityUsersApiActions.updateFacilityUserFail({ error })
              );
            })
          );
      })
    );
  });

  updateFacilityUserFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FacilityUsersApiActions.updateFacilityUserFail),
        tap(this.effectHelpers.onFormSubmitFail('Update Facility User Failed'))
      );
    },
    { dispatch: false }
  );

  resendUserInvite$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FacilityUserListPageActions.resendUserInvite),
        this.effectHelpers.apiRequest({
          description: 'resending user invite',
          useMapOperator: 'exhaustMap',
          onRequest: (action) =>
            this.facilityUsersService.patch(
              action.id,
              {},
              { query: { $actions: [{ sendInviteEmail: true }] } }
            )
        })
      ),
    { dispatch: false }
  );

  updateFacilityUserRoles$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FacilityUserProfilePageActions.updateFacilityUserRoles),
        this.effectHelpers.apiRequest({
          description: 'updating facility user roles',
          useMapOperator: 'exhaustMap',
          onRequest: (action) =>
            this.facilityUsersService.patch(
              action.id,
              {},
              {
                query: {
                  $actions: [{ updateRoles: { roleIds: action.roleIds } }]
                }
              }
            ),
          onSuccess: (facilityUser) =>
            FacilityUsersApiActions.updateFacilityUserSuccess({ facilityUser }),
          onError: (error) =>
            FacilityUsersApiActions.updateFacilityUserFail({ error })
        })
      );
    },
    { dispatch: false }
  );
}
