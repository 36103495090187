import { IDocument, Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const DocumentsOverviewPageActions = createActionGroup({
  source: 'Documents Overview Page',
  events: {
    'Get Documents': (params: Params) => ({ params }),
    'Create Document': props<{ document: IDocument; params?: Params }>()
  }
});
