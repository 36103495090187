import { Table } from '$/models/data/base';
import { CountryId } from '$shared/lookups/countries.lookup';
import { PharmacySoftwareVendorId } from '$shared/lookups/pharmacy-software-vendors.lookup';
import { StateId } from '$shared/lookups/states.lookup';

export class Pharmacy extends Table {
  orgId?: string;
  facilityId?: string;

  name: string;
  isPrimary?: boolean;
  address1?: string;
  address2?: string;
  city?: string;
  state?: StateId;
  postalCode?: string;
  country?: CountryId;
  softwareVendorId?: PharmacySoftwareVendorId;
  storeId?: string;
  email?: string;
  orderingMethod?: string;
}
