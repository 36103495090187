import { IMedicalProfessional } from '$shared/services/medical-professional.schema';
import { MaybeArray } from '$shared/types/utility-types';
import { createAction, props } from '@ngrx/store';

export const createMedicalProfessional_MedicalProfessionalForm = createAction(
  '[MedicalProfessional Form] Add MedicalProfessional',
  props<{ medicalProfessional: MaybeArray<IMedicalProfessional> }>()
);

export const createMedicalProfessional_ResidentMedicalProfessionalsRoute =
  createAction(
    '[Facesheet Route Effect] Add MedicalProfessionals',
    props<{
      medicalProfessional: MaybeArray<IMedicalProfessional>;
    }>()
  );
