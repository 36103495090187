import { Injectable, inject } from '@angular/core';
import { EffectHelpersService } from '$/app/services';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { ActivityResidentsApiService } from './activity-residents.service';
import { ActivityLogModalActions } from '$/app/shared/pages/forms/log-forms/activity-log-modal/activity-log.modal.actions';
import { ActivityResidentsApiActions } from './activity-residents.actions';
import { LogsPageActions } from '$/app/pages/notebook/residents/logs-overview/logs-overview.actions';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';

@Injectable()
export class ActivityResidentEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly activityResidentsService = inject(
    ActivityResidentsApiService
  );

  createActivityResident$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivityLogModalActions.createActivityResident),
      this.effectHelpers.apiRequest({
        description: 'creating activity resident',
        onRequest: (action) => {
          return this.activityResidentsService.create(
            action.activityResidents,
            action.params
          );
        },
        onSuccess: (activityResident) => {
          return ActivityResidentsApiActions.createActivityResidentSuccess({
            activityResident
          });
        },
        onError: (error) =>
          ActivityResidentsApiActions.createActivityResidentFail({ error })
      })
    );
  });

  getActivityResidents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LogsPageActions.getActivityResidents),
      this.effectHelpers.apiRequest({
        description: 'getting activity residents',
        onRequest: (action) => {
          return this.activityResidentsService
            .getAll(action.params)
            .pipe(
              getAllPages(this.activityResidentsService, action.params.query),
              reducePaginatedResponses()
            );
        },
        onSuccess: (activityResidents) => {
          const responseData = new ApiData(
            'activityResidents',
            activityResidents,
            ActivityResidentsApiActions.getActivityResidentsSuccess
          );

          return responseData.getActions();
        },
        onError: (error) =>
          ActivityResidentsApiActions.getActivityResidentsFail({ error })
      })
    );
  });
}
