import { Table } from '$/models/data/base';
import { PrnResponseId } from '$shared/lookups/prn-responses.lookup';

export class Prn extends Table {
  orgId?: string;
  facilityId?: string;
  residentId?: string;
  medicationId?: string;
  quantity: number;
  strength: number;
  reason?: string;
  response?: PrnResponseId;
  responseNotes?: string;
  responseAt: string;
  responseBy?: string;
  givenAt: string;
  givenBy?: string;
  amountNotes?: string;
  medicationBatchId?: string;

  constructor(prn: Prn) {
    super();
    Object.assign(this, prn);
  }
}
