import { InventoryItemFormActions } from '$/app/pages/medications/shared/components/inventory-item-form/inventory-item-form.modal.actions';
import { ResidentFacesheetPageActions } from '$/app/pages/residents/resident-detail/facesheet/facesheet.actions';
import { MedicalProfessionalsManagerActions } from '$/app/pages/residents/resident-detail/medical-professionals-manager/medical-professionals-manager.actions';
import {
  EffectHelpersService,
  ResidentMedicalProfessionalsApiService
} from '$/app/services';
import { MedicalProfessionalsGeneralActions } from '$/app/store/medical-professionals/actions';
import { NormalizedAction, createActionsFromNormalizedData } from '$/app/utils';
import { Paginated, ResidentMedicalProfessional } from '$/models';
import { residentMedicalProfessionalSchema } from '$shared';
import { normalizeAndRemove } from '$shared/normalization/normalize-and-remove';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import {
  ResidentMedicalProfessionalDetailActions,
  ResidentMedicalProfessionalsApiActions,
  ResidentMedicalProfessionalsGeneralActions,
  ResidentMedicalProfessionalsListActions
} from './actions';

@Injectable()
export class ResidentMedicalProfessionalsEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly residentMedicalProfessionalsService = inject(
    ResidentMedicalProfessionalsApiService
  );
  private readonly effectHelpers = inject(EffectHelpersService);

  loadResidentMedicalProfessionals$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ResidentMedicalProfessionalsListActions.loadResidentMedicalProfessionals
      ),
      switchMap((action) => {
        return this.residentMedicalProfessionalsService
          .getAll(action.params)
          .pipe(
            map((residentMedicalProfessionals) => {
              return ResidentMedicalProfessionalsApiActions.loadResidentMedicalProfessionalsSuccess(
                { residentMedicalProfessionals }
              );
            }),
            catchError((error) => {
              return of(
                ResidentMedicalProfessionalsApiActions.loadResidentMedicalProfessionalsFail(
                  { error }
                )
              );
            })
          );
      })
    );
  });

  getResidentMedicalProfessionals$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(InventoryItemFormActions.getResidentMedicalProfessionals),
      switchMap((action) => {
        const params = {
          query: {
            residentId: action.residentId,
            $eager: { medicalProfessional: true }
          }
        };

        return this.residentMedicalProfessionalsService.getAll(params).pipe(
          mergeMap((result: Paginated<ResidentMedicalProfessional>) => {
            const normalizedData = normalizeAndRemove(
              result.data,
              [residentMedicalProfessionalSchema],
              { residentMedicalProfessionals: ['medicalProfessional'] }
            );

            return createActionsFromNormalizedData(normalizedData, {
              residentMedicalProfessionals: new NormalizedAction(
                ResidentMedicalProfessionalsGeneralActions.addResidentMedicalProfessionals,
                { dispatchOnEmpty: true }
              ),
              medicalProfessionals:
                MedicalProfessionalsGeneralActions.addMedicalProfessionals
            });
          }),
          catchError((error) => {
            return of(
              ResidentMedicalProfessionalsApiActions.getResidentMedicalProfessionalsFail(
                { error }
              )
            );
          })
        );
      })
    );
  });

  fetchResidentMedicalProfessional$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ResidentMedicalProfessionalDetailActions.fetchResidentMedicalProfessional
      ),
      switchMap((action) => {
        return this.residentMedicalProfessionalsService
          .get(action.id, action.params)
          .pipe(
            map((residentMedicalProfessional: ResidentMedicalProfessional) => {
              return ResidentMedicalProfessionalsApiActions.fetchResidentMedicalProfessionalSuccess(
                { residentMedicalProfessional }
              );
            }),
            catchError((error) => {
              return of(
                ResidentMedicalProfessionalsApiActions.fetchResidentMedicalProfessionalFail(
                  { error }
                )
              );
            })
          );
      })
    );
  });

  createResidentMedicalProfessional$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          MedicalProfessionalsManagerActions.addResidentMedicalProfessional
        ),
        exhaustMap((action) => {
          return this.residentMedicalProfessionalsService
            .create({
              residentId: action.residentId,
              medicalProfessionalId: action.medicalProfessionalId
            })
            .pipe(
              catchError((error) => {
                this.store.dispatch(
                  ResidentMedicalProfessionalsApiActions.createResidentMedicalProfessionalFail(
                    { error }
                  )
                );
                return EMPTY;
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  createResidentMedicalProfessionalFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          ResidentMedicalProfessionalsApiActions.createResidentMedicalProfessionalFail
        ),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  updateResidentMedicalProfessional$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          ResidentMedicalProfessionalDetailActions.updateResidentMedicalProfessional,
          ResidentFacesheetPageActions.setPrimaryMedicalProfessional
        ),
        switchMap((action) => {
          return this.residentMedicalProfessionalsService
            .patch(action.id, action.changes)
            .pipe(
              catchError((error) => {
                this.store.dispatch(
                  ResidentMedicalProfessionalsApiActions.updateResidentMedicalProfessionalFail(
                    { error }
                  )
                );
                return EMPTY;
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  updateResidentMedicalProfessionalFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          ResidentMedicalProfessionalsApiActions.updateResidentMedicalProfessionalFail
        ),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  deleteResidentMedicalProfessional$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          MedicalProfessionalsManagerActions.removeResidentMedicalProfessional,
          ResidentFacesheetPageActions.removeResidentMedicalProfessional
        ),
        exhaustMap((action) => {
          return this.residentMedicalProfessionalsService
            .delete(action.id, action.params)
            .pipe(
              catchError((error) => {
                this.store.dispatch(
                  ResidentMedicalProfessionalsApiActions.deleteResidentMedicalProfessionalFail(
                    { error }
                  )
                );
                return EMPTY;
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  deleteResidentMedicalProfessionalFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          ResidentMedicalProfessionalsApiActions.deleteResidentMedicalProfessionalFail
        ),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );
}
