import { Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const ActivityLogsPageActions = createActionGroup({
  source: 'Resident Activity Log Page',
  events: {
    'Load Activity Logs': (params: Params) => ({ params }),
    'Get Activity Logs': (params: Params) => ({
      params
    }),
    'Get Activities': (params: Params) => ({
      params
    }),
    'Delete Activity Log': props<{ id: string; params?: Params }>()
  }
});
