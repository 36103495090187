import { IMedication } from '$shared/medications';
import { createActionGroup, props } from '@ngrx/store';

export const MedicationOrderItemComponentActions = createActionGroup({
  source: 'Medication Order Item',
  events: {
    'Add Medication To Order Queue': props<{ medications: IMedication[] }>(),
    'Remove Medication From Order Queue': props<{
      medications: IMedication[];
    }>()
  }
});
