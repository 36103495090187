import { Params, Update } from '$/models';
import { IPharmacyApiMessage } from '$shared/services/pharmacy-api-messages.schema';
import { IProposedChange } from '$shared/services/proposed-changes.schema';
import { createActionGroup, props } from '@ngrx/store';

export const MedicationReceivingPageActions = createActionGroup({
  source: 'Medication Receiving Page',
  events: {
    'Load Pharmacy Api Messages': (params: Params) => ({ params }),
    'Get Medical Professionals': (params: Params) => ({ params }),
    'Get Pharmacies': (params: Params) => ({ params }),
    'Change Pharmacy Api Message Relation':
      props<Update<IPharmacyApiMessage>>(),
    'Reject Proposed Change': props<Update<IProposedChange>>()
  }
});
