import { MedicationReceivingTableActions } from '$/app/pages/medications/dashboard/medication-receiving/medication-receiving-table/medication-receiving-table.actions';
import { MedicationReceivingPageActions } from '$/app/pages/medications/dashboard/medication-receiving/medication-receiving.actions';
import { MedicationFormPageActions } from '$/app/pages/medications/residents/medication-form/medication-form.actions';
import { ResidentModalActions } from '$/app/pages/residents/resident-modal/resident.modal.actions';
import { EffectHelpersService, OverlayService } from '$/app/services';
import { MedicalProfessionalFormActions } from '$/app/shared/pages/forms/medical-professional-form/medical-professional-form.actions';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProposedChangesApiActions } from './proposed-changes.actions';
import { ProposedChangesApiService } from './proposed-changes.service';

@Injectable()
export class ProposedChangesEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly overlay = inject(OverlayService);
  private readonly proposedChangesService = inject(ProposedChangesApiService);

  updateProposedChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MedicationReceivingTableActions.updateProposedChange,
        MedicalProfessionalFormActions.rejectProposedChange,
        MedicationFormPageActions.rejectProposedChange,
        ResidentModalActions.rejectProposedChange,
        MedicationReceivingPageActions.rejectProposedChange
      ),
      this.effectHelpers.apiRequest({
        description: 'Update Proposed Change',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.proposedChangesService.patch(
            action.id,
            action.changes,
            action.params
          );
        },
        onSuccess: (entity) =>
          ProposedChangesApiActions.updateProposedChangeSuccess({
            proposedChange: entity
          }),
        onError: (error) =>
          ProposedChangesApiActions.updateProposedChangeFail({ error })
      })
    );
  });
}
