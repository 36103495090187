import { createFeature, createReducer, on } from '@ngrx/store';
import { MedicationReceivingFilterComponentActions } from '../medication-receiving-filter/medication-receiving-filter.actions';
import { initialState } from './medication-receiving-page.state';

export const uiMedicationReceivingPageFeature = createFeature({
  name: 'uiMedicationReceivingPage',
  reducer: createReducer(
    initialState,
    on(
      MedicationReceivingFilterComponentActions.filterChanged,
      (state, action) => ({
        ...state,
        filters: action.filters
      })
    )
  )
});
