/* 
  Each icon configuration in the array provides the information needed
  to register new svg icons with angular material using 
  iconRegistry.addSvgIcon(). The registration is done in the app.component.ts
  file. For that reason, the url for each item should be relative to 
  app.component.ts.
*/
export const CUSTOM_MATERIAL_ICONS = [
  {
    name: 'poop',
    url: '../assets/icon/material/poop.svg'
  },
  {
    name: 'account_arrow_left',
    url: '../assets/icon/material/account_arrow_left.svg'
  },
  {
    name: 'account_key_outline',
    url: '../assets/icon/material/account_key_outline.svg'
  },
  {
    name: 'account_remove',
    url: '../assets/icon/material/account_remove.svg'
  },
  {
    name: 'account_group',
    url: '../assets/icon/material/account_group.svg'
  },
  {
    name: 'account_off',
    url: '../assets/icon/material/account_off.svg'
  },
  {
    name: 'cancel_outline',
    url: '../assets/icon/material/cancel_outline.svg'
  },
  {
    name: 'hand_pill',
    url: '../assets/icon/alcomy/hand_pill.svg'
  },
  {
    name: 'hand_pill_outline',
    url: '../assets/icon/alcomy/hand_pill_outline.svg'
  },
  {
    name: 'pill_check',
    url: '../assets/icon/alcomy/pill_check.svg'
  },
  {
    name: 'file_document_clock_outline',
    url: '../assets/icon/alcomy/file_document_clock_outline.svg'
  },
  {
    name: 'file_document_clock',
    url: '../assets/icon/alcomy/file_document_clock.svg'
  },
  {
    name: 'file_alert_outline',
    url: '../assets/icon/material/file_alert_outline.svg'
  },
  {
    name: 'file_alert',
    url: '../assets/icon/material/file_alert.svg'
  },
  {
    name: 'file_check_outline',
    url: '../assets/icon/material/file_check_outline.svg'
  },
  {
    name: 'file_check',
    url: '../assets/icon/material/file_check.svg'
  },
  {
    name: 'file_document_alert_outline',
    url: '../assets/icon/material/file_document_alert_outline.svg'
  },
  {
    name: 'file_document_alert',
    url: '../assets/icon/material/file_document_alert.svg'
  },
  {
    name: 'file_document_arrow_right_outline',
    url: '../assets/icon/material/file_document_arrow_right_outline.svg'
  },
  {
    name: 'file_document_arrow_right',
    url: '../assets/icon/material/file_document_arrow_right.svg'
  },
  {
    name: 'file_document_check_outline',
    url: '../assets/icon/material/file_document_check_outline.svg'
  },
  {
    name: 'file_document_check',
    url: '../assets/icon/material/file_document_check.svg'
  },
  {
    name: 'file_document_edit_outline',
    url: '../assets/icon/material/file_document_edit_outline.svg'
  },
  {
    name: 'file_document_edit',
    url: '../assets/icon/material/file_document_edit.svg'
  },
  {
    name: 'file_document_multiple_outline',
    url: '../assets/icon/material/file_document_multiple_outline.svg'
  },
  {
    name: 'file_document_multiple',
    url: '../assets/icon/material/file_document_multiple.svg'
  },
  {
    name: 'file_document_outline',
    url: '../assets/icon/material/file_document_outline.svg'
  },
  {
    name: 'file_document',
    url: '../assets/icon/material/file_document.svg'
  },
  {
    name: 'file_document_plus_outline',
    url: '../assets/icon/material/file_document_plus_outline.svg'
  },
  {
    name: 'file_document_plus',
    url: '../assets/icon/material/file_document_plus.svg'
  },
  {
    name: 'file_document_remove_outline',
    url: '../assets/icon/material/file_document_remove_outline.svg'
  },
  {
    name: 'file_document_remove',
    url: '../assets/icon/material/file_document_remove.svg'
  },
  {
    name: 'file_download_outline',
    url: '../assets/icon/material/file_download_outline.svg'
  },
  {
    name: 'file_download',
    url: '../assets/icon/material/file_download.svg'
  },
  {
    name: 'file_edit_outline',
    url: '../assets/icon/material/file_edit_outline.svg'
  },
  {
    name: 'file_edit',
    url: '../assets/icon/material/file_edit.svg'
  },
  {
    name: 'file_move',
    url: '../assets/icon/material/file_move.svg'
  },
  {
    name: 'file_move_outline',
    url: '../assets/icon/material/file_move_outline.svg'
  },
  {
    name: 'file_excel_box',
    url: '../assets/icon/material/file_excel_box.svg'
  },
  {
    name: 'file_gif_box',
    url: '../assets/icon/material/file_gif_box.svg'
  },
  {
    name: 'file_hidden',
    url: '../assets/icon/material/file_hidden.svg'
  },
  {
    name: 'file_jpg_box',
    url: '../assets/icon/material/file_jpg_box.svg'
  },
  {
    name: 'file_multiple_outline',
    url: '../assets/icon/material/file_multiple_outline.svg'
  },
  {
    name: 'file_multiple',
    url: '../assets/icon/material/file_multiple.svg'
  },
  {
    name: 'file_outline',
    url: '../assets/icon/material/file_outline.svg'
  },
  {
    name: 'file',
    url: '../assets/icon/material/file.svg'
  },
  {
    name: 'file_pdf_box',
    url: '../assets/icon/material/file_pdf_box.svg'
  },
  {
    name: 'file_plus_outline',
    url: '../assets/icon/material/file_plus_outline.svg'
  },
  {
    name: 'file_plus',
    url: '../assets/icon/material/file_plus.svg'
  },
  {
    name: 'file_png_box',
    url: '../assets/icon/material/file_png_box.svg'
  },
  {
    name: 'file_remove_outline',
    url: '../assets/icon/material/file_remove_outline.svg'
  },
  {
    name: 'file_remove',
    url: '../assets/icon/material/file_remove.svg'
  },
  {
    name: 'file_sign',
    url: '../assets/icon/material/file_sign.svg'
  },
  {
    name: 'file_upload_outline',
    url: '../assets/icon/material/file_upload_outline.svg'
  },
  {
    name: 'file_upload',
    url: '../assets/icon/material/file_upload.svg'
  },
  {
    name: 'file_word_box',
    url: '../assets/icon/material/file_word_box.svg'
  },
  {
    name: 'file_eye',
    url: '../assets/icon/material/file_eye.svg'
  },
  {
    name: 'notifications',
    url: '../assets/icon/material/notifications.svg'
  },
  {
    name: 'prescriptions',
    url: '../assets/icon/material/prescriptions.svg'
  },
  {
    name: 'contract',
    url: '../assets/icon/material/contract.svg'
  },
  {
    name: 'contract_edit',
    url: '../assets/icon/material/contract_edit.svg'
  },
  {
    name: 'contract_delete',
    url: '../assets/icon/material/contract_delete.svg'
  },
  {
    name: 'contract_add',
    url: '../assets/icon/alcomy/contract_add.svg'
  },
  {
    name: 'numeric_1',
    url: '../assets/icon/material/numeric_1.svg'
  },
  {
    name: 'eraser',
    url: '../assets/icon/material/eraser.svg'
  },
  {
    name: 'filter_outline',
    url: '../assets/icon/material/filter_outline.svg'
  },
  {
    name: 'info_outline',
    url: '../assets/icon/material/info_outline.svg'
  }
];
