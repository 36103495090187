import { dateRangeToQuery } from '$/app/utils';
import { createActionGroup } from '@ngrx/store';
import { EventListFilters } from '../events-list.vmodel';

export const EventListFilterComponentActions = createActionGroup({
  source: 'Event List Filter Component',
  events: {
    'Filter Changed': (
      filters: EventListFilters
    ): { filters: EventListFilters; queryParams: Record<string, any> } => {
      return {
        filters,
        queryParams: {
          types: filters.types,
          medicalEventTypes: filters.medicalEventTypes,
          ...dateRangeToQuery(filters.dateRange)
        }
      };
    }
  }
});
