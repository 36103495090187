import { Params } from '$/models';
import { IMedication } from '$shared/medications';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const MedicationsToOrderListPageActions = createActionGroup({
  source: 'Medications To Order List Page',
  events: {
    'Get Medications To Order List Page Info': (
      params: Params = { query: {} }
    ) => ({ params }),

    'Add All Medications To Order Queue': props<{
      medications: IMedication[];
    }>(),

    'Remove All Medications From Order Queue': props<{
      medications: IMedication[];
    }>(),

    'Order Queued Medications': emptyProps(),

    'Clear Order Queue': emptyProps()
  }
});
