import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { MissedBowelMovementsPageActions } from '$/app/pages/notebook/dashboard/missed-bowel-movements/missed-bowel-movements.actions';
import { BmLogsPageActions } from '$/app/pages/notebook/shared/logs/bowel-movement-log/bowel-movement-log.actions';
import { BmLogFormPageActions } from '$/app/shared/pages/forms/log-forms/bm-log-form/bm-log-form.actions';
import { getPaginationData, getRecords } from '$/app/utils';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  BmLogsApiActions,
  BmLogsGeneralActions,
  BmLogsWsActions
} from './bowel-movement-logs.actions';
import {
  BmLogPageQueries,
  bmLogsAdapter,
  initialState
} from './bowel-movement-logs.state';

export const bmLogsFeature = createFeature({
  name: 'bmLogs',
  reducer: createReducer(
    initialState,

    on(
      BmLogsPageActions.loadBmLogs,
      BmLogsPageActions.getBmLogs,
      BmLogFormPageActions.createBowelMovementLog,
      BmLogFormPageActions.updateBowelMovementLog,
      BmLogsPageActions.deleteBmLog,
      ResidentMedicationTasksPageActions.deleteBowelMovementLog,
      MissedMedicationsPageActions.deleteBowelMovementLog,
      RoutineMarDetailPageActions.deleteBowelMovementLog,
      RoutineMarListPageActions.deleteBowelMovementLog,
      MissedBowelMovementsPageActions.loadMissedBowelMovements,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),

    on(BmLogsApiActions.loadBmLogsSuccess, (state, action) => {
      const queryType = action.metadata?.pageQueryType;
      const pagination = getPaginationData<BmLogPageQueries>(
        state,
        action.bmLogs,
        queryType
      );
      const data = getRecords(action.bmLogs);

      return bmLogsAdapter.setAll(data, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(BmLogsApiActions.getBmLogsSuccess, (state, action) => {
      const queryType = action.metadata?.pageQueryType;
      const pagination = getPaginationData<BmLogPageQueries>(
        state,
        action.bmLogs,
        queryType
      );
      const data = getRecords(action.bmLogs);

      return bmLogsAdapter.upsertMany(data, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(BmLogsApiActions.createBmLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(BmLogsApiActions.updateBmLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(BmLogsApiActions.deleteBmLogSuccess, (state, action) =>
      bmLogsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        error: null
      })
    ),

    on(
      BmLogsApiActions.loadBmLogsFail,
      BmLogsApiActions.getBmLogsFail,
      BmLogsApiActions.createBmLogFail,
      BmLogsApiActions.updateBmLogFail,
      BmLogsApiActions.deleteBmLogFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      BmLogsWsActions.bmLogsCreated,
      BmLogsWsActions.bmLogsPatched,
      (state, action) => {
        return bmLogsAdapter.upsertOne(action.bmLog, state);
      }
    ),

    on(BmLogsWsActions.bmLogsRemoved, (state, action) => {
      return bmLogsAdapter.removeOne(action.id, state);
    }),

    on(BmLogsGeneralActions.addBmLogs, (state, action) => {
      return bmLogsAdapter.upsertMany(action.bmLogs, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(BmLogsGeneralActions.clearBmLogs, () => {
      return bmLogsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
