import { Params, Update } from '$/models';
import { IActivity } from '$shared/services/activity.schema';

import { createActionGroup, props } from '@ngrx/store';

export const ActivitiesFormModalActions = createActionGroup({
  source: 'Activities Form Page',
  events: {
    'Create Activity': props<{
      activity: IActivity;
      params: Params;
    }>(),
    'Update Activity': props<Update<IActivity>>()
  }
});
