import { Component, input } from '@angular/core';
import { AlcCommonModule } from '../../alc-common.module';

@Component({
  selector: 'alc-info-block',
  standalone: true,
  imports: [AlcCommonModule],
  templateUrl: 'info-block.component.html',
  styles: `
    :host {
      display: block;
    }
  `
})
export class AlcInfoBlockComponent {
  public readonly label = input.required<string>();
  public readonly tooltip = input<string>();
}
