import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { activityResidentsFeature } from './activity-residents.reducer';
import { ActivityResidentEffects } from './activity-resident.effects';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(activityResidentsFeature),
    EffectsModule.forFeature([ActivityResidentEffects])
  ],
  providers: [ActivityResidentEffects]
})
export class ActivityResidentsStoreModule {}
