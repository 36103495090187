import { IMedicationSchedule } from '$shared/medication-schedules';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const medicationSchedulesAdapter =
  createEntityAdapter<IMedicationSchedule>();

export interface State extends EntityState<IMedicationSchedule> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = medicationSchedulesAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
