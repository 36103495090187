import { FacilityContactsPageActions } from '$/app/pages/facilities/facility-detail/facility-contacts/facility-contacts.actions';
import { MedicationFormPageActions } from '$/app/pages/medications/residents/medication-form/medication-form.actions';
import { MedicalProfessionalsManagerActions } from '$/app/pages/residents/resident-detail/medical-professionals-manager/medical-professionals-manager.actions';
import {
  EffectHelpersService,
  MedicalProfessionalsApiService
} from '$/app/services';
import { MedicalProfessionalInfoModalActions } from '$/app/shared/components/medical-professional-info/medical-professional-info.actions';
import { MedicalProfessionalFormActions } from '$/app/shared/pages/forms/medical-professional-form/medical-professional-form.actions';
import * as MedicalProfessionalsSelectors from '$/app/store/medical-professionals/medical-professionals.selectors';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { MedicalProfessionalsApiActions } from './actions';

@Injectable()
export class MedicalProfessionalsEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly medicalProfessionalsService = inject(
    MedicalProfessionalsApiService
  );
  private readonly effectHelpers = inject(EffectHelpersService);

  loadMedicalProfessionals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MedicalProfessionalsManagerActions.loadMedicalProfessionals,
        MedicationFormPageActions.loadMedicalProfessionals
      ),
      withLatestFrom(
        this.store.select(MedicalProfessionalsSelectors.selectLoaded)
      ),
      filter(([, loaded]) => !loaded),
      map(([action]) => action),
      this.effectHelpers.apiRequest({
        description: 'Load medical professionals',
        onRequest: (action) =>
          this.medicalProfessionalsService
            .getAll(action.params)
            .pipe(
              getAllPages(
                this.medicalProfessionalsService,
                action.params?.query
              ),
              reducePaginatedResponses()
            ),
        onSuccess: (medicalProfessionals) => {
          const responseData = new ApiData(
            'medicalProfessionals',
            medicalProfessionals,
            MedicalProfessionalsApiActions.loadMedicalProfessionalsSuccess
          );

          return responseData.getActions();
        },
        onError: (error) =>
          MedicalProfessionalsApiActions.loadMedicalProfessionalsFail({ error })
      })
    )
  );

  fetchMedicalProfessional$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MedicalProfessionalInfoModalActions.fetchMedicalProfessional),
      this.effectHelpers.apiRequest({
        description: 'Fetch medical professional',
        onRequest: (action) =>
          this.medicalProfessionalsService.get(action.id, action.params),
        onSuccess: (medicalProfessionals) => {
          const responseData = new ApiData(
            'medicalProfessionals',
            medicalProfessionals,
            MedicalProfessionalsApiActions.fetchMedicalProfessionalSuccess,
            {
              payloadKey: 'medicalProfessional'
            }
          );

          return responseData.getActions();
        },
        onError: (error) =>
          MedicalProfessionalsApiActions.fetchMedicalProfessionalFail({ error })
      })
    )
  );

  createMedicalProfessional$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MedicalProfessionalFormActions.createMedicalProfessional),
        exhaustMap((action) =>
          this.medicalProfessionalsService
            .create(action.medicalProfessional, action.params)
            .pipe(
              catchError((error) => {
                this.store.dispatch(
                  MedicalProfessionalsApiActions.createMedicalProfessionalFail({
                    error
                  })
                );

                return EMPTY;
              })
            )
        ),
        tap(
          this.effectHelpers.onModalFormSubmitSuccess(
            'Medical Professional created successfully!'
          )
        )
      ),
    { dispatch: false }
  );

  createMedicalProfessionalFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MedicalProfessionalsApiActions.createMedicalProfessionalFail),
        tap(this.effectHelpers.onFormSubmitFail())
      ),
    { dispatch: false }
  );

  updateMedicalProfessional$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MedicalProfessionalFormActions.updateMedicalProfessional,
        FacilityContactsPageActions.mergeMedicalProfessionals,
        FacilityContactsPageActions.archiveMedicalProfessional
      ),
      this.effectHelpers.apiRequest({
        description: 'Update medical professional',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.medicalProfessionalsService.patch(
            action.id,
            action.changes,
            action.params
          );
        },
        onSuccess: (medicalProfessional) =>
          MedicalProfessionalsApiActions.updateMedicalProfessionalSuccess({
            medicalProfessional
          }),
        onError: (error) =>
          MedicalProfessionalsApiActions.updateMedicalProfessionalFail({
            error
          })
      })
    );
  });
}
