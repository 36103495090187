import { medicationsAdapter } from '$/app/store/medications/medications.state';
import {
  selectParam,
  selectRouterState
} from '$/app/store/router/router.selectors';
import { sifter } from '$/app/utils/filtering/sifter';
import { IMedication } from '$shared/medications';
import { Dictionary } from '$shared/types';
import { createSelector } from '@ngrx/store';
import { filter, isEmpty, keyBy } from 'lodash';
import { Query } from 'sift';
import { medicationsFeature } from './medications.reducer';

export const {
  selectMedicationsState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = medicationsFeature;

export const { selectAll, selectTotal } = medicationsAdapter.getSelectors(
  selectMedicationsState
);

const selectWithFilter = (filters: Query<IMedication>) => {
  return createSelector(selectAll, (medications) => {
    return medications.filter(sifter<IMedication>(filters));
  });
};

/**
 * Select all medications where active is set to true. These
 * should be the latest medication of each batch regardless
 * of the status.
 */
const selectAllLatest = createSelector(
  selectAll,
  (medications): IMedication[] => {
    return medications.filter((medication) => {
      return medication.active;
    });
  }
);

const selectLatestByBatchId = createSelector(selectAllLatest, (medications) =>
  keyBy(medications, 'batchId')
);

/**
 * Select all medications where active is set to true
 * and status is set to 'active'
 */
const selectAllActive = createSelector(
  selectAll,
  (medications): IMedication[] => {
    return medications.filter((medication) => {
      return medication.active && medication.statusId === 'active';
    });
  }
);

/**
 * Select all medications as a dictionary where active is set to true
 * and status is set to 'active'
 */
const selectActiveEntities = createSelector(
  selectAllActive,
  (medications): Dictionary<IMedication> => {
    return keyBy(medications, 'id');
  }
);

/**
 * //DEPRECATED: Use SharedMedicationSelectors.selectLatestMedication instead
 *
 * Select the most recent medication of a batch, by using the batchId
 * in the route params
 */
const selectMedication = createSelector(
  selectAllLatest,
  selectRouterState,
  (medications, routerState): IMedication => {
    const medicationsByBatchId = keyBy(medications, 'batchId');

    if (
      isEmpty(medicationsByBatchId) ||
      !routerState?.params?.medicationBatchId
    ) {
      return {} as IMedication;
    }

    return (
      routerState && medicationsByBatchId[routerState.params.medicationBatchId]
    );
  }
);

/**
 * Select the most recent medication, by using the medicationId
 * in the route params
 */
const selectMedicationById = createSelector(
  selectEntities,
  selectRouterState,
  (entities, routerState): IMedication => {
    if (!entities || !routerState?.params?.medicationId) {
      return {} as IMedication;
    }

    return routerState && entities[routerState.params.medicationId];
  }
);

/**
 * Select a medication with a medicationId
 */
const selectMedicationWithId = (medicationId: string) =>
  createSelector(
    selectEntities,
    (medicationEntities) => medicationEntities[medicationId]
  );

/**
 * Select an array of medications that belong to a resident, by using
 * the residentId in the route params
 */
const selectResidentMedications = createSelector(
  selectAllLatest,
  selectParam('residentId'),
  (medications, residentId) => filter(medications, { residentId })
);

/**
 * Select an array of prn medications where active is set to true
 * and status is set to 'active'
 */
const selectActivePrnMedications = createSelector(
  selectAllActive,
  (medications) => filter(medications, 'isPrn')
);

const selectNarcotics = createSelector(selectAllActive, (medications) =>
  filter(medications, 'isNarcotic')
);

const selectNarcoticsByResidentId = (residentId: string) => {
  return createSelector(selectAllActive, (medications) =>
    medications.filter(
      (medication) =>
        medication.isNarcotic && medication.residentId === residentId
    )
  );
};

export const MedicationsSelectors = {
  selectAll,
  selectEntities,
  selectAllActive,
  selectWithFilter,
  selectAllLatest,
  selectLatestByBatchId,
  selectMedication,
  selectMedicationById,
  selectMedicationWithId,
  selectResidentMedications,
  selectActivePrnMedications,
  selectActiveEntities,
  selectNarcotics,
  selectNarcoticsByResidentId,
  selectLoading,
  selectIds,
  selectLoaded,
  selectError
};
