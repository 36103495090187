import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PharmacyApiMessagesEffects } from './pharmacy-api-messages.effects';
import { pharmacyApiMessagesFeature } from './pharmacy-api-messages.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(pharmacyApiMessagesFeature),
    EffectsModule.forFeature([PharmacyApiMessagesEffects])
  ],
  providers: [PharmacyApiMessagesEffects]
})
export class PharmacyApiMessagesStoreModule {}
