import { FacilityContactsPageActions } from '$/app/pages/facilities/facility-detail/facility-contacts/facility-contacts.actions';
import { PharmacyInfoPageActions } from '$/app/pages/facilities/facility-detail/pharamcy-info/pharmacy-info.page.actions';
import { InventoryItemFormActions } from '$/app/pages/medications/shared/components/inventory-item-form/inventory-item-form.modal.actions';
import { ResidentModalActions } from '$/app/pages/residents/resident-modal/resident.modal.actions';
import * as NewPharmacyFormActions from '$/app/shared/pages/forms/pharmacy-form/pharmacy-form.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  PharmaciesApiActions,
  PharmaciesGeneralActions,
  PharmaciesListActions
} from './actions';
import { State, initialState, pharmaciesAdapter } from './pharmacies.state';

const reducer = createReducer(
  initialState,

  on(
    PharmaciesListActions.loadPharmacies,
    InventoryItemFormActions.loadPharmacies,
    ResidentModalActions.loadPharmacies,
    PharmaciesListActions.getPharmacies,
    NewPharmacyFormActions.createPharmacy,
    FacilityContactsPageActions.deletePharmacy,
    PharmacyInfoPageActions.deletePharmacy,
    FacilityContactsPageActions.mergePharmacies,
    (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),

  on(PharmaciesApiActions.loadPharmaciesSuccess, (state, action) => {
    return pharmaciesAdapter.setAll(action.pharmacies.data, {
      ...state,

      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(PharmaciesApiActions.getPharmaciesSuccess, (state, action) => {
    return pharmaciesAdapter.upsertMany(action.pharmacies.data, {
      ...state,

      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(PharmaciesApiActions.fetchPharmacySuccess, (state, action) => {
    return pharmaciesAdapter.upsertOne(action.pharmacy, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(PharmaciesApiActions.createPharmacySuccess, (state, action) => {
    const pharmacies = castArray(action.pharmacy);

    return pharmaciesAdapter.upsertMany(pharmacies, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(PharmaciesApiActions.updatePharmacySuccess, (state, action) => {
    return pharmaciesAdapter.updateOne(action.pharmacy, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(PharmaciesApiActions.deletePharmacySuccess, (state, action) => {
    return pharmaciesAdapter.removeOne(action.id, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(
    PharmaciesApiActions.loadPharmaciesFail,
    PharmaciesApiActions.getPharmaciesFail,
    PharmaciesApiActions.fetchPharmacyFail,
    PharmaciesApiActions.createPharmacyFail,
    PharmaciesApiActions.updatePharmacyFail,
    PharmaciesApiActions.deletePharmacyFail,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
  ),

  on(
    PharmaciesApiActions.pharmaciesCreated,
    PharmaciesApiActions.pharmaciesPatched,
    (state, action) => {
      return pharmaciesAdapter.upsertOne(action.pharmacy, {
        ...state
      });
    }
  ),

  on(PharmaciesApiActions.pharmaciesRemoved, (state, action) => {
    return pharmaciesAdapter.removeOne(action.id, {
      ...state
    });
  }),

  on(PharmaciesGeneralActions.addPharmacies, (state, action) => {
    return pharmaciesAdapter.upsertMany(action.pharmacies, {
      ...state,
      loading: false,
      error: null
    });
  }),

  on(PharmaciesGeneralActions.clearPharmacies, (state) => {
    return pharmaciesAdapter.removeAll({
      ...state,

      loading: false,
      loaded: false,
      error: null
    });
  })
);

export function pharmaciesReducer(state = initialState, action: Action): State {
  return reducer(state, action);
}
