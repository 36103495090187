import { AppComponentActions } from '$/app/app.actions';
import { FacilityContactsPageActions } from '$/app/pages/facilities/facility-detail/facility-contacts/facility-contacts.actions';
import { FacilityProfilePageActions } from '$/app/pages/facilities/facility-detail/facility-profile/facility-profile.actions';
import { EffectHelpersService } from '$/app/services';
import { FacilitiesApiService } from '$/app/services/api/facilities.service';
import { FacilityModalActions } from '$/app/shared/pages/forms/facility-modal/facility.modal.actions';
import { FacilityPhonesGeneralActions } from '$/app/store/facility-phones/actions/facility-phones-gen.actions';
import { ofRoute } from '$/app/store/lib/ofRoute';
import {
  ApiData,
  NormalizedAction,
  createActionsFromNormalizedData
} from '$/app/utils';
import { Facility, FacilityGraph } from '$/models';
import { facilitySchema } from '$shared';
import { normalizeAndRemove } from '$shared/normalization/normalize-and-remove';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import {
  catchError,
  mergeMap,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import { FacilitiesApiActions } from './facilities.actions';
import * as FacilitiesSelectors from './facilities.selectors';

@Injectable()
export class FacilitiesEffects {
  private readonly actions$ = inject(Actions);
  private readonly facilitiesService = inject(FacilitiesApiService);
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly store = inject(Store);

  onLoadFacilities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppComponentActions.loadFacilities),
      switchMap((action) =>
        this.facilitiesService.getAll(action.params).pipe(
          mergeMap((facilities: Facility[]) => {
            const formattedFacilities = facilities.map((facility: Facility) => {
              facility.residents = facility?.residents?.[0]?.count ?? 0;
              return facility;
            });

            const normalizedData = normalizeAndRemove(
              formattedFacilities,
              [facilitySchema],
              { phoneNumbers: ['phoneNumbers'] }
            );

            return createActionsFromNormalizedData(normalizedData, {
              facilities: new NormalizedAction(
                FacilitiesApiActions.loadFacilitiesSuccess,
                { dispatchOnEmpty: true }
              ),
              facilityPhones: new NormalizedAction(
                FacilityPhonesGeneralActions.addFacilityPhones,
                { dispatchOnEmpty: true }
              )
            });
          }),
          catchError((error) =>
            of(FacilitiesApiActions.loadFacilitiesFail({ error }))
          )
        )
      )
    )
  );

  fetchFacility$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        FacilityProfilePageActions.fetchFacility,
        FacilityContactsPageActions.fetchFacility
      ),
      this.effectHelpers.apiRequest({
        description: 'Fetch facility contact page info',
        onRequest: (action) =>
          this.facilitiesService.get(action.id, action.params),
        onSuccess: (facility) => {
          const responseData = new ApiData(
            'facilities',
            facility,
            FacilitiesApiActions.fetchFacilitySuccess,
            {
              payloadKey: 'facility',
              dispatchOnEmpty: true
            }
          );
          return responseData.getActions();
        },
        onError: (error) => FacilitiesApiActions.fetchFacilityFail({ error })
      })
    )
  );

  updateFacility$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        FacilityModalActions.updateFacility,
        FacilityProfilePageActions.uploadLogoFile,
        FacilityProfilePageActions.removeLogoFile
      ),
      this.effectHelpers.apiRequest({
        description: 'Update facility',
        useMapOperator: 'exhaustMap',
        onRequest: (action) =>
          this.facilitiesService.patch(
            action.id,
            action.changes,
            action.params
          ),
        onSuccess: (facility: Facility) =>
          FacilitiesApiActions.updateFacilitySuccess({
            facility
          }),
        onError: (error) => FacilitiesApiActions.updateFacilityFail({ error })
      })
    )
  );

  routesWithFacilityEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofRoute([/:facilityId/g]),
      withLatestFrom(this.store.select(FacilitiesSelectors.selectFacility)),
      switchMap(([action, facility]) => {
        if (facility) {
          return EMPTY;
        }

        const facilityId: string = action.payload.routerState.params.facilityId;

        return this.facilitiesService.get(facilityId).pipe(
          mergeMap((facility: FacilityGraph) =>
            new ApiData(
              'facilities',
              facility,
              FacilitiesApiActions.fetchFacilitySuccess,
              { payloadKey: 'facility' }
            ).getActions()
          ),
          catchError((error) =>
            of(FacilitiesApiActions.fetchFacilityFail({ error }))
          )
        );
      })
    )
  );
}
