import { IMedicalProfessionalPhone } from '$shared/services/medical-professional-phone.schema';
import { createAction, props } from '@ngrx/store';

export const clearMedicalProfessionalPhones = createAction(
  '[Medical Professional Phones] Clear Medical Professional Phones'
);

export const addMedicalProfessionalPhones = createAction(
  'Add Medical Professional Phones',
  props<{ medicalProfessionalPhones: IMedicalProfessionalPhone[] }>()
);
