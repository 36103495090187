import { lookupValue } from '$shared/lookups/lookup-value';
import { MedicationFormId } from '$shared/lookups/medication-forms.lookup';
import { IMedication } from '$shared/medications';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'medIcon', standalone: true })
export class AlcMedicationIconPipe implements PipeTransform {
  transform(value: Pick<IMedication, 'formId'> | MedicationFormId) {
    if (!value) {
      return null;
    }

    if (typeof value === 'string') {
      return lookupValue('medicationForms', value, 'icon');
    }

    return lookupValue('medicationForms', value.formId, 'icon');
  }
}
