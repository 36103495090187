import { IDocument, Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const DocumentTrackingPageActions = createActionGroup({
  source: 'Document Tracking Page',
  events: {
    'Load Documents': (params: Params) => ({ params }),
    'Get Document Templates': (params: Params = { query: {} }) => ({ params }),
    'Create Document': props<{ document: IDocument; params: Params }>(),
    'Create Document From Template': props<{
      document: IDocument;
      params: Params;
    }>()
  }
});
