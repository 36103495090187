import { Paginated } from '$/models';
import { IMedicationSchedule } from '$shared/medication-schedules';
import { createAction, props } from '@ngrx/store';

// [API] Load Success/Fail
export const loadMedicationSchedulesSuccess = createAction(
  '[Medication Schedules API] Load Medication Schedules Success',
  props<{ medicationSchedules: Paginated<IMedicationSchedule> }>()
);
export const loadMedicationSchedulesFail = createAction(
  '[Medication Schedules API] Load Medication Schedules Fail',
  props<{ error: Error }>()
);

// [API] Get Success/Fail
export const getMedicationSchedulesSuccess = createAction(
  '[Medication Schedules API] Get Medication Schedules Success',
  props<{ medicationSchedules: Paginated<IMedicationSchedule> }>()
);
export const getMedicationSchedulesFail = createAction(
  '[Medication Schedules API] Get Medication Schedules Fail',
  props<{ error: Error }>()
);

// [API] Fetch Success/Fail
export const fetchMedicationScheduleSuccess = createAction(
  '[Medication Schedules API] Fetch Medication Schedule Success',
  props<{ medicationSchedule: IMedicationSchedule }>()
);
export const fetchMedicationScheduleFail = createAction(
  '[Medication Schedules API] Fetch Medication Schedule Fail',
  props<{ error: Error }>()
);

// [WS] MedicationSchedules Created
export const medicationSchedulesCreated = createAction(
  '[Medication Schedules WS] Medication Schedules Created',
  props<{ medicationSchedule: IMedicationSchedule }>()
);
// [WS] MedicationSchedules Patched
export const medicationSchedulesPatched = createAction(
  '[Medication Schedules WS] Medication Schedules Patched',
  props<{ medicationSchedule: IMedicationSchedule }>()
);
// [WS] MedicationSchedules Removed
export const medicationSchedulesRemoved = createAction(
  '[Medication Schedules WS] Medication Schedules Removed',
  props<{ id: string }>()
);
