import { MedicationFormPageActions } from '$/app/pages/medications/residents/medication-form/medication-form.actions';
import { MedicalProfessionalsManagerActions } from '$/app/pages/residents/resident-detail/medical-professionals-manager/medical-professionals-manager.actions';
import { MedicalProfessionalInfoModalActions } from '$/app/shared/components/medical-professional-info/medical-professional-info.actions';
import { MedicalProfessionalFormActions } from '$/app/shared/pages/forms/medical-professional-form/medical-professional-form.actions';
import * as MedicalProfessionalsActions from '$/app/store/medical-professionals/medical-professionals.actions';
import {
  initialState,
  medicalProfessionalsAdapter
} from '$/app/store/medical-professionals/medical-professionals.state';
import { getPaginatedData, getRecords } from '$/app/utils';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  MedicalProfessionalsApiActions,
  MedicalProfessionalsGeneralActions
} from './actions';

export const medicalProfessionalsFeature = createFeature({
  name: 'medicalProfessionals',
  reducer: createReducer(
    initialState,

    on(
      MedicalProfessionalsManagerActions.loadMedicalProfessionals,
      MedicationFormPageActions.loadMedicalProfessionals,
      MedicalProfessionalInfoModalActions.fetchMedicalProfessional,
      MedicalProfessionalsActions.createMedicalProfessional_MedicalProfessionalForm,
      MedicalProfessionalFormActions.updateMedicalProfessional,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),

    on(
      MedicalProfessionalsApiActions.loadMedicalProfessionalsSuccess,
      (state, action) => {
        return medicalProfessionalsAdapter.setAll(
          getPaginatedData(action.medicalProfessionals),
          {
            ...state,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      MedicalProfessionalsApiActions.getMedicalProfessionalsSuccess,
      (state, action) => {
        return medicalProfessionalsAdapter.upsertMany(
          getPaginatedData(action.medicalProfessionals),
          {
            ...state,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      MedicalProfessionalsApiActions.fetchMedicalProfessionalSuccess,
      (state, action) => {
        return medicalProfessionalsAdapter.upsertOne(
          action.medicalProfessional,
          {
            ...state,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),

    on(
      MedicalProfessionalsActions.createMedicalProfessional_ResidentMedicalProfessionalsRoute,
      MedicalProfessionalsApiActions.createMedicalProfessionalSuccess,
      (state, action) => {
        const medicalProfessionals = getRecords(action.medicalProfessional);

        return medicalProfessionalsAdapter.upsertMany(medicalProfessionals, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      MedicalProfessionalsApiActions.updateMedicalProfessionalSuccess,
      (state, action) => {
        const records = getRecords(action.medicalProfessional);

        return medicalProfessionalsAdapter.upsertMany(records, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      MedicalProfessionalsApiActions.deleteMedicalProfessionalSuccess,
      (state, action) => {
        return medicalProfessionalsAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      MedicalProfessionalsApiActions.loadMedicalProfessionalsFail,
      MedicalProfessionalsApiActions.getMedicalProfessionalsFail,
      MedicalProfessionalsApiActions.fetchMedicalProfessionalFail,
      MedicalProfessionalsApiActions.createMedicalProfessionalFail,
      MedicalProfessionalsApiActions.updateMedicalProfessionalFail,
      MedicalProfessionalsApiActions.deleteMedicalProfessionalFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      MedicalProfessionalsApiActions.medicalProfessionalsCreated,
      MedicalProfessionalsApiActions.medicalProfessionalsPatched,
      (state, action) => {
        return medicalProfessionalsAdapter.upsertOne(
          action.medicalProfessional,
          state
        );
      }
    ),

    on(
      MedicalProfessionalsApiActions.medicalProfessionalsRemoved,
      (state, action) => {
        return medicalProfessionalsAdapter.removeOne(action.id, state);
      }
    ),

    on(
      MedicalProfessionalsGeneralActions.addMedicalProfessionals,
      (state, action) => {
        return medicalProfessionalsAdapter.upsertMany(
          action.medicalProfessionals,
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),

    on(
      MedicalProfessionalsGeneralActions.clearMedicalProfessionals,
      (state) => {
        return medicalProfessionalsAdapter.removeAll({
          ...state,
          loading: false,
          loaded: false,
          error: null
        });
      }
    )
  )
});
