import { createFeature, createReducer, on } from '@ngrx/store';
import { ActivityLogsFilterModalActions } from '../activity-logs-filter/activity-logs-filter.actions';
import { initialState } from './activity-logs-page.state';

export const uiActivityLogsPageFeature = createFeature({
  name: 'uiActivityLogsPage',
  reducer: createReducer(
    initialState,
    on(ActivityLogsFilterModalActions.filterChanged, (state, action) => ({
      ...state,
      filters: action.filters
    }))
  )
});
