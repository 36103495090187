import { IPaginationInfo } from '$/models';
import { IActivity } from '$shared/services/activity.schema';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export interface ActivityPageQueries {
  all: IPaginationInfo;
}

export const activitiesAdapter = createEntityAdapter<IActivity>();

export interface State extends EntityState<IActivity> {
  pagination: ActivityPageQueries;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = activitiesAdapter.getInitialState({
  pagination: {
    all: {
      total: 0,
      skip: 0
    }
  },
  loading: false,
  loaded: false,
  error: null
});
