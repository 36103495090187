import { Params, Update } from '$/models';
import { IMedication } from '$shared/medications';
import { IProposedChange } from '$shared/services/proposed-changes.schema';
import { MaybeArray } from '$shared/types/utility-types';
import { props } from '@ngrx/store';

import { createActionGroup } from '@ngrx/store';

export const MedicationFormPageActions = createActionGroup({
  source: 'Medication Form Modal',
  events: {
    'Create Medication': props<{
      medication: MaybeArray<Partial<IMedication>>;
      params?: Params;
    }>(),
    'Update Medication': props<{
      id: string;
      medication: Partial<IMedication>;
      params?: Params;
    }>(),
    'Reject Proposed Change': props<Update<IProposedChange>>(),
    'Load Medical Professionals': (params?: Params) => ({ params })
  }
});
