<ion-item
  [lines]="lines()"
  button
  [ngClass]="{ 'md:rounded-2xl md:shadow-md': !inCard() }"
  [style.--padding-start]="removePaddingStart() ? '0' : '16px'"
  [style.--inner-padding-end]="0"
>
  <div slot="start">
    <ng-content select="[alc-slot=start]" />
  </div>

  <ion-label class="mr-4">
    <div class="flex w-full items-center justify-between">
      <span class="w-full">
        <ng-content select="[alc-label]">
          <h2 class="whitespace-pre-line">{{ title() }}</h2>
          <p class="whitespace-pre-line text-medium-dark">
            {{ subtitle() }}
          </p>
        </ng-content>
      </span>

      <div class="flex flex-wrap items-center justify-end gap-x-1" slot="end">
        <ng-content select="[alc-list-item-pill]" />
      </div>
    </div>

    <ng-content select="[alc-list-item-content]" />
  </ion-label>

  <alc-menu-button
    [attr.data-testid]="menuButtonTestId()"
    [align]="'end'"
    [options]="menuOptions()"
    (optionSelected)="menuOptionSelected.emit($event)"
  />
</ion-item>
