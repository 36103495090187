import { trim } from 'lodash';
import { Logger } from '../logger';
import { LoggerPayload } from '../logger/types';

/**
 * This is a general utility function for getting values from a dictionary.
 *
 * @returns The value of `map[key]`. If the value is undefined, an error is logged.
 *
 * @example
 *    const details = getOrReport(detailsMap, key, 'Could not find details', {
 *      somethingImportantToLog
 *    });
 *
 *    if (!details) {
 *      return;
 *    }
 */
export function getOrReport<
  TKey extends string,
  TMap extends Record<TKey, any>
>(
  map: TMap,
  key: TKey,
  message: string = '',
  payload: LoggerPayload = {}
): TMap[TKey] | undefined {
  const value = map[key];

  if (value === undefined) {
    Logger.error(trim(`Unknown key '${key}'.  ${message}`), {
      ...payload,
      map,
      key
    });
  }

  return value;
}
