import { IPaginationInfo } from '$/models';
import { IInstalledLibraryCollection } from '$shared/services/installed-library-collection';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const installedLibraryCollectionsAdapter =
  createEntityAdapter<IInstalledLibraryCollection>();

export interface State extends EntityState<IInstalledLibraryCollection> {
  pagination: IPaginationInfo;
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State =
  installedLibraryCollectionsAdapter.getInitialState({
    pagination: {},
    loading: false,
    loaded: false,
    error: null
  });
