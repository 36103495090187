import { createFeatureSelector, createSelector } from '@ngrx/store';

import { keyBy } from 'lodash';
import {
  residentMedicalProfessionalsAdapter,
  State
} from './resident-medical-professionals.state';

// Selector Helpers

export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
export const getError = (state: State) => state.error;

// ResidentMedicalProfessionals State Selector
export const selectResidentMedicalProfessionalsState =
  createFeatureSelector<State>('residentMedicalProfessionals');

const entitySelectors = residentMedicalProfessionalsAdapter.getSelectors(
  selectResidentMedicalProfessionalsState
);

// Entity Selectors
export const selectAll = entitySelectors.selectAll;
export const selectEntities = entitySelectors.selectEntities;
export const selectIds = entitySelectors.selectIds;
export const selectTotal = entitySelectors.selectTotal;

// Extras Selectors
export const selectLoading = createSelector(
  selectResidentMedicalProfessionalsState,
  getLoading
);
export const selectLoaded = createSelector(
  selectResidentMedicalProfessionalsState,
  getLoaded
);
export const selectError = createSelector(
  selectResidentMedicalProfessionalsState,
  getError
);

export const selectAllByCompoundKey = createSelector(
  selectAll,
  (residentMedicalProfessionals) => {
    return keyBy(
      residentMedicalProfessionals,
      (rmp) => `${rmp.residentId}-${rmp.medicalProfessionalId}`
    );
  }
);
