import { getPaginationData, getRecords } from '$/app/utils';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  ActivitiesApiActions,
  ActivitiesGeneralActions,
  ActivitiesWsActions
} from './activities.actions';
import {
  ActivityPageQueries,
  activitiesAdapter,
  initialState
} from './activities.state';
import { ActivitiesListPageActions } from '$/app/pages/facilities/facility-detail/activities-list/activities-list.actions';
import { ActivitiesFormModalActions } from '$/app/shared/pages/forms/activities-form/activities-form.actions';

export const activitiesFeature = createFeature({
  name: 'activities',
  reducer: createReducer(
    initialState,

    on(
      ActivitiesListPageActions.loadActivities,
      ActivitiesListPageActions.deleteActivity,

      ActivitiesFormModalActions.createActivity,
      ActivitiesFormModalActions.updateActivity,
      (state) => {
        return { ...state, loading: true, error: null };
      }
    ),

    on(ActivitiesApiActions.loadActivitiesSuccess, (state, action) => {
      const queryType = action.metaData?.pageQueryType;
      const pagination = getPaginationData<ActivityPageQueries>(
        state,
        action.activities,
        queryType
      );
      const data = getRecords(action.activities);

      return activitiesAdapter.setAll(data, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(ActivitiesApiActions.getActivitySuccess, (state, action) => {
      const queryType = action.metaData?.pageQueryType;
      const pagination = getPaginationData<ActivityPageQueries>(
        state,
        action.activities,
        queryType
      );
      const data = getRecords(action.activities);

      return activitiesAdapter.upsertMany(data, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(ActivitiesApiActions.createActivitySuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(ActivitiesApiActions.updateActivitySuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(ActivitiesApiActions.deleteActivitySuccess, (state, action) =>
      activitiesAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        error: null
      })
    ),
    on(
      ActivitiesApiActions.createActivityFail,
      ActivitiesApiActions.getActivityFail,
      ActivitiesApiActions.createActivityFail,
      ActivitiesApiActions.updateActivityFail,
      ActivitiesApiActions.deleteActivityFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),
    on(
      ActivitiesWsActions.activitiesCreated,
      ActivitiesWsActions.activitiesPatched,
      (state, action) => {
        return activitiesAdapter.upsertOne(action.activity, state);
      }
    ),
    on(ActivitiesWsActions.activitiesRemoved, (state, action) =>
      activitiesAdapter.removeOne(action.id, state)
    ),
    on(ActivitiesGeneralActions.addActivities, (state, action) => {
      return activitiesAdapter.upsertMany(action.activities, {
        ...state,
        loading: false,
        error: null
      });
    }),
    on(ActivitiesGeneralActions.clearActivities, () => {
      return activitiesAdapter.removeAll({
        ...initialState
      });
    })
  )
});
