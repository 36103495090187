import { doseTypeIds } from '../lookups/dose-types.lookup';
import { logTypeIds } from '../lookups/log-details.lookup';
import { TypeBox } from '../type-box';
import { comparisonOperators } from '../utils';
import { ILogDependency } from './log-dependency';
import { logDependencyRuleActionTypes } from './log-dependency-rule-action.model';

export const valueDoseSchema = TypeBox.StrictObject({
  value: TypeBox.String()
});

export type IValueDose = TypeBox.Infer<typeof valueDoseSchema>;

export const rangedDoseSchema = TypeBox.StrictObject({
  min: TypeBox.String(),
  max: TypeBox.String()
});

export type IRangedDose = TypeBox.Infer<typeof rangedDoseSchema>;

export class RangedDose implements IRangedDose {
  min: string;
  max: string;

  constructor(min: string, max: string) {
    this.min = min;
    this.max = max;
  }
}

export const optionDoseSchema = TypeBox.Array(TypeBox.String());

export type TOptionDose = TypeBox.Infer<typeof optionDoseSchema>;

export type NormalDoseValue = IValueDose | IRangedDose | TOptionDose;

export const logDependencySchema = TypeBox.StrictObject({
  type: TypeBox.Enum(logTypeIds),
  rules: TypeBox.Array(
    TypeBox.StrictObject({
      conditions: TypeBox.Array(
        TypeBox.StrictObject({
          field: TypeBox.String(),
          operator: TypeBox.Enum(comparisonOperators),
          value: TypeBox.Union([
            TypeBox.String(),
            TypeBox.Number(),
            TypeBox.Boolean()
          ])
        })
      ),
      action: TypeBox.StrictObject({
        type: TypeBox.Enum(logDependencyRuleActionTypes),
        doseTypeId: TypeBox.Enum(doseTypeIds),
        dose: TypeBox.Union([
          valueDoseSchema,
          rangedDoseSchema,
          optionDoseSchema
        ])
      })
    })
  )
});

// export type DoseValue = IValueDose | RangedDose | TOptionDose | ILogDependency;
export const doseValueSchema = TypeBox.Union([
  valueDoseSchema,
  rangedDoseSchema,
  optionDoseSchema,
  logDependencySchema
]);

export type DoseValue = TypeBox.Infer<typeof doseValueSchema>;

export function isValueDose(dose: DoseValue): dose is IValueDose {
  return !!(dose && 'value' in dose);
}

export function isRangedDose(dose: DoseValue): dose is RangedDose {
  return !!(dose && 'min' in dose && 'max' in dose);
}

export function isOptionDose(dose: DoseValue): dose is TOptionDose {
  return Array.isArray(dose);
}

export function isLogDependency(
  dose: DoseValue | ILogDependency
): dose is ILogDependency {
  return !!(dose && 'type' in dose);
}

export function isNormalDoseValue(dose: DoseValue): dose is NormalDoseValue {
  return !!(
    dose &&
    ('value' in dose || Array.isArray(dose) || ('min' in dose && 'max' in dose))
  );
}
