import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noop',
  standalone: true
})
export class AlcNoopPipe implements PipeTransform {
  transform(value: any): any {
    return value;
  }
}
