import { MedicationsSelectors } from '$/app/store/medications/medications.selectors';
import { inventorySorter } from '$/app/utils';
import { IMedicationInventoryItem } from '$shared/medication-inventory-items';
import { IMedication } from '$shared/medications';
import { Dictionary } from '$shared/types';
import { chainFlow } from '$shared/utils';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  filter,
  flatMap,
  groupBy,
  keyBy,
  map,
  mapValues,
  reduce
} from 'lodash';
import {
  State,
  medicationInventoryItemsAdapter
} from './medication-inventory-items.state';
import { ResidentsSelectors } from '../residents/residents.selectors';
import { FacilitySettingsSelectors } from '../facility-settings';

// Selector Helpers
export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
export const getError = (state: State) => state.error;

// Medication Inventory Items State Selector
export const selectMedicationInventoryItemsState = createFeatureSelector<State>(
  'medicationInventoryItems'
);

const entitySelectors = medicationInventoryItemsAdapter.getSelectors(
  selectMedicationInventoryItemsState
);

// Entity Selectors
export const selectAll = entitySelectors.selectAll;
export const selectEntities = entitySelectors.selectEntities;
export const selectIds = entitySelectors.selectIds;
export const selectTotal = entitySelectors.selectTotal;

// Extras Selectors
export const selectLoading = createSelector(
  selectMedicationInventoryItemsState,
  getLoading
);
export const selectLoaded = createSelector(
  selectMedicationInventoryItemsState,
  getLoaded
);
export const selectError = createSelector(
  selectMedicationInventoryItemsState,
  getError
);

// Other Selectors

/**
 * Select the non-empty inventory items of a medication batch
 */
export const selectNonEmptyItems = createSelector(
  selectAll,
  (inventoryItems: IMedicationInventoryItem[]): IMedicationInventoryItem[] =>
    inventoryItems.filter((item) => !item.emptyOn)
);

/**
 * Select the non-empty inventory items of a medication batch
 */
export const selectBatchInventoryItems = createSelector(
  MedicationsSelectors.selectMedication,
  selectAll,
  (
    medication: IMedication,
    inventoryItems: IMedicationInventoryItem[]
  ): IMedicationInventoryItem[] =>
    filter(
      inventoryItems,
      (item) => item.medicationBatchId === medication?.batchId
    )
);

/**
 * Select the non-empty inventory items of a medication batch
 */
export const selectNonEmptyInventoryItems = createSelector(
  selectBatchInventoryItems,
  (inventoryItems: IMedicationInventoryItem[]): IMedicationInventoryItem[] =>
    inventoryItems.filter((item) => !item?.emptyOn)
);

export const selectMedicationInventoryItemWithId = (
  medicationInventoryItemId: string
) =>
  createSelector(
    selectEntities,
    (inventoryItemEntities) => inventoryItemEntities[medicationInventoryItemId]
  );

/**
 * Returns an array of non-empty inventory items for a specific medicationBatchId (passed as a prop)
 */
export function selectInventoryItemsWithMedicationBatchId(
  medicationBatchId?: string
) {
  return createSelector(
    selectAll,
    (
      inventoryItems: IMedicationInventoryItem[]
    ): IMedicationInventoryItem[] => {
      if (!medicationBatchId) {
        throw new Error(
          `${selectInventoryItemsWithMedicationBatchId.name} selector requires medicationId`
        );
      }

      return inventoryItems.filter((item) => {
        return (
          item.medicationBatchId === medicationBatchId &&
          (!item.emptyOn || item.isCurrent)
        );
      });
    }
  );
}

export const selectLastMedicationInventoryItem = (
  medicationBatchId: string
) => {
  return createSelector(
    selectInventoryItemsWithMedicationBatchId(medicationBatchId),
    (inventoryItems: IMedicationInventoryItem[]): IMedicationInventoryItem => {
      return inventoryItems[inventoryItems.length - 1];
    }
  );
};

// Creates a dictionary containing the non empty inventory items of a batch.
export const selectAllGroupedByMedicationBatchId = createSelector(
  selectAll,
  (inventoryItems): Dictionary<IMedicationInventoryItem[]> =>
    chainFlow(
      inventoryItems,
      (items) => filter(items, (i) => !i.emptyOn),
      (items) => groupBy(items, 'medicationBatchId'),
      (itemDict) => mapValues(itemDict, (items) => inventorySorter(items).asc())
    )
);

// Creates a dictionary containing the non empty inventory items of a batch.
export const selectAvailableAndAllInventory = createSelector(
  selectAll,
  (
    inventoryItems
  ): Dictionary<{
    available: IMedicationInventoryItem[];
    all: IMedicationInventoryItem[];
  }> =>
    chainFlow(
      inventoryItems,
      (items) => groupBy(items, 'medicationBatchId'),
      (itemsDic) =>
        mapValues(itemsDic, (items) => inventorySorter(items).asc()),
      (itemDict) =>
        mapValues(
          itemDict,
          (
            items
          ): {
            available: IMedicationInventoryItem[];
            all: IMedicationInventoryItem[];
          } => {
            return reduce(
              items,
              (acc, item) => {
                if (!item.emptyOn) {
                  acc.available.push(item);
                }

                acc.all.push(item);

                return acc;
              },
              {
                available: [],
                all: []
              }
            );
          }
        )
    )
);

export const selectCurrentGroupedByMedicationBatchId = createSelector(
  selectAll,
  (inventoryItems): Dictionary<IMedicationInventoryItem> =>
    chainFlow(
      inventoryItems,
      (items) => filter(items, (i) => i.isCurrent),
      (items) => keyBy(items, 'medicationBatchId')
    )
);

export const expiredMedInventoryItemsGroupedByResidents = createSelector(
  selectAll,
  MedicationsSelectors.selectAll,
  ResidentsSelectors.selectEntities,
  FacilitySettingsSelectors.selectFacilityTime,
  (inventoryItems, medications, residents, ft) => {
    const expiredInventoryItems = inventoryItems.filter(
      (item) =>
        ft.createDateTime() >= ft.createDateTime(item.expireDate) &&
        item.emptyOn === null
    );

    return medInventoryItemsGroupedByResidents(
      expiredInventoryItems,
      medications,
      residents
    );
  }
);

export const expiringMedInventoryItemsGroupedByResidents = createSelector(
  selectAll,
  MedicationsSelectors.selectAll,
  ResidentsSelectors.selectEntities,
  FacilitySettingsSelectors.selectFacilityTime,
  (inventoryItems, medications, residents, ft) => {
    const weekFromNow = ft.createDateTime().plus({ days: 7 });

    const expiringInventoryItems = inventoryItems.filter(
      (item) =>
        ft.createDateTime(item.expireDate) >= ft.createDateTime() &&
        ft.createDateTime(item.expireDate) <= weekFromNow &&
        item.emptyOn === null
    );

    return medInventoryItemsGroupedByResidents(
      expiringInventoryItems,
      medications,
      residents
    );
  }
);

const medInventoryItemsGroupedByResidents = (
  inventoryItems,
  medications,
  residents
) => {
  const activeResidents = filter(residents, { statusId: 'active' });

  return map(activeResidents, (resident) => {
    const residentMedications = filter(medications, {
      residentId: resident.id
    });

    const residentInventoryItems = flatMap(
      residentMedications,
      (medication) => {
        return map(
          filter(inventoryItems, { medicationId: medication.id }),
          (inventoryItem) => {
            return {
              ...inventoryItem,
              medication
            };
          }
        );
      }
    );

    if (!residentInventoryItems.length) {
      return null;
    }

    return {
      ...resident,
      inventoryItems: residentInventoryItems
    };
  }).filter(Boolean);
};
