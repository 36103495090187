import { AlcInventoryItemFormModal } from '$/app/pages/medications/shared/components/inventory-item-form/inventory-item-form.modal';
import { OverlayService } from '$/app/services';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, tap } from 'rxjs/operators';
import { InventoryItemFormActions } from '../../../shared/components/inventory-item-form/inventory-item-form.modal.actions';
import { InventoryItemDetailPageActions } from '../../inventory-item-detail/inventory-item-detail.actions';
import { MedicationDetailInventoryPageActions } from './medication-detail-inventory.actions';

@Injectable()
export class MedicationDetailInventoryEffects {
  private readonly actions$ = inject(Actions);
  private readonly overlay = inject(OverlayService);

  openInventoryItemForm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MedicationDetailInventoryPageActions.openInventoryItemForm,
        InventoryItemDetailPageActions.openInventoryItemForm
      ),
      tap(async (action) => {
        await this.overlay.showModal({
          component: AlcInventoryItemFormModal,
          componentProps: {
            resident: action.resident,
            medication: action.medication,
            medicationOrderItem: action.medicationOrderItem,
            medicationInventoryItem: action.medicationInventoryItem
          }
        });
      }),
      mergeMap((action) => {
        return [
          InventoryItemFormActions.loadPharmacies(),
          InventoryItemFormActions.getResidentMedicalProfessionals({
            residentId: action.resident.id
          })
        ];
      })
    );
  });
}
