import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ActivityLogsEffects } from './activity-logs.effects';
import { activityLogsFeature } from './activity-logs.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(activityLogsFeature),
    EffectsModule.forFeature([ActivityLogsEffects])
  ],
  providers: [ActivityLogsEffects]
})
export class ActivityLogsStoreModule {}
