import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash';

@Pipe({
  name: 'parenthesize',
  standalone: true
})
export class AlcParenthesizePipe implements PipeTransform {
  transform(
    value: any,
    { prefix = '', suffix = '' }: { prefix?: string; suffix?: string } = {}
  ) {
    return !isNil(value) ? `(${prefix}${value}${suffix})` : value;
  }
}
