import { CreateDocumentFromTemplatePageActions } from '$/app/pages/documents/create-document-from-template/create-document-from-template.actions';
import { DocumentTemplateDetailsPageActions } from '$/app/pages/documents/document-template-details/document-template-details.actions';
import { DocumentTemplateFormPageActions } from '$/app/pages/documents/document-template-form/document-template-form.actions';
import { DocumentTrackingPageActions } from '$/app/pages/documents/document-tracking/document-tracking.actions';
import { FacilityLibraryPageActions } from '$/app/pages/documents/facility-document-library/facility-document-library.actions';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  DocumentTemplatesApiActions,
  DocumentTemplatesGeneralActions,
  DocumentTemplatesWsActions
} from './document-templates.actions';
import {
  documentTemplatesAdapter,
  initialState
} from './document-templates.state';

export const documentTemplatesFeature = createFeature({
  name: 'documentTemplates',
  reducer: createReducer(
    initialState,
    on(
      DocumentTemplateFormPageActions.createDocumentTemplate,
      DocumentTemplateFormPageActions.updateDocumentTemplate,
      DocumentTrackingPageActions.getDocumentTemplates,
      CreateDocumentFromTemplatePageActions.getDocumentTemplates,
      DocumentTemplateDetailsPageActions.deleteDocumentTemplate,
      FacilityLibraryPageActions.moveDocumentsToTemplate,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),
    // [API] Load Success
    on(
      DocumentTemplatesApiActions.loadDocumentTemplatesSuccess,
      (state, action) => {
        const {
          documentTemplates: { data: records, ...pagination }
        } = action;

        return documentTemplatesAdapter.setAll(records, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      DocumentTemplatesApiActions.getDocumentTemplatesSuccess,
      (state, action) => {
        const {
          documentTemplates: { data: records, ...pagination }
        } = action;

        return documentTemplatesAdapter.upsertMany(records, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      DocumentTemplatesApiActions.fetchDocumentTemplateSuccess,
      (state, action) => {
        return documentTemplatesAdapter.upsertOne(action.documentTemplate, {
          ...state,
          loading: false,
          loaded: false,
          error: null
        });
      }
    ),

    on(
      DocumentTemplatesApiActions.createDocumentTemplateSuccess,
      (state, action) => {
        return documentTemplatesAdapter.addOne(action.documentTemplate, {
          ...state,
          loading: false,
          error: null
        });
      }
    ),

    on(
      DocumentTemplatesApiActions.updateDocumentTemplateSuccess,
      (state, action) => {
        return documentTemplatesAdapter.upsertOne(action.documentTemplate, {
          ...state,
          loading: false,
          error: null
        });
      }
    ),

    on(
      DocumentTemplatesApiActions.deleteDocumentTemplateSuccess,
      (state, action) =>
        documentTemplatesAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          error: null
        })
    ),

    on(
      DocumentTemplatesApiActions.loadDocumentTemplatesFail,
      DocumentTemplatesApiActions.getDocumentTemplatesFail,
      DocumentTemplatesApiActions.createDocumentTemplateFail,
      DocumentTemplatesApiActions.updateDocumentTemplateFail,
      DocumentTemplatesApiActions.deleteDocumentTemplateFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      DocumentTemplatesWsActions.documentTemplateCreated,
      DocumentTemplatesWsActions.documentTemplatePatched,
      (state, action) => {
        return documentTemplatesAdapter.upsertOne(
          action.documentTemplate,
          state
        );
      }
    ),

    on(DocumentTemplatesWsActions.documentTemplateRemoved, (state, action) => {
      return documentTemplatesAdapter.removeOne(action.id, state);
    }),

    on(
      DocumentTemplatesGeneralActions.addDocumentTemplates,
      (state, action) => {
        return documentTemplatesAdapter.upsertMany(action.documentTemplates, {
          ...state,
          loading: false,
          error: null
        });
      }
    ),

    on(DocumentTemplatesGeneralActions.clearDocumentTemplates, () => {
      return documentTemplatesAdapter.removeAll({
        ...initialState
      });
    })
  )
});
