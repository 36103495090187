import { ValidatorFn } from '@angular/forms';
import { digits } from './digits.validator';
import { divisibleBy } from './divisible-by.validator';
import { email } from './email.validator';
import { equal } from './equal.validator';
import { maxDateTime } from './max-datetime.validator';
import { maxDoseForPeriod } from './max-dose-for-period.validator';
import { maxDose } from './max-dose.validator';
import { medicationStrength } from './medication-strength.validator';
import { minDateTime } from './min-datetime.validator';
import { minDose } from './min-dose.validator';
import { notEmpty } from './not-empty.validator';
import { notZero } from './not-zero.validator';
import { phoneNumber } from './phone-number.validator';
import { satisfyDose } from './satisfy-dose.validator';
import { validDateRange } from './valid-date-range.validator';
import { weekdaysRequired } from './weekdays-required.validator';
import { digitsAndMedCharacters } from './digits-and-med-characters.validator';

type ValidatorFnFactory = (...args: any[]) => ValidatorFn;

export const AlcomyValidators = {
  digits,
  divisibleBy,
  equal,
  maxDateTime,
  maxDose,
  minDateTime,
  minDose,
  satisfyDose,
  maxDoseForPeriod,
  phoneNumber,
  email,
  notZero,
  validDateRange,
  weekdaysRequired,
  medicationStrength,
  notEmpty,
  digitsAndMedCharacters
} satisfies Record<string, ValidatorFn | ValidatorFnFactory>;
