import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { getPaginationData, getRecords } from '$/app/utils';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  ActivityLogsApiActions,
  ActivityLogsGeneralActions,
  ActivityLogsWsActions
} from './activity-logs.actions';
import {
  ActivityLogPageQueries,
  activityLogsAdapter,
  initialState
} from './activity-logs.state';

export const activityLogsFeature = createFeature({
  name: 'activityLogs',
  reducer: createReducer(
    initialState,

    on(
      // ActivityLogsPageActions.loadActivityLogs,
      // ActivityLogsPageActions.getActivityLogs,
      // ActivityLogFormPageActions.createActivityLog,
      // ActivityLogFormPageActions.updateActivityLog,
      // ActivityLogsPageActions.deleteActivityLog,
      ResidentMedicationTasksPageActions.deleteActivityLog,
      MissedMedicationsPageActions.deleteActivityLog,
      RoutineMarDetailPageActions.deleteActivityLog,
      RoutineMarListPageActions.deleteActivityLog,
      (state) => {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
    ),

    on(ActivityLogsApiActions.loadActivityLogsSuccess, (state, action) => {
      const queryType = action.metadata?.pageQueryType;
      const pagination = getPaginationData<ActivityLogPageQueries>(
        state,
        action.activityLogs,
        queryType
      );
      const data = getRecords(action.activityLogs);

      return activityLogsAdapter.setAll(data, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(ActivityLogsApiActions.getActivityLogsSuccess, (state, action) => {
      const queryType = action.metadata?.pageQueryType;
      const pagination = getPaginationData<ActivityLogPageQueries>(
        state,
        action.activityLogs,
        queryType
      );
      const data = getRecords(action.activityLogs);

      return activityLogsAdapter.upsertMany(data, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(ActivityLogsApiActions.createActivityLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(ActivityLogsApiActions.updateActivityLogSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(ActivityLogsApiActions.deleteActivityLogSuccess, (state, action) =>
      activityLogsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        error: null
      })
    ),

    on(
      ActivityLogsApiActions.loadActivityLogsFail,
      ActivityLogsApiActions.getActivityLogsFail,
      ActivityLogsApiActions.createActivityLogFail,
      ActivityLogsApiActions.updateActivityLogFail,
      ActivityLogsApiActions.deleteActivityLogFail,
      (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      })
    ),

    on(
      ActivityLogsWsActions.activityLogsCreated,
      ActivityLogsWsActions.activityLogsPatched,
      (state, action) => {
        return activityLogsAdapter.upsertOne(action.activityLog, state);
      }
    ),

    on(ActivityLogsWsActions.activityLogsRemoved, (state, action) => {
      return activityLogsAdapter.removeOne(action.id, state);
    }),

    on(ActivityLogsGeneralActions.addActivityLogs, (state, action) => {
      return activityLogsAdapter.upsertMany(action.activityLogs, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(ActivityLogsGeneralActions.clearActivityLogs, () => {
      return activityLogsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
