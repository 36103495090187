import { AlcAction, Pharmacy, Update } from '$/models';
import { IMedicalProfessional } from '$shared/services/medical-professional.schema';
import { createActionGroup, props } from '@ngrx/store';

export const FacilityContactsPageActions = createActionGroup({
  source: 'Facility Contacts Page',
  events: {
    'Archive Medical Professional': props<Update<Pharmacy>>(),

    'Fetch Facility': AlcAction<{ id: string }>(),

    'Delete Pharmacy': AlcAction<{ id: string }>(),
    'Merge Pharmacies': props<Update<Pharmacy>>(),
    'Merge Medical Professionals': props<Update<IMedicalProfessional>>()
  }
});
