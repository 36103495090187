import { MissedMedicationsPageActions } from '$/app/pages/medications/dashboard/missed-medications/missed-medications.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks/resident-medication-tasks.actions';
import { RoutineMedicationsPageActions } from '$/app/pages/medications/dashboard/routine-medications/routine-medications.actions';
import { RoutineMarDetailPageActions } from '$/app/pages/medications/residents/routine-mar-detail/routine-mar-detail.actions';
import { RoutineMarListPageActions } from '$/app/pages/medications/residents/routine-mar-list/routine-mar-list.actions';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  MedicationTasksApiActions,
  MedicationTasksGeneralActions,
  MedicationTasksWsActions
} from './medication-tasks.actions';
import { initialState, medicationTasksAdapter } from './medication-tasks.state';

export const medicationTasksFeature = createFeature({
  name: 'medicationTasks',
  reducer: createReducer(
    initialState,

    on(
      ResidentMedicationTasksPageActions.prepMedication,
      ResidentMedicationTasksPageActions.giveMedication,
      ResidentMedicationTasksPageActions.omitMedication,
      ResidentMedicationTasksPageActions.undoPrepMedication,
      ResidentMedicationTasksPageActions.undoGiveMedication,
      ResidentMedicationTasksPageActions.undoOmitMedication,

      ResidentMedicationTasksPageActions.prepAllMedications,
      ResidentMedicationTasksPageActions.giveAllMedications,
      ResidentMedicationTasksPageActions.omitAllMedications,
      ResidentMedicationTasksPageActions.undoPrepAllMedications,
      ResidentMedicationTasksPageActions.undoGiveAllMedications,
      ResidentMedicationTasksPageActions.undoOmitAllMedications,

      RoutineMedicationsPageActions.getMedicationTasks,
      ResidentMedicationTasksPageActions.getMedicationTasks,
      MissedMedicationsPageActions.loadMedicationTasks,
      RoutineMarListPageActions.loadMedicationTasks,
      RoutineMarListPageActions.getMedicationTasks,
      RoutineMarDetailPageActions.fetchMedicationTask,
      (state) => {
        return {
          ...state,
          loading: true,
          loaded: false,
          error: null
        };
      }
    ),

    on(
      MedicationTasksApiActions.loadMedicationTasksSuccess,
      (state, action) => {
        return medicationTasksAdapter.setAll(action.medicationTasks.data, {
          ...state,
          total: action.medicationTasks.total,
          skip: action.medicationTasks.skip,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(MedicationTasksApiActions.getMedicationTasksSuccess, (state, action) => {
      return medicationTasksAdapter.upsertMany(action.medicationTasks.data, {
        ...state,
        total: action.medicationTasks.total,
        skip: action.medicationTasks.skip,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(
      MedicationTasksApiActions.fetchMedicationTaskSuccess,
      (state, action) => {
        return medicationTasksAdapter.upsertOne(action.medicationTask, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(MedicationTasksApiActions.createMedicationTaskSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(
      MedicationTasksApiActions.updateMedicationTaskSuccess,
      (state, action) => {
        return medicationTasksAdapter.updateOne(action.medicationTask, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),
    on(
      MedicationTasksApiActions.updateMultipleMedicationTasksSuccess,
      (state, action) => {
        return medicationTasksAdapter.updateMany(action.medicationTasks, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      MedicationTasksApiActions.deleteMedicationTaskSuccess,
      (state, action) => {
        return medicationTasksAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      MedicationTasksApiActions.loadMedicationTasksFail,
      MedicationTasksApiActions.getMedicationTasksFail,
      MedicationTasksApiActions.fetchMedicationTaskFail,
      MedicationTasksApiActions.createMedicationTaskFail,
      MedicationTasksApiActions.updateMedicationTaskFail,
      MedicationTasksApiActions.updateMultipleMedicationTasksFail,
      MedicationTasksApiActions.deleteMedicationTaskFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      MedicationTasksWsActions.medicationTasksCreated,
      MedicationTasksWsActions.medicationTasksPatched,
      (state, action) => {
        return medicationTasksAdapter.upsertOne(action.medicationTask, {
          ...state
        });
      }
    ),

    on(
      MedicationTasksWsActions.medicationTasksBatchCreated,
      MedicationTasksWsActions.medicationTasksBatchPatched,
      (state, action) => {
        return medicationTasksAdapter.upsertMany(action.medicationTasks, {
          ...state
        });
      }
    ),

    on(MedicationTasksWsActions.medicationTasksRemoved, (state, action) => {
      return medicationTasksAdapter.removeOne(action.id, {
        ...state
      });
    }),

    on(
      MedicationTasksWsActions.medicationTasksBatchRemoved,
      (state, action) => {
        return medicationTasksAdapter.removeMany(action.ids, {
          ...state
        });
      }
    ),

    on(MedicationTasksGeneralActions.addMedicationTasks, (state, action) => {
      return medicationTasksAdapter.upsertMany(action.medicationTasks, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(MedicationTasksGeneralActions.clearMedicationTasks, (state) => {
      return medicationTasksAdapter.removeAll({
        ...state,
        total: 0,
        skip: 0,
        loading: false,
        loaded: false,
        error: null
      });
    })
  )
});
