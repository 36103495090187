import { OverlayService } from '$/app/services';
import { expansionAnimation } from '$/app/shared/animations/animations';
import { SharedModule } from '$/app/shared/shared.module';
import { TypedFormControls } from '$/app/shared/types/reactive-forms';
import { AlcomyValidators } from '$/app/shared/validators';
import { validateForm } from '$/app/utils';
import { Pharmacy, PharmacyPhone, ValidationError } from '$/models';
import { Logger } from '$shared/logger';
import { lookups } from '$shared/lookups';
import { Component, Input, OnInit, inject } from '@angular/core';
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Store } from '@ngrx/store';
import * as PharmacyFormActions from './pharmacy-form.actions';

@Component({
  selector: 'alc-pharmacy-form',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './pharmacy-form.modal.html',
  animations: [expansionAnimation]
})
export class AlcPharmacyFormModal implements OnInit {
  private readonly store = inject(Store);
  private readonly fb = inject(UntypedFormBuilder);
  private readonly overlay = inject(OverlayService);

  @Input() theme = 'organization';
  @Input() pharmacy: Pharmacy;
  @Input() phones: PharmacyPhone[];

  protected readonly lookups = lookups;
  protected form: UntypedFormGroup;

  protected get formPhones() {
    return this.form.get('phones') as UntypedFormArray;
  }

  protected phoneFormGroup(index: number) {
    return this.formPhones.at(index) as FormGroup<
      TypedFormControls<{
        number: string;
        label: string;
        type: string;
      }>
    >;
  }

  ngOnInit() {
    this.createForm();
  }

  protected async close() {
    await this.overlay.dismissModal();
  }

  protected addPhone(ev: Event) {
    this.formPhones.push(this.newPhone());

    setTimeout(() => {
      ev.target['scrollIntoView']({
        behavior: 'smooth',
        block: 'center'
      });
    }, 100);
  }

  protected removePhone(index: number) {
    this.formPhones.removeAt(index);
  }

  protected submit() {
    try {
      const formValue = validateForm<Pharmacy>(this.form);

      // Force email to be null if empty string
      formValue.email = !formValue.email
        ? null
        : formValue.email.trim().toLowerCase();

      if (!this.pharmacy) {
        this.store.dispatch(
          PharmacyFormActions.createPharmacy({ pharmacy: formValue })
        );
      } else {
        this.store.dispatch(
          PharmacyFormActions.updatePharmacy(this.pharmacy.id, formValue, {
            loading: {
              message: 'Updating pharmacy...'
            },
            toast: {
              message: 'Pharmacy updated successfully!'
            },
            onSuccess: () => {
              this.close();
            }
          })
        );
      }
    } catch (error) {
      Logger.error('Error validating pharmacy form', {
        component: AlcPharmacyFormModal.name,
        formData: this.form.getRawValue(),
        error
      });

      if (error instanceof ValidationError) {
        this.overlay.showAlert(error.message, 'Form fields invalid');
      } else {
        throw error;
      }
    }
  }

  private createForm() {
    this.form = this.fb.group({
      name: [this.pharmacy?.name ?? null, Validators.required],
      isPrimary: [this.pharmacy?.isPrimary ?? false],
      address1: [this.pharmacy?.address1 ?? null],
      address2: [this.pharmacy?.address2 ?? null],
      city: [this.pharmacy?.city ?? null],
      state: [this.pharmacy?.state ?? null],
      postalCode: [
        this.pharmacy?.postalCode ?? null,
        [
          AlcomyValidators.digits,
          Validators.minLength(5),
          Validators.maxLength(5)
        ]
      ],
      country: [{ value: this.pharmacy?.country ?? 'US', disabled: true }],
      email: [this.pharmacy?.email ?? null, AlcomyValidators.email],
      orderingMethod: [
        this.pharmacy?.orderingMethod ?? 'fax',
        Validators.required
      ],
      softwareVendorId: [this.pharmacy?.softwareVendorId ?? null],
      storeId: [this.pharmacy?.storeId ?? null]
    });

    this.form.addControl(
      'phones',
      this.fb.array(
        this.phones?.length
          ? this.phones.map((phone) => {
              return this.fb.group({
                number: [
                  phone.number,
                  [Validators.required, AlcomyValidators.phoneNumber]
                ],
                label: [phone.label, Validators.maxLength(80)],
                type: [phone.type, Validators.required]
              });
            })
          : [this.newPhone()]
      )
    );
  }

  private newPhone() {
    return this.fb.group({
      number: [null, [Validators.required, AlcomyValidators.phoneNumber]],
      label: [null],
      type: ['work', Validators.required]
    });
  }
}
