import { OverlayService } from '$/app/services/ui';
import { environment } from '$/environments/environment';
import { Params } from '$/models';
import { Logger } from '$shared/logger';
import { MaybePromise } from '$shared/types/utility-types';
import { isPromise } from '$shared/utils/predicates/is-promise';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  LoadingController,
  PopoverController
} from '@ionic/angular/standalone';
import { LoadingOptions } from '@ionic/core';
import { Action } from '@ngrx/store';
import { castArray, identity } from 'lodash';
import {
  EMPTY,
  Observable,
  catchError,
  concatMap,
  delayWhen,
  exhaustMap,
  from,
  mergeMap,
  of,
  switchMap
} from 'rxjs';

interface ActionWithParams extends Action {
  params?: Params;
}

type UseMapOperator = 'switchMap' | 'exhaustMap' | 'concatMap' | 'mergeMap';

interface ApiRequestParams<TAction, TModifiedAction, TData> {
  description: string;
  useMapOperator?: UseMapOperator;
  transformAction?: (action: TAction) => MaybePromise<TModifiedAction>;
  onRequest: (action: TModifiedAction) => Observable<TData> | Promise<TData>;
  onSuccess?: (data: TData) => any;
  onError?: (error: Error) => any;
}

@Injectable({ providedIn: 'root' })
export class EffectHelpersService {
  private readonly popCtrl = inject(PopoverController);
  private readonly loadingController = inject(LoadingController);
  private readonly overlay = inject(OverlayService);
  private readonly router = inject(Router);

  onModalFormSubmitSuccess(message?: string) {
    return async (data?: any) => {
      await this.dismissModal({ message, data });
    };
  }

  apiRequest<
    TAction extends ActionWithParams,
    TModifiedAction extends TAction,
    TData
  >({
    description,
    useMapOperator,
    transformAction,
    onRequest,
    onSuccess,
    onError
  }: ApiRequestParams<TAction, TModifiedAction, TData>) {
    transformAction = transformAction ?? identity;

    const mapOperator = getMapOperator(useMapOperator);

    return (obs: Observable<TAction>) =>
      obs.pipe(
        delayWhen((action) => this.handleParamsOnRequest(action.params)),
        switchMap((action) => {
          // transformAction can return either a promise or a value
          // so we need to wrap it in a promise
          const func = async () => await transformAction(action);

          return from(func());
        }),
        mapOperator((action) => {
          action.params = action.params ?? {};
          action.params.userAction = action.type;
          const result = onRequest(action);

          const observable: Observable<TData> = isPromise(result)
            ? from(result)
            : result;

          return observable.pipe(
            mergeMap((data) => {
              this.handleParamsOnSuccess(action.params, data).catch((error) => {
                Logger.error(
                  `Error while calling handleParamsOnSuccess for ${description}`,
                  { action, error }
                );
              });

              const result = onSuccess ? onSuccess(data) : [];

              return castArray(result);
            }),
            catchError((error: Error) => {
              Logger.error(`Error during ${description}`, {
                action,
                error
              });

              this.handleParamsOnFail(action.params, {
                error,
                ...(action.params?.errorMessages ?? {})
              });

              return onError ? of(onError(error)) : EMPTY;
            })
          );
        })
      );
  }

  async handleParamsOnRequest(params: Params = {}) {
    const { loading } = params;

    if (!loading) {
      return;
    }

    if (typeof loading === 'string') {
      await this.overlay.showLoading(loading);
    } else {
      await this.overlay.showLoading(loading.message, loading);
    }
  }

  async handleParamsOnSuccess(params: Params = {}, result?: any) {
    const { toast, refresher, loading, onSuccess, redirect, onComplete } =
      params;

    if (onSuccess) {
      await onSuccess(result);
    }

    if (redirect) {
      if (typeof redirect === 'string') {
        this.router.navigateByUrl(redirect);
      } else {
        const wait = redirect.wait ?? 0;

        setTimeout(() => {
          this.router.navigate(redirect.pathSegments, redirect.extras);
        }, wait);
      }
    }

    if (loading) {
      await this.overlay.hideLoading();
    }

    if (toast) {
      if (typeof toast === 'string') {
        await this.overlay.showToast('success', toast);
      } else {
        await this.overlay.showToast('success', toast.message);
      }
    }

    if (refresher) {
      refresher.complete();
    }

    if (onComplete) {
      await onComplete();
    }
  }

  async handleParamsOnFail(
    params: Params = {},
    { error, title, message, skip }: Params['errorMessages'] & { error: Error }
  ) {
    const { onError, onComplete, loading } = params;

    if (loading) {
      await this.overlay.hideLoading();
    }

    if (onError) {
      await onError(error);
    }

    const errorClass = (error as any)?.className;
    const isSilentError = errorClass === 'timeout' && environment.production;

    if (!skip && !isSilentError) {
      this.overlay.showErrorMessage(error, { title, message });
    }

    if (onComplete) {
      await onComplete();
    }
  }

  async dismissModal(options: { message?: string; data?: any }) {
    const overlay = await this.overlay.getTopModal();

    if (overlay) {
      await this.overlay.dismissModal(options?.data);
    }

    if (options?.message) {
      await this.overlay.showToast('success', options?.message);
    }
  }

  onPopFormSubmitSuccess(message?: string) {
    return async () => {
      const overlay = await this.popCtrl.getTop();
      if (overlay) {
        await this.popCtrl.dismiss();
      }

      if (message) {
        await this.overlay.showToast('success', message);
      }
    };
  }

  onFormSubmitFail(title?: string, message?: string) {
    return ({ error }) => {
      Logger.error('Error during form submit', { error });

      this.overlay.showErrorMessage(error, { title, message });
    };
  }

  showLoading(message = 'please wait...', options: LoadingOptions = {}) {
    return async () => {
      const loading = await this.loadingController.create({
        mode: 'md',
        showBackdrop: false,
        message,
        ...options
      });
      await loading.present();
    };
  }

  hideLoading() {
    return async () => {
      const loading = await this.loadingController.getTop();
      if (loading) {
        await loading.dismiss();
      }
    };
  }
}

// NOTE(2024-06-01): If rxjs 8 has been released, the eslint-disable-next-line comments below can probably be removed
function getMapOperator(useMapOperator: UseMapOperator) {
  switch (useMapOperator ?? 'switchMap') {
    case 'switchMap':
      return switchMap;

    case 'exhaustMap':
      return exhaustMap;

    case 'concatMap':
      return concatMap;

    case 'mergeMap':
      return mergeMap;

    default:
      Logger.throw('Invalid map operator', { useMapOperator });
  }
}
