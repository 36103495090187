import { Logger } from '$shared/logger';
import { MaybePromise } from '$shared/types/utility-types';
import { isFunction } from 'lodash';
import { AlcAlertOptions } from './#alerts';
import { OverlayService } from './index';

type ConfirmOptions = Omit<AlcAlertOptions, 'type'>;

type ConfirmKeywordOptions = Partial<Omit<AlcAlertOptions, 'type'>>;

/**
 * Display a confirmation alert where the user chooses Yes or No and return
 * the result as a boolean.
 */
export function confirm(
  this: OverlayService,
  msg: string,
  onConfirm?: () => MaybePromise<void>
): Promise<boolean>;

export function confirm(
  this: OverlayService,
  msg: string,
  options?: string | ConfirmOptions
): Promise<boolean>;

export function confirm(
  this: OverlayService,
  msg: string,
  onConfirm: () => MaybePromise<void>
): Promise<boolean>;

export function confirm(
  this: OverlayService,
  msg: string,
  options: string | ConfirmOptions,
  onConfirm: () => MaybePromise<void>
): Promise<boolean>;

export async function confirm(
  this: OverlayService,
  ...args: any[]
): Promise<boolean> {
  const {
    message,
    options,
    onConfirm
  }: {
    message: string;
    options?: ConfirmOptions;
    onConfirm?: () => MaybePromise<void>;
  } = isFunction(args[1])
    ? { message: args[0], options: {}, onConfirm: args[1] }
    : {
        message: args[0],
        options: args[1]
          ? typeof args[1] === 'string'
            ? { title: args[1] }
            : args[1]
          : {},
        onConfirm: args[2]
      };

  if (onConfirm && !isFunction(onConfirm)) {
    Logger.throw('callback is not a function');
  }

  const result = await this.showAlert(message, {
    type: 'confirm',
    title: options.title,
    subtitle: options.subtitle,
    cssClass: options.cssClass,
    backdropDismiss: options.backdropDismiss,
    primaryButtonText: options.primaryButtonText || 'Yes',
    secondaryButtonText: options.secondaryButtonText || 'No',
    danger: options.danger
  });

  if (result && onConfirm) {
    await onConfirm();
  }

  return result;
}

// Same as confirm but only opens the confirmation dialog when the condition is true
// If the condition is false and an onConfirm callback is provided, it will be called
export function confirmWhen(
  this: OverlayService,
  condition: boolean,
  message: string,
  onConfirm?: () => MaybePromise<void>
): Promise<boolean>;
export function confirmWhen(
  this: OverlayService,
  condition: boolean,
  message: string,
  options?: string | ConfirmOptions
): Promise<boolean>;
export async function confirmWhen(
  this: OverlayService,
  condition: boolean,
  message: string,
  options: string | ConfirmOptions,
  onConfirm: () => MaybePromise<void>
): Promise<boolean>;
export async function confirmWhen(
  this: OverlayService,
  condition: boolean,
  message: string,
  ...args: any[]
): Promise<boolean> {
  const {
    options,
    onConfirm
  }: {
    options?: ConfirmOptions;
    onConfirm?: () => MaybePromise<void>;
  } = isFunction(args[0])
    ? { options: {}, onConfirm: args[0] }
    : {
        options: args[0]
          ? typeof args[0] === 'string'
            ? { title: args[0] }
            : args[0]
          : {},
        onConfirm: args[1]
      };

  if (condition) {
    return await this.confirm(message, options, onConfirm);
  }

  if (onConfirm) {
    await onConfirm();
    return true;
  }
}

export async function confirmKeyword(
  this: OverlayService,
  message: string,
  keyword: string,
  headerOrOptions: string | ConfirmKeywordOptions,
  onConfirm?: () => MaybePromise<void>
): Promise<boolean> {
  let options: ConfirmKeywordOptions;

  if (typeof headerOrOptions === 'string') {
    options = { title: headerOrOptions };
  } else {
    options = headerOrOptions || {};
  }

  if (onConfirm && !isFunction(onConfirm)) {
    Logger.throw('callback is not a function');
  }

  const result = await this.showAlert(message, {
    type: 'confirm-keyword',
    keyword,
    title: options.title,
    subtitle: options.subtitle,
    cssClass: options.cssClass,
    backdropDismiss: options.backdropDismiss,
    primaryButtonText: options.primaryButtonText || 'Yes',
    secondaryButtonText: 'No',
    danger: options.danger || true
  });

  if (result && onConfirm) {
    await onConfirm();
  }

  return result;
}
