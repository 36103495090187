import { MedicationDestructionItemFormActions } from '$/app/pages/medications/dashboard/medication-destructions/medication-destruction-item-form/medication-destruction-item.actions';
import { NarcoticCountListPageActions } from '$/app/pages/medications/dashboard/narcotic-counts/narcotic-count-list/narcotic-count-list.actions';
import { ResidentMedicationTasksPageActions } from '$/app/pages/medications/dashboard/resident-medication-tasks';
import { MedicationDetailInventoryPageActions } from '$/app/pages/medications/residents/medication-detail/medication-detail-inventory/medication-detail-inventory.actions';
import { InventoryItemFormActions } from '$/app/pages/medications/shared/components/inventory-item-form/inventory-item-form.modal.actions';
import { InventoryPickerPageActions } from '$/app/shared/components/inventory-picker/inventory-picker.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  MedicationInventoryItemsApiActions,
  MedicationInventoryItemsGeneralActions
} from './actions';
import {
  State,
  initialState,
  medicationInventoryItemsAdapter
} from './medication-inventory-items.state';

const reducer = createReducer(
  initialState,

  on(
    InventoryPickerPageActions.changeInventoryItem,
    InventoryItemFormActions.createMedicationInventoryItem,
    MedicationDetailInventoryPageActions.markInventoryEmpty,
    ResidentMedicationTasksPageActions.markInventoryEmpty,
    NarcoticCountListPageActions.markInventoryEmpty,
    MedicationDetailInventoryPageActions.changeCurrentInventory,
    MedicationDestructionItemFormActions.loadInventoryItems,
    (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),

  on(
    MedicationInventoryItemsApiActions.loadMedicationInventoryItemsSuccess,
    (state, action) => {
      return medicationInventoryItemsAdapter.setAll(
        action.medicationInventoryItems.data,
        {
          ...state,

          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    MedicationInventoryItemsApiActions.getMedicationInventoryItemsSuccess,
    (state, action) => {
      return medicationInventoryItemsAdapter.upsertMany(
        action.medicationInventoryItems.data,
        {
          ...state,

          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    MedicationInventoryItemsApiActions.addInventoryItem_DashboardRoute,
    (state, action) => {
      const medicationInventoryItems = castArray(
        action.medicationInventoryItem
      );
      return medicationInventoryItemsAdapter.upsertMany(
        medicationInventoryItems,
        {
          ...state,
          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    MedicationInventoryItemsApiActions.fetchMedicationInventoryItemSuccess,
    (state, action) => {
      return medicationInventoryItemsAdapter.upsertOne(
        action.medicationInventoryItem,
        {
          ...state,
          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    MedicationInventoryItemsApiActions.createMedicationInventoryItemSuccess,
    (state, action) => {
      const medicationInventoryItems = castArray(
        action.medicationInventoryItem
      );

      return medicationInventoryItemsAdapter.upsertMany(
        medicationInventoryItems,
        {
          ...state,
          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    MedicationInventoryItemsApiActions.updateMedicationInventoryItemSuccess,
    (state, action) => {
      return medicationInventoryItemsAdapter.updateOne(
        action.medicationInventoryItem,
        {
          ...state,
          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    MedicationInventoryItemsApiActions.deleteMedicationInventoryItemSuccess,
    (state, action) => {
      return medicationInventoryItemsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    MedicationInventoryItemsApiActions.loadMedicationInventoryItemsFail,
    MedicationInventoryItemsApiActions.getMedicationInventoryItemsFail,
    MedicationInventoryItemsApiActions.fetchMedicationInventoryItemFail,
    MedicationInventoryItemsApiActions.createMedicationInventoryItemFail,
    MedicationInventoryItemsApiActions.updateMedicationInventoryItemFail,
    MedicationInventoryItemsApiActions.deleteMedicationInventoryItemFail,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
  ),

  on(
    MedicationInventoryItemsApiActions.medicationInventoryItemsCreated,
    MedicationInventoryItemsApiActions.medicationInventoryItemsPatched,
    (state, action) => {
      return medicationInventoryItemsAdapter.upsertOne(
        action.medicationInventoryItem,
        {
          ...state
        }
      );
    }
  ),

  on(
    MedicationInventoryItemsApiActions.medicationInventoryItemsRemoved,
    (state, action) => {
      return medicationInventoryItemsAdapter.removeOne(action.id, {
        ...state
      });
    }
  ),

  on(
    MedicationInventoryItemsGeneralActions.addMedicationInventoryItems,
    (state, action) => {
      return medicationInventoryItemsAdapter.upsertMany(
        action.medicationInventoryItems,
        {
          ...state,
          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    MedicationInventoryItemsGeneralActions.clearMedicationInventoryItems,
    (state) => {
      return medicationInventoryItemsAdapter.removeAll({
        ...state,

        loading: false,
        loaded: false,
        error: null
      });
    }
  )
);

export function medicationInventoryItemsReducer(
  state = initialState,
  action: Action
): State {
  return reducer(state, action);
}
