import { FacilityUserProfilePageActions } from '$/app/pages/staff/facility-user-details/facility-user-profile/facility-user-profile.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { castArray } from 'lodash';
import {
  FacilityUserPhoneDetailActions,
  FacilityUserPhoneFormActions,
  FacilityUserPhonesApiActions,
  FacilityUserPhonesGeneralActions,
  FacilityUserPhonesListActions,
  FacilityUserPhonesWsActions
} from './actions';
import {
  State,
  facilityUserPhonesAdapter,
  initialState
} from './facility-user-phones.state';

const reducer = createReducer(
  initialState,

  on(
    FacilityUserPhonesListActions.loadFacilityUserPhones,
    FacilityUserPhonesListActions.getFacilityUserPhones,
    FacilityUserPhoneDetailActions.fetchFacilityUserPhone,
    FacilityUserPhoneFormActions.addFacilityUserPhone,
    FacilityUserPhoneDetailActions.updateFacilityUserPhone,
    FacilityUserProfilePageActions.deleteFacilityUserPhone,
    (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),

  on(
    FacilityUserPhonesApiActions.loadFacilityUserPhonesSuccess,
    (state, action) => {
      return facilityUserPhonesAdapter.setAll(action.facilityUserPhones.data, {
        ...state,

        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    FacilityUserPhonesApiActions.getFacilityUserPhonesSuccess,
    (state, action) => {
      return facilityUserPhonesAdapter.upsertMany(
        action.facilityUserPhones.data,
        {
          ...state,

          loading: false,
          loaded: true,
          error: null
        }
      );
    }
  ),

  on(
    FacilityUserPhonesApiActions.fetchFacilityUserPhoneSuccess,
    (state, action) => {
      return facilityUserPhonesAdapter.upsertOne(action.facilityUserPhone, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    FacilityUserPhonesApiActions.addFacilityUserPhoneSuccess,
    (state, action) => {
      const facilityUserPhones = castArray(action.facilityUserPhone);

      return facilityUserPhonesAdapter.addMany(facilityUserPhones, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    FacilityUserPhonesApiActions.updateFacilityUserPhoneSuccess,
    (state, action) => {
      return facilityUserPhonesAdapter.updateOne(action.facilityUserPhone, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    FacilityUserPhonesApiActions.deleteFacilityUserPhoneSuccess,
    (state, action) => {
      return facilityUserPhonesAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(
    FacilityUserPhonesApiActions.loadFacilityUserPhonesFail,
    FacilityUserPhonesApiActions.getFacilityUserPhonesFail,
    FacilityUserPhonesApiActions.fetchFacilityUserPhoneFail,
    FacilityUserPhonesApiActions.addFacilityUserPhoneFail,
    FacilityUserPhonesApiActions.updateFacilityUserPhoneFail,
    FacilityUserPhonesApiActions.deleteFacilityUserPhoneFail,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
  ),

  on(
    FacilityUserPhonesWsActions.facilityUserPhonesCreated,
    FacilityUserPhonesWsActions.facilityUserPhonesPatched,
    (state, action) => {
      if (action.facilityUserPhone['changes']) {
        action.facilityUserPhone = action.facilityUserPhone['changes'];
      }
      return facilityUserPhonesAdapter.upsertOne(action.facilityUserPhone, {
        ...state
      });
    }
  ),

  on(FacilityUserPhonesWsActions.facilityUserPhonesRemoved, (state, action) => {
    return facilityUserPhonesAdapter.removeOne(action.id, {
      ...state
    });
  }),

  on(FacilityUserPhonesGeneralActions.clearFacilityUserPhones, (state) => {
    return facilityUserPhonesAdapter.removeAll({
      ...state,

      loading: false,
      loaded: false,
      error: null
    });
  }),

  on(
    FacilityUserPhonesGeneralActions.addFacilityUserPhones,
    (state, action) => {
      return facilityUserPhonesAdapter.upsertMany(action.facilityUserPhones, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  )
);

export function facilityUserPhonesReducer(
  state = initialState,
  action: Action
): State {
  return reducer(state, action);
}
