import { sortByFields } from '$/app/utils';
import { IMedication } from '$shared/medications';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const medicationsAdapter = createEntityAdapter<IMedication>({
  sortComparer: sortByFields('brandName', 'genericName')
});

export interface State extends EntityState<IMedication> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = medicationsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
