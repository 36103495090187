import {
  createLogsByCareTaskSelector,
  createLogsByMedicationTaskSelector,
  createLogsByPrnSelector
} from '$/app/store/shared/selectors/helpers/log-selector-helpers';
import { sifter } from '$/app/utils';
import { IActivityLog } from '$shared/services/activity-log.schema';
import { createSelector } from '@ngrx/store';
import { groupBy } from 'lodash';
import { Query } from 'sift';
import { activityLogsFeature } from './activity-logs.reducer';
import { activityLogsAdapter } from './activity-logs.state';

export const {
  selectActivityLogsState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = activityLogsFeature;

export const { selectAll, selectTotal } = activityLogsAdapter.getSelectors(
  selectActivityLogsState
);

export const selectPagination = (query) => {
  return createSelector(selectActivityLogsState, (state) => {
    return state.pagination[query];
  });
};

export const selectAllGroupedByBatchId = createSelector(selectAll, (logs) =>
  groupBy(logs, 'batchId')
);

export const selectLogsByMedicationTask =
  createLogsByMedicationTaskSelector(selectAll);

export const selectLogsByCareTask = createLogsByCareTaskSelector(selectAll);

export const selectLogsByPrn = createLogsByPrnSelector(selectAll);

export const selectLogsByQuery = (query: Query<IActivityLog>) => {
  return createSelector(selectAll, (logs) => {
    const filteredLogs = logs.filter(sifter<IActivityLog>(query));
    return filteredLogs;
  });
};

export const selectLogsAndMetrics = (query: Query<IActivityLog>) => {
  return createSelector(selectLogsByQuery(query), (logs) => {
    const computedMetrics = logs.reduce(
      (acc, _log) => {
        acc.count += 1;

        return acc;
      },
      {
        count: 0
      }
    );

    const metrics = [
      {
        type: 'value',
        label: 'Records',
        data: computedMetrics?.count || 0
      }
    ];

    return {
      metrics,
      logs
    };
  });
};
