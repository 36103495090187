<ion-header>
  <ion-toolbar color="danger">
    <h2 class="ion-text-wrap mx-0 my-4 px-4 text-lg">{{ title }}</h2>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div>
    @if (message) {
      <div
        class="text-dark"
        [ngClass]="{ 'whitespace-pre-line': !isHTML }"
        [innerHTML]="safeMessage"
      ></div>
    }
    @if (serverMessage) {
      <div class="mt-8 text-dark">
        <h3 class="m-0 mb-1 text-sm font-medium">Server Message</h3>
        <p class="m-0 whitespace-pre-line">
          {{ serverMessage }}
        </p>
      </div>
    }

    <p class="mt-8">
      <alc-contact-support />
    </p>

    @if (error?.data?.requestId) {
      <div class="text-dark">
        <h3 class="m-0 mb-1 text-sm font-medium">Request Info</h3>

        <div class="flex items-center justify-between">
          <div class="flex flex-col gap-1">
            <small class="block">{{ error.data.requestId }}</small>
            <small class="block">{{ extras?.email }}</small>
            <small class="block">
              {{ ft.createDateTime() | facilityDateTime: 'DEBUG_TIMESTAMP' }}
            </small>
            <small class="block">
              Alcomy v{{ extras?.version }} ({{ extras?.buildNumber }})
            </small>
            <small class="block">
              {{ extras?.deviceInfo?.name }} {{ extras?.deviceInfo?.model }} ({{
                extras?.deviceInfo?.operatingSystem
              }}
              v{{ extras?.deviceInfo.osVersion }})
            </small>
            <small class="block">
              {{ extras?.userAction }}
            </small>
          </div>

          <button mat-icon-button (click)="copy()">
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
      </div>
    }
  </div>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <div class="form-action-buttons">
      <ion-button shape="round" fill="solid" color="danger" (click)="close()">
        Ok
      </ion-button>
    </div>
  </ion-toolbar>
</ion-footer>
