import { AmountSegment } from '../types';
import { getMeasurementViaAlternateId } from './get-measurement-via-alternate-id';

/**
 * Takes a string amount and creates structured segments.
 *
 * ***
 * e.g.
 * '10mg/5ml' =>
 * [
 *    {amount: '10', measurement: 'mg'},
 *    '/',
 *    {amount: '5', measurement: 'ml'}
 * ]
 */
export function parseAmountString(amount: string): AmountSegment[] {
  if (!amount) {
    return [];
  }

  const segments = amount
    .toString()
    .toLowerCase()
    .replace(/\s*/g, '')
    .replace(/,*/g, '')
    .split(/(-|\/)/g)
    .filter(Boolean);

  const convertedSegments = segments.map((segment, index) => {
    if (segment === '-' || segment === '/') {
      return segment;
    }

    const amountTuple = /([.\d]+\.*\d*)?([a-z%]+)?/.exec(segment);

    if (amountTuple) {
      const amount =
        amountTuple[1]?.charAt(0) !== '.'
          ? amountTuple[1]
          : `0${amountTuple[1]}`;

      const unitOfMeasure = amountTuple[2];

      const measurement = getMeasurementViaAlternateId(unitOfMeasure)?.id;

      return {
        amount:
          index === segments.length - 1 && measurement && !amount
            ? '1'
            : amount,
        measurement
      };
    } else {
      return {
        amount: ''
      };
    }
  });

  while (typeof convertedSegments[convertedSegments.length - 1] === 'string') {
    convertedSegments.pop();
  }

  return convertedSegments;
}
