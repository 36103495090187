import { createFeature, createReducer, on } from '@ngrx/store';
import {
  ProposedChangesGeneralActions,
  ProposedChangesWsActions
} from './proposed-changes.actions';
import { initialState, proposedChangesAdapter } from './proposed-changes.state';

export const proposedChangesFeature = createFeature({
  name: 'proposedChanges',
  reducer: createReducer(
    initialState,

    on(
      ProposedChangesWsActions.proposedChangesCreated,
      ProposedChangesWsActions.proposedChangesPatched,
      (state, action) => {
        return proposedChangesAdapter.upsertOne(action.proposedChange, {
          ...state
        });
      }
    ),

    on(ProposedChangesWsActions.proposedChangesRemoved, (state, action) => {
      return proposedChangesAdapter.removeOne(action.id, {
        ...state
      });
    }),

    on(ProposedChangesGeneralActions.addProposedChanges, (state, action) => {
      return proposedChangesAdapter.upsertMany(action.proposedChanges, {
        ...state
      });
    }),

    on(ProposedChangesGeneralActions.clearProposedChanges, (state) => {
      return proposedChangesAdapter.removeAll({
        ...state,
        loading: false,
        loaded: false,
        error: null
      });
    })
  )
});
