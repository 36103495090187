import { OverlayService } from '$/app/services';
import { FileViewerService } from '$/app/services/ui/file-viewer.service';
import { AlcCalloutComponent } from '$/app/shared/components/callout/callout.component';
import { AlcContactSupportComponent } from '$/app/shared/components/contact-support.component';
import { SettingsValues } from '$/app/utils';
import { version } from '$shared/version';
import { Component, inject, Input } from '@angular/core';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar
} from '@ionic/angular/standalone';
import * as semver from 'semver';
import { expansionAnimation } from '../../animations/animations';
import { SharedModule } from '../../shared.module';
import { AlcReleaseFooterComponent } from './release-footer/release-footer.component';
import { AlcReleaseHighlightComponent } from './release-highlight/release-highlight.component';
import { AlcReleaseImageComponent } from './release-image/release-image.component';
import { AlcReleaseItemComponent } from './release-item/release-item.component';
import { AlcReleaseParagraphComponent } from './release-paragraph/release-paragraph.component';
import { AlcReleaseSectionComponent } from './release-section/release-section.component';

@Component({
  selector: 'alc-release-notes',
  standalone: true,
  imports: [
    IonTitle,
    IonContent,
    IonToolbar,
    IonHeader,
    SharedModule,
    AlcReleaseSectionComponent,
    AlcReleaseHighlightComponent,
    AlcReleaseItemComponent,
    AlcReleaseParagraphComponent,
    AlcReleaseImageComponent,
    AlcReleaseFooterComponent,
    AlcCalloutComponent,
    AlcContactSupportComponent
  ],
  templateUrl: './release-notes.page.html',
  styleUrls: ['./release-notes.page.scss'],
  animations: [expansionAnimation]
})
export class AlcReleaseNotesPage {
  private readonly overlay = inject(OverlayService);
  protected readonly fileViewer = inject(FileViewerService);

  @Input() trigger: 'automatic' | 'manual' = 'manual';

  expandEmailDisclaimer = false;

  static async showIfNewVersion(overlay: OverlayService) {
    const storedVersion = SettingsValues.RELEASE_NOTES_APP_VERSION;
    if (!storedVersion) {
      SettingsValues.RELEASE_NOTES_APP_VERSION = version;

      return;
    }

    const newerMinorVersion = semver.lt(
      `${semver.major(storedVersion)}.${semver.minor(storedVersion)}.0`,
      `${semver.major(version)}.${semver.minor(version)}.0`
    );

    if (!newerMinorVersion) {
      return;
    }

    await overlay.showModal({
      component: AlcReleaseNotesPage,
      componentProps: {
        trigger: 'automatic'
      },
      cssClass: 'lg-modal'
    });
  }

  protected dismiss() {
    this.overlay.dismissModal();
  }

  protected markRead() {
    SettingsValues.RELEASE_NOTES_APP_VERSION = version;

    this.overlay.dismissModal();
  }
}
