import { IMedicationSchedule } from '$shared/medication-schedules';
import { createAction, props } from '@ngrx/store';

export const clearMedicationSchedules = createAction(
  '[MedicationSchedules] Clear MedicationSchedules'
);

export const addMedicationSchedules = createAction(
  'Add Medication Schedules',
  props<{ medicationSchedules: IMedicationSchedule[] }>()
);
