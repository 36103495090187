import { FacilityUserProfilePageActions } from '$/app/pages/staff/facility-user-details/facility-user-profile/facility-user-profile.actions';
import { FacilityUserGroupFormActions } from '$/app/pages/staff/facility-user-group-form/facility-user-group-form.actions';
import { FacilityUserGroupMemberPickerActions } from '$/app/pages/staff/facility-user-group-member-picker/facility-user-group-member-picker.actions';
import { FacilityUserGroupPickerFormPageActions } from '$/app/pages/staff/facility-user-group-picker-form/facility-user-group-picker-form.actions';
import {
  EffectHelpersService,
  FacilityUserGroupMembersApiService
} from '$/app/services';
import { AnnouncementFormPageActions } from '$/app/shared/pages/forms/announcement-form/announcement-form.actions';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import { FacilityUserGroupMembersApiActions } from './actions';

@Injectable()
export class FacilityUserGroupMembersEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly facilityUserGroupMembersService = inject(
    FacilityUserGroupMembersApiService
  );
  private readonly effectHelpers = inject(EffectHelpersService);

  getFacilityUserGroupMembers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FacilityUserGroupMemberPickerActions.getFacilityUserGroupMembers,
        FacilityUserGroupFormActions.getFacilityUserGroupMembers,
        AnnouncementFormPageActions.loadFacilityUserGroupMembers
      ),
      switchMap((action) => {
        return this.facilityUserGroupMembersService.getAll(action.params).pipe(
          getAllPages(
            this.facilityUserGroupMembersService,
            action.params.query
          ),
          reducePaginatedResponses(),
          mergeMap((groupMembers) => {
            const responseData = new ApiData(
              'facilityUserGroupMembers',
              groupMembers
            );
            responseData.setPrimaryAction(
              FacilityUserGroupMembersApiActions.getFacilityUserGroupMembersSuccess
            );

            return responseData.getActions();
          }),
          catchError((error) => {
            return of(
              FacilityUserGroupMembersApiActions.getFacilityUserGroupMembersFail(
                { error }
              )
            );
          })
        );
      })
    );
  });

  createFacilityUserGroupMember$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          FacilityUserGroupMemberPickerActions.createFacilityUserGroupMember,
          FacilityUserGroupPickerFormPageActions.createFacilityUserGroupMember
        ),
        exhaustMap((action) => {
          return this.facilityUserGroupMembersService
            .create(action.facilityUserGroupMember, action.params)
            .pipe(
              catchError((error) => {
                this.store.dispatch(
                  FacilityUserGroupMembersApiActions.createFacilityUserGroupMemberFail(
                    {
                      error
                    }
                  )
                );
                return EMPTY;
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  createFacilityUserGroupMemberFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          FacilityUserGroupMembersApiActions.createFacilityUserGroupMemberFail
        ),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );

  deleteFacilityUserGroupMember$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          FacilityUserGroupMemberPickerActions.deleteFacilityUserGroupMember,
          FacilityUserProfilePageActions.deleteFacilityUserGroupMember,
          FacilityUserGroupPickerFormPageActions.deleteFacilityUserGroupMember
        ),
        exhaustMap((action) => {
          return this.facilityUserGroupMembersService.delete(action.id).pipe(
            catchError((error) => {
              this.store.dispatch(
                FacilityUserGroupMembersApiActions.deleteFacilityUserGroupMemberFail(
                  {
                    error
                  }
                )
              );
              return EMPTY;
            })
          );
        })
      );
    },
    { dispatch: false }
  );

  deleteFacilityUserGroupMemberFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          FacilityUserGroupMembersApiActions.deleteFacilityUserGroupMemberFail
        ),
        tap(this.effectHelpers.onFormSubmitFail())
      );
    },
    { dispatch: false }
  );
}
