import { ResidentMedicalProfessionalsSelectors } from '$/app/store/resident-medical-professionals';
import { ResidentsSelectors } from '$/app/store/residents/residents.selectors';
import * as RouterSelectors from '$/app/store/router/router.selectors';
import {
  MedicalProfessionalExtras,
  ResidentMedicalProfessional
} from '$/models';
import { IResident } from '$shared/residents/residents.interface';
import {
  IMedicalProfessional,
  IMedicalProfessionalWithPhones
} from '$shared/services/medical-professional.schema';
import { chainFlow } from '$shared/utils';
import { createSelector } from '@ngrx/store';
import { sort } from 'fast-sort';
import { filter, groupBy, isEmpty, keyBy, map, mapValues } from 'lodash';
import * as FacilitiesSelectors from '../facilities/facilities.selectors';
import { MedicalProfessionalPhonesSelectors } from '../medical-professional-phones';
import { medicalProfessionalsFeature } from './medical-professionals.reducer';
import { medicalProfessionalsAdapter } from './medical-professionals.state';

export const {
  selectEntities,
  selectLoading,
  selectLoaded,
  selectMedicalProfessionalsState
} = medicalProfessionalsFeature;

export const { selectAll } = medicalProfessionalsAdapter.getSelectors(
  selectMedicalProfessionalsState
);

export const selectAllVMs = createSelector(
  selectAll,
  MedicalProfessionalPhonesSelectors.selectAllGroupedByMedicalProfessionalId,
  (
    medicalProfessionals,
    phonesGroupedByMedicalProfessionalId
  ): IMedicalProfessionalWithPhones[] =>
    map(medicalProfessionals, (mp): IMedicalProfessionalWithPhones => {
      return {
        ...mp,
        phones: phonesGroupedByMedicalProfessionalId?.[mp.id] || []
      };
    })
);

export const selectViewModelsById = createSelector(
  selectAllVMs,
  (medicalProfessionals) => keyBy(medicalProfessionals, 'id')
);

export const selectMedicalProfessional = createSelector(
  RouterSelectors.selectParam('medicalProfessionalId'),
  selectEntities,
  (medProId, mpEntities) => {
    return mpEntities[medProId] || {};
  }
);

export const selectCurrentFacilityMedicalProfessionals = createSelector(
  FacilitiesSelectors.selectCurrentFacility,
  selectAll,
  (facility, medicalProfessionals) =>
    filter(medicalProfessionals, {
      facilityId: facility?.id,
      archivedAt: null
    })
);

export const selectFacilityMedicalProfessionals = createSelector(
  FacilitiesSelectors.selectFacility,
  selectAll,
  (facility, medicalProfessionals) => {
    if (isEmpty(facility) || isEmpty(medicalProfessionals)) {
      return [];
    }
    return medicalProfessionals.filter((mp) => {
      return mp.facilityId === facility.id;
    });
  }
);

export const selectResidentMedicalProfessionals = createSelector(
  ResidentsSelectors.selectResident,
  ResidentMedicalProfessionalsSelectors.selectAll,
  selectEntities,
  (
    resident,
    residentMPs,
    mpEntities
  ): (IMedicalProfessional & MedicalProfessionalExtras)[] => {
    return chainFlow(
      map(residentMPs, (rmp) => {
        const mp = mpEntities[rmp.medicalProfessionalId];

        if (!mp || rmp.residentId !== resident?.id) {
          return null;
        }

        return {
          ...mp,
          isPrimary: rmp.isPrimary,
          residentMedicalProfessionalId: rmp.id
        };
      }),
      (items) => filter(items)
    );
  }
);

export const selectMedicalProfessionalsWithResidentId = (residentId: string) =>
  createSelector(
    selectAll,
    ResidentMedicalProfessionalsSelectors.selectAllByCompoundKey,
    (
      medicalProfessionals,
      residentMedicalProfessionals
    ): {
      resident: IMedicalProfessional[];
      other: IMedicalProfessional[];
    } => {
      const groupedMedicalProfessionals = medicalProfessionals.reduce(
        (
          acc: {
            resident: IMedicalProfessional[];
            other: IMedicalProfessional[];
          },
          medicalProfessional
        ): {
          resident: IMedicalProfessional[];
          other: IMedicalProfessional[];
        } => {
          if (
            residentMedicalProfessionals[
              `${residentId}-${medicalProfessional.id}`
            ]
          ) {
            acc.resident.push(medicalProfessional);
          } else {
            acc.other.push(medicalProfessional);
          }

          return acc;
        },
        {
          resident: [],
          other: []
        }
      );

      groupedMedicalProfessionals.resident = sort(
        groupedMedicalProfessionals.resident
      ).asc(
        (mp) => `${mp.firstName?.toLowerCase()}${mp.lastName?.toLowerCase()}`
      );

      groupedMedicalProfessionals.other = sort(
        groupedMedicalProfessionals.other
      ).asc(
        (mp) => `${mp.firstName?.toLowerCase()}${mp.lastName?.toLowerCase()}`
      );

      return groupedMedicalProfessionals;
    }
  );

export const selectMedicalProfessionalResidentsByRoute = createSelector(
  RouterSelectors.selectParam('medicalProfessionalId'),
  RouterSelectors.selectParam('facilityId'),
  ResidentMedicalProfessionalsSelectors.selectAll,
  ResidentsSelectors.selectEntities,
  (
    medicalProfessionalId,
    facilityId,
    residentMPs,
    residentEntities
  ): IResident[] => {
    if (isEmpty(residentMPs) || isEmpty(residentEntities)) {
      return [];
    }

    const medicalProfessionalResidents = residentMPs.reduce(
      (acc: IResident[], curr: ResidentMedicalProfessional) => {
        if (
          curr.medicalProfessionalId === medicalProfessionalId &&
          curr.facilityId === facilityId
        ) {
          if (curr.residentId in residentEntities) {
            const resident = {
              ...residentEntities[curr.residentId]
            };
            acc.push(resident);
          }
        }
        return acc;
      },
      []
    );
    return medicalProfessionalResidents;
  }
);

export const selectMedicalProfessionalById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id]);

export const selectResidentsByMedicalProfessionalId = (id: string) =>
  createSelector(
    ResidentMedicalProfessionalsSelectors.selectAll,
    ResidentsSelectors.selectEntities,
    (residentMedicalProfessionals, residentsById) => {
      return residentMedicalProfessionals
        .filter((rmp) => rmp.medicalProfessionalId === id)
        .map((rmp) => residentsById[rmp.residentId])
        .filter(Boolean);
    }
  );

export const selectAllGroupedByResidentId = createSelector(
  ResidentMedicalProfessionalsSelectors.selectAll,
  selectEntities,
  (
    residentMedicalProfessionals,
    medicalProfessionals
  ): Record<string, IMedicalProfessional[]> =>
    mapValues(
      groupBy(residentMedicalProfessionals, 'residentId'),
      (residentMedicalProfessionals) =>
        residentMedicalProfessionals.map(
          (residentMedicalProfessional) =>
            medicalProfessionals[
              residentMedicalProfessional.medicalProfessionalId
            ]
        )
    )
);
