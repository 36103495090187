import { CreateDocumentFromTemplatePageActions } from '$/app/pages/documents/create-document-from-template/create-document-from-template.actions';
import { DocumentTemplateDetailsPageActions } from '$/app/pages/documents/document-template-details/document-template-details.actions';
import { DocumentTemplateFormPageActions } from '$/app/pages/documents/document-template-form/document-template-form.actions';
import { DocumentTrackingPageActions } from '$/app/pages/documents/document-tracking/document-tracking.actions';
import { FacilityLibraryPageActions } from '$/app/pages/documents/facility-document-library/facility-document-library.actions';
import {
  DocumentTemplatesApiService,
  EffectHelpersService
} from '$/app/services';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { readFileAsDataUrl } from '$/app/utils/files';
import { IDocumentTemplate, Params } from '$/models';
import { Logger } from '$shared/logger';
import { ServiceActionGroup } from '$shared/types';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DocumentTemplatesApiActions } from './document-templates.actions';

@Injectable()
export class DocumentTemplatesEffects {
  private readonly actions$ = inject(Actions);
  private readonly documentTemplatesService = inject(
    DocumentTemplatesApiService
  );
  private readonly effectHelpers = inject(EffectHelpersService);

  loadDocumentTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilityLibraryPageActions.loadDocumentTemplates),
      this.effectHelpers.apiRequest({
        description: 'loading document templates',
        onRequest: (action) =>
          this.documentTemplatesService
            .getAll(action.params)
            .pipe(
              getAllPages(this.documentTemplatesService, action.params.query),
              reducePaginatedResponses()
            ),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'documentTemplates',
            response,
            DocumentTemplatesApiActions.loadDocumentTemplatesSuccess
          );

          return responseData.getActions();
        },
        onError: (error) =>
          DocumentTemplatesApiActions.loadDocumentTemplatesFail({ error })
      })
    )
  );

  getDocumentTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CreateDocumentFromTemplatePageActions.getDocumentTemplates,
        DocumentTrackingPageActions.getDocumentTemplates
      ),
      this.effectHelpers.apiRequest({
        description: 'getting document templates',
        onRequest: (action) =>
          this.documentTemplatesService
            .getAll(action.params)
            .pipe(
              getAllPages(this.documentTemplatesService, action.params.query),
              reducePaginatedResponses()
            ),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'documentTemplates',
            response,
            DocumentTemplatesApiActions.getDocumentTemplatesSuccess
          );

          return responseData.getActions();
        },
        onError: (error) =>
          DocumentTemplatesApiActions.loadDocumentTemplatesFail({ error })
      })
    )
  );

  fetchDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentTemplateDetailsPageActions.fetchDocumentTemplate),
      this.effectHelpers.apiRequest({
        description: 'fetching a document template',
        onRequest: (action) =>
          this.documentTemplatesService.get(action.id, action.params),
        onSuccess: (documentTemplate) => {
          const responseData = new ApiData(
            'documentTemplates',
            documentTemplate,
            DocumentTemplatesApiActions.fetchDocumentTemplateSuccess,
            {
              payloadKey: 'documentTemplate'
            }
          );

          return responseData.getActions();
        },
        onError: (error) =>
          DocumentTemplatesApiActions.fetchDocumentTemplateFail({ error })
      })
    )
  );

  createDocumentTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentTemplateFormPageActions.createDocumentTemplate),
      this.effectHelpers.apiRequest({
        description: 'updating document template',
        useMapOperator: 'exhaustMap',
        onRequest: (action) =>
          this.documentTemplatesService.create(
            action.documentTemplate,
            action.params
          ),
        onSuccess: (documentTemplate: IDocumentTemplate) =>
          DocumentTemplatesApiActions.createDocumentTemplateSuccess({
            documentTemplate
          }),
        onError: (error) =>
          DocumentTemplatesApiActions.createDocumentTemplateFail({ error })
      })
    )
  );

  updateDocumentTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DocumentTemplateFormPageActions.updateDocumentTemplate,
        DocumentTemplateDetailsPageActions.removeFile,
        FacilityLibraryPageActions.moveDocumentsToTemplate
      ),
      this.effectHelpers.apiRequest({
        description: 'updating document template',
        onRequest: (action) =>
          this.documentTemplatesService.patch(
            action.id,
            action.changes,
            action.params
          ),
        onSuccess: (documentTemplate) =>
          DocumentTemplatesApiActions.updateDocumentTemplateSuccess({
            documentTemplate
          }),
        onError: (error) =>
          DocumentTemplatesApiActions.updateDocumentTemplateFail({ error })
      })
    )
  );

  deleteDocumentTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        FacilityLibraryPageActions.deleteDocumentTemplate,
        DocumentTemplateDetailsPageActions.deleteDocumentTemplate
      ),
      this.effectHelpers.apiRequest({
        description: 'Delete document template',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.documentTemplatesService.delete(action.id, action.params);
        },
        onSuccess: (documentTemplate) =>
          DocumentTemplatesApiActions.deleteDocumentTemplateSuccess({
            id: documentTemplate.id
          }),
        onError: (error) =>
          DocumentTemplatesApiActions.deleteDocumentTemplateFail({ error })
      })
    );
  });

  uploadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentTemplateDetailsPageActions.uploadFile),
      this.effectHelpers.apiRequest({
        description: 'uploading file and attaching to document template',
        transformAction: async (action) => ({
          ...action,
          params: {
            ...action.params,
            query: {
              ...action.params.query,
              $actions: await getUploadAction(action.file)
            }
          } satisfies Params
        }),
        onRequest: (action) =>
          this.documentTemplatesService.patch(action.id, {}, action.params),
        onSuccess: (documentTemplate) =>
          DocumentTemplatesApiActions.updateDocumentTemplateSuccess({
            documentTemplate
          }),
        onError: (error) =>
          DocumentTemplatesApiActions.fetchDocumentTemplateFail({ error })
      })
    )
  );
}

async function getUploadAction(file: File): Promise<ServiceActionGroup[]> {
  Logger.assert(file, 'File is required to upload a file');

  return [
    {
      uploadAttachedFile: {
        url: await readFileAsDataUrl(file),
        filename: file.name
      }
    }
  ];
}
