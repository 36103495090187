import { getDateRange } from '$/app/utils';
import { FacilityTime } from '$shared/utils/date-time/facility-time';
import { EventListFilters } from '../events-list.vmodel';

export const defaultFilters = (ft: FacilityTime): EventListFilters => ({
  types: ['medical-event', 'appointment', 'incident-report'],
  medicalEventTypes: [
    'hospital',
    'emergency',
    'procedure',
    'accident',
    'visit'
  ],
  dateRange: getDateRange(null, ft, 'past60Days')
});

export interface State {
  filters: EventListFilters;
}

export const initialState: State = {
  filters: null
};
