import { createFeature, createReducer, on } from '@ngrx/store';
import { EventListFilterComponentActions } from '../event-list-filter/event-list-filter.actions';
import { initialState } from './event-list.state';

export const uiEventListPageFeature = createFeature({
  name: 'uiEventListPage',
  reducer: createReducer(
    initialState,
    on(EventListFilterComponentActions.filterChanged, (state, action) => {
      return {
        ...state,
        filters: action.filters
      };
    })
  )
});
