import { MedicationOrderItem, Params, Update } from '$/models';
import { IMedicationInventoryItem } from '$shared/medication-inventory-items';
import { IMedication } from '$shared/medications';
import { IResident } from '$shared/residents/residents.interface';
import { createActionGroup, props } from '@ngrx/store';

export const MedicationDetailInventoryPageActions = createActionGroup({
  source: 'Medication Detail Inventory Page',
  events: {
    'Delete Medication Inventory Item': props<{
      id: string;
      params?: Params;
    }>(),

    'Mark Inventory Empty': props<Update<IMedicationInventoryItem>>(),
    'Mark Inventory Not Empty': props<Update<IMedicationInventoryItem>>(),

    'Change Current Inventory': props<Update<IMedicationInventoryItem>>(),

    'Open Inventory Item Form': props<{
      resident: IResident;
      medication: IMedication;
      medicationOrderItem?: MedicationOrderItem;
      medicationInventoryItem?: IMedicationInventoryItem;
    }>(),

    'Fetch Medication Detail Page Info': (params: Params = { query: {} }) => ({
      params
    })
  }
});
