import { MedicationReceivingTableActions } from '$/app/pages/medications/dashboard/medication-receiving/medication-receiving-table/medication-receiving-table.actions';
import { MedicationReceivingPageActions } from '$/app/pages/medications/dashboard/medication-receiving/medication-receiving.actions';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  PharmacyApiMessagesApiActions,
  PharmacyApiMessagesGeneralActions,
  PharmacyApiMessagesWsActions
} from './pharmacy-api-messages.actions';
import {
  initialState,
  pharmacyApiMessagesAdapter
} from './pharmacy-api-messages.state';

export const pharmacyApiMessagesFeature = createFeature({
  name: 'pharmacyApiMessages',
  reducer: createReducer(
    initialState,
    on(
      MedicationReceivingPageActions.loadPharmacyApiMessages,
      MedicationReceivingTableActions.acceptPharmacyApiMessages,
      MedicationReceivingTableActions.rejectPharmacyApiMessages,
      (state) => {
        return {
          ...state,
          loading: true
        };
      }
    ),
    on(
      PharmacyApiMessagesApiActions.loadPharmacyApiMessagesSuccess,
      (state, action) => {
        return pharmacyApiMessagesAdapter.setAll(
          action.pharmacyApiMessages.data,
          {
            ...state,
            loading: false,
            loaded: true,
            error: null
          }
        );
      }
    ),
    on(
      PharmacyApiMessagesApiActions.updatePharmacyApiMessageSuccess,
      (state, _action) => {
        return {
          ...state,
          loading: false,
          loaded: true
        };
      }
    ),
    on(
      PharmacyApiMessagesApiActions.loadPharmacyApiMessagesFail,
      PharmacyApiMessagesApiActions.updatePharmacyApiMessageFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      PharmacyApiMessagesWsActions.pharmacyApiMessagesCreated,
      PharmacyApiMessagesWsActions.pharmacyApiMessagesPatched,
      (state, action) => {
        return pharmacyApiMessagesAdapter.upsertOne(action.pharmacyApiMessage, {
          ...state
        });
      }
    ),

    on(
      PharmacyApiMessagesWsActions.pharmacyApiMessagesRemoved,
      (state, action) => {
        return pharmacyApiMessagesAdapter.removeOne(action.id, {
          ...state
        });
      }
    ),

    on(
      PharmacyApiMessagesGeneralActions.addPharmacyApiMessages,
      (state, action) => {
        return pharmacyApiMessagesAdapter.upsertMany(
          action.pharmacyApiMessages,
          {
            ...state
          }
        );
      }
    ),

    on(PharmacyApiMessagesGeneralActions.clearPharmacyApiMessages, (state) => {
      return pharmacyApiMessagesAdapter.removeAll({
        ...state,
        loading: false,
        loaded: false,
        error: null
      });
    })
  )
});
