import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ActivitiesEffects } from './activities.effects';
import { activitiesFeature } from './activities.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(activitiesFeature),
    EffectsModule.forFeature([ActivitiesEffects])
  ],
  providers: [ActivitiesEffects]
})
export class ActivitiesStoreModule {}
